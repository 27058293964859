<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 10:05:51
 * @LastEditTime: 2022-08-25 17:33:37
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 默认指标卡
 * @FilePath: \user\src\views\businessChart\components\cardDefault.vue
-->
<template>
  <div class="card-default">
    <!-- 样式2 -->
    <div class="styleType2-conter" v-if="styleType == '2'">
      <div class="styleType2-title" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">
        <span>{{ item.title }}</span>
        <en-icon :name="'bus-board-icon' + item.iconSet.icon + '_1'" :size="item.contentFont" :style="'color:' + item.contentColor"> </en-icon>
      </div>
      <div class="styleType2-text" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ value }}</div>
    </div>
    <!-- 样式3 -->
    <div class="styleType2-conter" v-else-if="styleType == '3'" style="color: #FFF;" :style="gradient">
      <div class="styleType2-title" :style="{ 'font-size': item.titleFont + 'px' }">
        <span>{{ item.title }}</span>
        <en-icon :name="'bus-board-icon' + item.iconSet.icon + '_1'" :size="item.contentFont" style="color: #FFF;"> </en-icon>
      </div>
      <div class="styleType2-text" :style="{ 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ value }}</div>
    </div>
    <!-- 样式1 -->
    <div class="default-conter" v-else>
      <p class="default-conter-title" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">{{ item.title }}</p>
      <p class="default-conter-text" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ value }}</p>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardDefault",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 全局变量
    variables: {
      type: Array,
      default() {
        return [];
      }
    },
    menuData: {
      type: Object,
      circular() {
        return {};
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: "",
      zbData: [],
      gradient: "",
      styleType: "1"
    };
  },
  created() {
    const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
    if (customAttr && customAttr.styleType) {
      this.styleType = customAttr.styleType || "1";
    } else {
      this.styleType = this.picItemData.picData.styleType || "1";
    }
  },
  mounted() {
    console.log(111111111, this.item);
    console.log(this.picData);
    this.fun();
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
    } else if (showType === "2") {
      // 百分百
      this.value = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.value = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.value = thousandFormat(Number(this.value || 0).toFixed(decimal));
    }
    // styleType 底色块的 颜色 需要 传递给父级 把标题框颜色 改成这样
    if (String(this.picItemData.picData.styleType || "1") === "3") {
      this.$emit("styleType", this.item.contentColor);
    } else {
      this.$emit("styleType", "");
    }
  },
  watch: {
    //
    menuData: {
      handler() {
        const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
        if (customAttr && customAttr.styleType) {
          this.styleType = customAttr.styleType;
        } else {
          this.styleType = this.picItemData.picData.styleType;
        }
      },
      deep: true
    },
    resized() {},
    isFullScreen() {}
  },
  methods: {
    // 穿透事件
    through() {
      // // 全屏无事件
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    },
    fun() {
      const c1 = `${this.item.contentColor}FF`;
      const c2 = `${this.item.contentColor}E5`;
      const c3 = `${this.item.contentColor}CC`;
      this.gradient = `background-image: linear-gradient(${c1},${c2},${c3})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-default {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  position: relative;
  box-sizing: border-box;
  .default-conter {
    height: 100%;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    p {
      margin: 0;
    }
    .default-conter-title {
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: 400;
      // position: absolute;
      // top: 10px;
      // left: 20px;
    }
    .default-conter-text {
      font-family: Microsoft YaHei;
      font-weight: 400;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .styleType2-conter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 0;
    .styleType2-title {
      // padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .styleType2-text {
      flex: 1;
      text-align: left;
      display: flex;
      align-items: center;
    }
  }
}
</style>
