<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-21 16:03:17
 * @LastEditTime: 2024-02-20 14:13:11
 * @LastEditors: Please set LastEditors
 * @Description: top项组件
 * @FilePath: \user\src\views\businessChart\components\chartTop.vue
-->
<template>
  <el-popover placement="bottom" width="360" trigger="hover" popper-class="chartTop__top">
    <div class="top-box">
      <div class="top-box-checkbox top-box-mod">
        <el-checkbox v-model="isTop" @change="isTopChange">TOP项</el-checkbox>
      </div>
      <div class="top-box-mod">
        <div style="width: 48px;letter-spacing: 4px;">显示前</div>
        <en-input-number
          style="width:240px;height:32px;"
          v-model.number="topNumber"
          :precision="0"
          :max="99"
          :min="1"
          :disabled="disabled"
          placeholder="请输入1-99的数字"
          @keyup.enter.native="saveTop"
        >
        </en-input-number>
        <div style="margin-left: 4px;">项</div>
      </div>
      <div class="top-box-mod">
        <el-checkbox v-model="topSortFlg" :disabled="disabled" @change="topSortFlgChange">排序</el-checkbox>
      </div>
      <div class="top-box-mod">
        <div style="width: 48px;">排序方式</div>
        <en-switch v-model="topSort" class="align" style="width:260px;" :switch-datas="switchDatas" :disabled="topSortDisabled || disabled"> </en-switch>
      </div>
      <div class="top-box-mod" style="justify-content: flex-end;">
        <en-button @click="saveTop">确定</en-button>
      </div>
    </div>
    <span title="设置TOP项" slot="reference">
      <en-icon name="topxiang" size="small" style="color:#26C393;margin-left:15px;"> </en-icon>
    </span>
  </el-popover>
</template>

<script>
export default {
  name: "ChartTop",
  props: {
    // istop参数
    isTopObj: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      disabled: false,
      switchDatas: [
        { icon: "shengxu", text: "", model: 100 },
        { icon: "jiangxu", text: "", model: 101 }
      ],
      isTop: true, // 是否开启top项
      topSort: 101,
      topSortFlg: true, // 是否开启排序
      topNumber: 1,
      topSortDisabled: false // 排序按钮
    };
  },
  mounted() {
    this.isTop = this.isTopObj.isTop === 0;
    if (this.isTop) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    this.topNumber = this.isTopObj.topOption || this.isTopObj.topNumber;
    // 排序
    if (this.isTopObj.topSort === "000") {
      this.topSort = 100;
    } else if (this.isTopObj.topSort === "001") {
      this.topSort = 101;
    } else {
      // 没有排序
      this.topSortFlg = false;
      this.topSortDisabled = true;
    }
  },
  methods: {
    // 开启top项操作
    isTopChange() {
      console.log(this.isTop);
      if (this.isTop) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    topSortFlgChange() {
      if (this.topSortFlg) {
        this.topSortDisabled = false;
      } else {
        this.topSortDisabled = true;
      }
    },
    // 确定
    saveTop() {
      const obj = {};
      obj.isTop = this.isTop ? 0 : 1;
      if (obj.isTop === 0) {
        obj.topNumber = this.topNumber;
        if (this.topSortFlg) {
          obj.topSort = this.topSort === 100 ? "000" : "001";
        }
      }
      // 回传给父级
      this.$emit("close", obj);
    }
  }
};
</script>

<style lang="scss">
.chartTop__top {
  padding: 0;
  .top-box {
    padding: 20px;
    width: 360px;
    height: 236px;
    background: #ffffff;
    font-size: 12px;
    .top-box-mod {
      display: flex;
      align-items: center;
      width: 100%;
      height: 38px;
      margin: 0;
    }
  }
}
.en-switch .is-selected{
  background: #3e90fe!important;
    border-left: 1px solid #3e90fe!important;
    border-top: 1px solid #3e90fe!important;
    border-bottom: 1px solid #3e90fe!important;
}
</style>
