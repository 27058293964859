var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "360",
        trigger: "hover",
        "popper-class": "chartTop__top",
      },
    },
    [
      _c("div", { staticClass: "top-box" }, [
        _c(
          "div",
          { staticClass: "top-box-checkbox top-box-mod" },
          [
            _c(
              "el-checkbox",
              {
                on: { change: _vm.isTopChange },
                model: {
                  value: _vm.isTop,
                  callback: function ($$v) {
                    _vm.isTop = $$v
                  },
                  expression: "isTop",
                },
              },
              [_vm._v("TOP项")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-box-mod" },
          [
            _c(
              "div",
              { staticStyle: { width: "48px", "letter-spacing": "4px" } },
              [_vm._v("显示前")]
            ),
            _c("en-input-number", {
              staticStyle: { width: "240px", height: "32px" },
              attrs: {
                precision: 0,
                max: 99,
                min: 1,
                disabled: _vm.disabled,
                placeholder: "请输入1-99的数字",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.saveTop.apply(null, arguments)
                },
              },
              model: {
                value: _vm.topNumber,
                callback: function ($$v) {
                  _vm.topNumber = _vm._n($$v)
                },
                expression: "topNumber",
              },
            }),
            _c("div", { staticStyle: { "margin-left": "4px" } }, [
              _vm._v("项"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-box-mod" },
          [
            _c(
              "el-checkbox",
              {
                attrs: { disabled: _vm.disabled },
                on: { change: _vm.topSortFlgChange },
                model: {
                  value: _vm.topSortFlg,
                  callback: function ($$v) {
                    _vm.topSortFlg = $$v
                  },
                  expression: "topSortFlg",
                },
              },
              [_vm._v("排序")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-box-mod" },
          [
            _c("div", { staticStyle: { width: "48px" } }, [_vm._v("排序方式")]),
            _c("en-switch", {
              staticClass: "align",
              staticStyle: { width: "260px" },
              attrs: {
                "switch-datas": _vm.switchDatas,
                disabled: _vm.topSortDisabled || _vm.disabled,
              },
              model: {
                value: _vm.topSort,
                callback: function ($$v) {
                  _vm.topSort = $$v
                },
                expression: "topSort",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "top-box-mod",
            staticStyle: { "justify-content": "flex-end" },
          },
          [_c("en-button", { on: { click: _vm.saveTop } }, [_vm._v("确定")])],
          1
        ),
      ]),
      _c(
        "span",
        { attrs: { slot: "reference", title: "设置TOP项" }, slot: "reference" },
        [
          _c("en-icon", {
            staticStyle: { color: "#26C393", "margin-left": "15px" },
            attrs: { name: "topxiang", size: "small" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }