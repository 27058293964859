<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 11:04:33
 * @LastEditTime: 2022-08-25 17:29:44
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 指标卡圆形
 * @FilePath: \user\src\views\businessChart\components\cardCircular.vue
-->
<template>
  <div class="card-circular">
    <!-- 样式3 -->
    <!--  :style="{ background: 'rgba(254, 123, 143, 1)'||item.contentColor + 'ff' }" -->
    <div class="styleType3-conter" v-if="styleType == '3'" :style="gradient">
      <div class="circular-top">
        <p class="default-conter-title" style="color: #ffffff;" :style="{ 'font-size': item.titleFont + 'px' }">{{ item.title }}</p>
      </div>
      <div class="circular-bottom">
        <div class="default-conter-text" style="color: #ffffff;" :style="{ 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ valueStr }}</div>
        <div class="circular-echarts">
          <!-- :style="{ width: Number(item.contentFont) + 150 + 'px', height: Number(item.contentFont) + 150 + 'px' }" -->
          <div :ref="'echarts' + item.uuid" class="circular-echarts-con" :id="'echarts' + item.uuid"></div>
        </div>
      </div>
    </div>
    <!-- 样式2 -->
    <div class="styleType2-conter" v-else-if="styleType == '2'">
      <div class="circular-top">
        <p class="default-conter-title" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">{{ item.title }}</p>
      </div>
      <div class="circular-bottom">
        <p class="default-conter-text" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ valueStr }}</p>
        <div class="circular-echarts">
          <!-- :style="{ width: Number(item.contentFont) + 150 + 'px', height: Number(item.contentFont) + 150 + 'px' }" -->
          <div :ref="'echarts' + item.uuid" class="circular-echarts-con" :id="'echarts' + item.uuid"></div>
        </div>
      </div>
    </div>
    <!-- 样式1 -->
    <div class="styleType1-conter" v-else>
      <div ref="circular" class="circular-echarts">
        <div :ref="'echarts' + item.uuid" class="circular-echarts-con" :id="'echarts' + item.uuid"></div>
      </div>
      <div class="circular-conter">
        <div>
          <p class="default-conter-title" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">{{ item.title }}</p>
          <p class="default-conter-text" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">{{ valueStr }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

import "@/assets/js/echarts-liquidfill.min";

export default {
  name: "CardCircular",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      circular() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      circular() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      circular() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      circular() {
        return false;
      }
    },
    menuData: {
      type: Object,
      circular() {
        return {};
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: 0, // 取出的真实值
      valueStr: "", // 显示值
      contrast: 0, // 对比值
      zbData: [],
      myChart: null,
      gradient: "",
      styleType: "1"
    };
  },
  created() {
    const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
    if (customAttr && customAttr.styleType) {
      this.styleType = customAttr.styleType || "1";
    } else {
      this.styleType = this.picItemData.picData.styleType || "1";
    }
  },
  mounted() {
    // console.log(echartsLiquidfill);
    console.log(this.item);
    console.log(this.picData);
    this.fun();
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
    } else if (showType === "2") {
      // 百分百
      this.valueStr = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.valueStr = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.valueStr = thousandFormat(Number(this.value).toFixed(decimal));
    }
    setTimeout(() => {
      this.circular();
    }, 500);
  },
  watch: {
    //
    resized() {
      if (this.myChart) {
        // const dom = this.$refs.circular;
        // const dom2 = this.$refs.circular2;
        // const h = dom.offsetHeight - 20;
        // const w = dom.offsetWidth - 20;
        // console.log(dom);
        // if (w * 0.8 > h) {
        //   dom2.style.height = `${h}px`;
        // } else {
        //   dom2.style.height = `${w * 0.8}px`;
        // }
        this.myChart.resize();
        // this.myChart.clear();
        // this.circular();
      }
    },
    menuData: {
      handler() {
        const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
        if (customAttr && customAttr.styleType) {
          this.styleType = customAttr.styleType;
        } else {
          this.styleType = this.picItemData.picData.styleType;
        }
        this.$nextTick(() => {
          this.circular();
        });
      },
      deep: true
    },
    isFullScreen() {}
  },
  computed: {
    // styleType() {
    //   if (this.myChart) {
    //     this.myChart.resize();
    //   }
    //   // setTimeout(() => {
    //   //   this.circular();
    //   // }, 500);
    //   const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
    //   if (customAttr && customAttr.styleType) {
    //     return customAttr.styleType;
    //   }
    //   return this.picItemData.picData.styleType;
    // }
  },
  methods: {
    // 画圈
    circular() {
      // const zcolorList = [this.item.yData[0].color];
      const zcolorList = [this.item.contentColor, `${this.item.contentColor}0c`];

      // 取出对比值
      if (this.item.yData2 && this.item.yData2.length) {
        zcolorList.push(this.item.yData2[0].color);
        const controlNameVariable2 = this.item.yData2[0].controlNameVariable;
        const controlName2 = this.item.yData2[0].controlName;
        (this.zbData || []).forEach((element) => {
          if (element.id === controlNameVariable2 && element.name === controlName2) {
            this.contrast = element.value;
          }
        });
      }
      // 得到需要展示的值
      let vlist;
      let bili;
      if (String(this.item.yData[0].controlType) === "5") {
        vlist = [{ value: 100 }];
      } else if (this.contrast === 0) {
        vlist = [{ value: 100 }];
      } else {
        // 对比值 存在, 按比例处理
        bili = this.value / this.contrast;
        if (bili < 0) {
          bili = 0;
          vlist = [{ value: 0 }, { value: 100 }];
        } else if (bili > 1) {
          bili = 1;
          vlist = [{ value: 100 }];
        } else {
          const chazhi = this.contrast - this.value;
          vlist = [
            { value: this.value, name: "" },
            { value: chazhi, name: "" }
          ];
        }
        console.log("bili", bili, this.item.contentFont);
      }
      // const dom = document.getElementById(`echarts${this.item.uuid}`);
      const dom = this.$refs[`echarts${this.item.uuid}`];
      console.log(dom);
      this.myChart = this.$charts.init(dom);
      this.myChart.clear();
      let option = {};
      if (String(this.styleType) === "3") {
        option = {
          // backgroundColor: "rgba(254, 123, 143, 1)",dom.clientHeight

          series: [
            {
              type: "liquidFill",
              radius: "100%",
              center: ["50%", "50%"],
              color: [
                {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  // colorStops: [
                  //       {
                  //           offset: 1,
                  //           color: 'rgba(238, 123, 166, 1)',
                  //       },{
                  //           offset: 0,
                  //           color: 'rgba(77, 239, 234, 1)',
                  //       },{
                  //           offset: 0,
                  //           color: 'rgba(253, 204, 207, 1)',
                  //       },{
                  //           offset: 1,
                  //           color: 'rgba(255, 105, 114, 1)',
                  //       },

                  //   ],
                  colorStops: [
                    {
                      offset: 1,
                      color: "rgba(255, 255, 255, 0.4)"
                    },
                    {
                      offset: 0,
                      color: "rgba(255, 255, 255, 0.4)"
                    },
                    {
                      offset: 0,
                      color: "rgba(255, 255, 255, 0.4)"
                    },
                    {
                      offset: 1,
                      color: "rgba(255, 255, 255, 0.4)"
                    }
                  ],
                  globalCoord: false
                }
              ],
              data: [bili, bili], // data个数代表波浪数
              backgroundStyle: {
                borderWidth: 0,
                color: `${this.item.contentColor}e5`
                // "rgba(254, 123, 143, 1)" ||
              },
              label: {
                normal: {
                  show: false,

                  textStyle: {
                    fontSize: 50,
                    color: "#fff"
                  }
                }
              },
              outline: {
                // show: false
                borderDistance: 0,
                itemStyle: {
                  borderWidth: 1,
                  borderColor: "#ffffff33"
                }
              }
            }
          ]
        };
      } else if (String(this.styleType) === "2") {
        // 计算大小
        option = {
          series: [
            {
              color: zcolorList,
              name: "",
              type: "pie",
              roseType: "radius",
              right: "20",
              center: ["80%", "50%"],
              // radius: [0, this.item.contentFont],
              avoidLabelOverlap: false,
              legendHoverLink: false,
              hoverAnimation: false,
              silent: true, // 不影响点击事件
              label: {
                normal: {
                  show: false,
                  position: "center"
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "12"
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: vlist
            }
          ]
        };
      } else {
        option = {
          series: [
            {
              color: zcolorList,
              name: "",
              type: "pie",
              radius: ["90%", "100%"],
              avoidLabelOverlap: false,
              legendHoverLink: false,
              hoverAnimation: false,
              silent: true, // 不影响点击事件
              label: {
                normal: {
                  show: false,
                  position: "center"
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "12"
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: vlist
            }
          ]
        };
      }

      this.myChart.setOption(option);
    },
    // 穿透事件
    through() {
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    },
    fun() {
      const c1 = `${this.item.contentColor}FF`;
      const c2 = `${this.item.contentColor}E5`;
      const c3 = `${this.item.contentColor}CC`;
      this.gradient = `background-image: linear-gradient(${c1},${c2},${c3})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-circular {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  // 样式2
  .styleType2-conter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0px 0 20px;
    .circular-top {
      p {
        margin: 20px 0;
        text-align: left;
        font-weight: 400;
      }
    }
    .circular-bottom {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        // flex: 1;
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }

    .circular-echarts {
      height: 100%;
      flex: 1;
      .circular-echarts-con {
        width: 100%;
        height: 100%;
      }
    }
  }
  // 样式3
  .styleType3-conter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 18px 20px;
    .circular-top {
      p {
        margin: 0;
        // line-height: 10px;
        text-align: left;
        font-weight: 400;
      }
    }
    .circular-bottom {
      flex: 1;
      width: 100%;
      position: relative;

      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      .default-conter-text {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .circular-echarts {
      height: 100%;
      flex: 1;
      .circular-echarts-con {
        width: 100%;
        height: 100%;
      }
    }
  }
  // 样式1
  .styleType1-conter {
    height: 100%;
    width: 100%;
    .circular-echarts {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .circular-echarts-con {
        width: 100%;
        height: 100%;
        padding: 16px 0 24px;
        box-sizing: border-box;
      }
    }
    .circular-conter {
      text-align: center;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .default-conter-title {
        margin-bottom: 15px;
      }
      p {
        margin: 0;
      }
    }
  }
}
</style>
