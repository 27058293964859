/* eslint-disable no-undef */
/* eslint-disable no-new */
!(function(t, e) {
  typeof exports === "object" && typeof module === "object"
    ? (module.exports = e(require("echarts")))
    : typeof define === "function" && define.amd
    ? define(["echarts"], e)
    : typeof exports === "object"
    ? (exports["echarts-liquidfill"] = e(require("echarts")))
    : (t["echarts-liquidfill"] = e(t.echarts));
}(window, (t) => (function(t) {
    const e = {};
    function r(n) {
      if (e[n]) return e[n].exports;
      const i = (e[n] = { i: n, l: !1, exports: {} });
      return t[n].call(i.exports, i, i.exports, r), (i.l = !0), i.exports;
    }
    return (
      (r.m = t),
      (r.c = e),
      (r.d = function(t, e, n) {
        r.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: n });
      }),
      (r.r = function(t) {
        typeof Symbol !== "undefined" && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t, "__esModule", { value: !0 });
      }),
      (r.t = function(t, e) {
        if ((1 & e && (t = r(t)), 8 & e)) return t;
        if (4 & e && typeof t === "object" && t && t.__esModule) return t;
        const n = Object.create(null);
        if ((r.r(n), Object.defineProperty(n, "default", { enumerable: !0, value: t }), 2 & e && typeof t !== "string")) {
          for (const i in t) {
            r.d(n, i, ((e) => t[e]).bind(null, i));
          }
        }
        return n;
      }),
      (r.n = function(t) {
        const e = t && t.__esModule
            ? function() {
                return t.default;
              }
            : function() {
                return t;
              };
        return r.d(e, "a", e), e;
      }),
      (r.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
      }),
      (r.p = ""),
      r((r.s = 29))
    );
  }([
    function(t, e) {
      const r = {
        "[object Function]": 1,
        "[object RegExp]": 1,
        "[object Date]": 1,
        "[object Error]": 1,
        "[object CanvasGradient]": 1,
        "[object CanvasPattern]": 1,
        "[object Image]": 1,
        "[object Canvas]": 1
      };
      const n = {
        "[object Int8Array]": 1,
        "[object Uint8Array]": 1,
        "[object Uint8ClampedArray]": 1,
        "[object Int16Array]": 1,
        "[object Uint16Array]": 1,
        "[object Int32Array]": 1,
        "[object Uint32Array]": 1,
        "[object Float32Array]": 1,
        "[object Float64Array]": 1
      };
      const i = Object.prototype.toString;
      const a = Array.prototype;
      const o = a.forEach;
      const s = a.filter;
      const l = a.slice;
      const h = a.map;
      const u = a.reduce;
      const c = {};
      function f(t) {
        if (t == null || typeof t !== "object") return t;
        let e = t;
        const a = i.call(t);
        if (a === "[object Array]") {
          if (!S(t)) {
            e = [];
            for (var o = 0, s = t.length; o < s; o++) e[o] = f(t[o]);
          }
        } else if (n[a]) {
          if (!S(t)) {
            const l = t.constructor;
            if (t.constructor.from) e = l.from(t);
            else {
              e = new l(t.length);
              for (o = 0, s = t.length; o < s; o++) e[o] = f(t[o]);
            }
          }
        } else if (!r[a] && !S(t) && !w(t)) for (const h in ((e = {}), t)) t.hasOwnProperty(h) && (e[h] = f(t[h]));
        return e;
      }
      function d(t, e, r) {
        if (!_(e) || !_(t)) return r ? f(e) : t;
        for (const n in e) {
          if (e.hasOwnProperty(n)) {
            const i = t[n];
            const a = e[n];
            !_(a) || !_(i) || x(a) || x(i) || w(a) || w(i) || b(a) || b(i) || S(a) || S(i) ? (!r && n in t) || (t[n] = f(e[n])) : d(i, a, r);
          }
        }
        return t;
      }
      function p(t, e, r) {
        for (const n in e) e.hasOwnProperty(n) && (r ? e[n] != null : t[n] == null) && (t[n] = e[n]);
        return t;
      }
      let v;
      const g = function() {
        return c.createCanvas();
      };
      function y(t, e, r) {
        if (t && e) {
          if (t.forEach && t.forEach === o) t.forEach(e, r);
          else if (t.length === +t.length) for (let n = 0, i = t.length; n < i; n++) e.call(r, t[n], n, t);
          else for (const a in t) t.hasOwnProperty(a) && e.call(r, t[a], a, t);
        }
      }
      function m(t, e) {
        const r = l.call(arguments, 2);
        return function() {
          return t.apply(e, r.concat(l.call(arguments)));
        };
      }
      function x(t) {
        return i.call(t) === "[object Array]";
      }
      function _(t) {
        const e = typeof t;
        return e === "function" || (!!t && e === "object");
      }
      function b(t) {
        return !!r[i.call(t)];
      }
      function w(t) {
        return typeof t === "object" && typeof t.nodeType === "number" && typeof t.ownerDocument === "object";
      }
      c.createCanvas = function() {
        return document.createElement("canvas");
      };
      function S(t) {
        return t.__ec_primitive__;
      }
      function T(t) {
        const e = x(t);
        this.data = {};
        const r = this;
        function n(t, n) {
          e ? r.set(t, n) : r.set(n, t);
        }
        t instanceof T ? t.each(n) : t && y(t, n);
      }
      (T.prototype = {
        constructor: T,
        get(t) {
          return this.data.hasOwnProperty(t) ? this.data[t] : null;
        },
        set(t, e) {
          return (this.data[t] = e);
        },
        each(t, e) {
          for (const r in (void 0 !== e && (t = m(t, e)), this.data)) this.data.hasOwnProperty(r) && t(this.data[r], r);
        },
        removeKey(t) {
          delete this.data[t];
        }
      }),
        (e.$override = function(t, e) {
          t === "createCanvas" && (v = null), (c[t] = e);
        }),
        (e.clone = f),
        (e.merge = d),
        (e.mergeAll = function(t, e) {
          for (var r = t[0], n = 1, i = t.length; n < i; n++) r = d(r, t[n], e);
          return r;
        }),
        (e.extend = function(t, e) {
          for (const r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
          return t;
        }),
        (e.defaults = p),
        (e.createCanvas = g),
        (e.getContext = function() {
          return v || (v = g().getContext("2d")), v;
        }),
        (e.indexOf = function(t, e) {
          if (t) {
            if (t.indexOf) return t.indexOf(e);
            for (let r = 0, n = t.length; r < n; r++) if (t[r] === e) return r;
          }
          return -1;
        }),
        (e.inherits = function(t, e) {
          const r = t.prototype;
          function n() {}
          for (const i in ((n.prototype = e.prototype), (t.prototype = new n()), r)) r.hasOwnProperty(i) && (t.prototype[i] = r[i]);
          (t.prototype.constructor = t), (t.superClass = e);
        }),
        (e.mixin = function(t, e, r) {
          p((t = "prototype" in t ? t.prototype : t), (e = "prototype" in e ? e.prototype : e), r);
        }),
        (e.isArrayLike = function(t) {
          if (t) return typeof t !== "string" && typeof t.length === "number";
        }),
        (e.each = y),
        (e.map = function(t, e, r) {
          if (t && e) {
            if (t.map && t.map === h) return t.map(e, r);
            for (var n = [], i = 0, a = t.length; i < a; i++) n.push(e.call(r, t[i], i, t));
            return n;
          }
        }),
        (e.reduce = function(t, e, r, n) {
          if (t && e) {
            if (t.reduce && t.reduce === u) return t.reduce(e, r, n);
            for (let i = 0, a = t.length; i < a; i++) r = e.call(n, r, t[i], i, t);
            return r;
          }
        }),
        (e.filter = function(t, e, r) {
          if (t && e) {
            if (t.filter && t.filter === s) return t.filter(e, r);
            for (var n = [], i = 0, a = t.length; i < a; i++) e.call(r, t[i], i, t) && n.push(t[i]);
            return n;
          }
        }),
        (e.find = function(t, e, r) {
          if (t && e) for (let n = 0, i = t.length; n < i; n++) if (e.call(r, t[n], n, t)) return t[n];
        }),
        (e.bind = m),
        (e.curry = function(t) {
          const e = l.call(arguments, 1);
          return function() {
            return t.apply(this, e.concat(l.call(arguments)));
          };
        }),
        (e.isArray = x),
        (e.isFunction = function(t) {
          return typeof t === "function";
        }),
        (e.isString = function(t) {
          return i.call(t) === "[object String]";
        }),
        (e.isObject = _),
        (e.isBuiltInObject = b),
        (e.isTypedArray = function(t) {
          return !!n[i.call(t)];
        }),
        (e.isDom = w),
        (e.eqNaN = function(t) {
          return t != t;
        }),
        (e.retrieve = function(t) {
          for (let e = 0, r = arguments.length; e < r; e++) if (arguments[e] != null) return arguments[e];
        }),
        (e.retrieve2 = function(t, e) {
          return t != null ? t : e;
        }),
        (e.retrieve3 = function(t, e, r) {
          return t != null ? t : e != null ? e : r;
        }),
        (e.slice = function() {
          return Function.call.apply(l, arguments);
        }),
        (e.normalizeCssArray = function(t) {
          if (typeof t === "number") return [t, t, t, t];
          const e = t.length;
          return e === 2 ? [t[0], t[1], t[0], t[1]] : e === 3 ? [t[0], t[1], t[2], t[1]] : t;
        }),
        (e.assert = function(t, e) {
          if (!t) throw new Error(e);
        }),
        (e.trim = function(t) {
          return t == null ? null : typeof t.trim === "function" ? t.trim() : t.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
        }),
        (e.setAsPrimitive = function(t) {
          t.__ec_primitive__ = !0;
        }),
        (e.isPrimitive = S),
        (e.createHashMap = function(t) {
          return new T(t);
        }),
        (e.concatArray = function(t, e) {
          for (var r = new t.constructor(t.length + e.length), n = 0; n < t.length; n++) r[n] = t[n];
          const i = t.length;
          for (n = 0; n < e.length; n++) r[n + i] = e[n];
          return r;
        }),
        (e.noop = function() {});
    },
    function(t, e, r) {
      const n = r(6);
      const i = r(0);
      const a = r(8);
      const o = r(52);
      const s = r(58).prototype.getCanvasPattern;
      const l = Math.abs;
      const h = new a(!0);
      function u(t) {
        n.call(this, t), (this.path = null);
      }
      (u.prototype = {
        constructor: u,
        type: "path",
        __dirtyPath: !0,
        strokeContainThreshold: 5,
        segmentIgnoreThreshold: 0,
        subPixelOptimize: !1,
        brush(t, e) {
          let r;
          const n = this.style;
          const i = this.path || h;
          const a = n.hasStroke();
          const o = n.hasFill();
          const l = n.fill;
          const u = n.stroke;
          const c = o && !!l.colorStops;
          const f = a && !!u.colorStops;
          const d = o && !!l.image;
          const p = a && !!u.image;
          (n.bind(t, this, e), this.setTransform(t), this.__dirty)
            && (c && ((r = r || this.getBoundingRect()), (this._fillGradient = n.getGradient(t, l, r))), f && ((r = r || this.getBoundingRect()), (this._strokeGradient = n.getGradient(t, u, r))));
          c ? (t.fillStyle = this._fillGradient) : d && (t.fillStyle = s.call(l, t)), f ? (t.strokeStyle = this._strokeGradient) : p && (t.strokeStyle = s.call(u, t));
          const v = n.lineDash;
          const g = n.lineDashOffset;
          const y = !!t.setLineDash;
          const m = this.getGlobalScale();
          if (
            (i.setScale(m[0], m[1], this.segmentIgnoreThreshold),
            this.__dirtyPath || (v && !y && a)
              ? (i.beginPath(t), v && !y && (i.setLineDash(v), i.setLineDashOffset(g)), this.buildPath(i, this.shape, !1), this.path && (this.__dirtyPath = !1))
              : (t.beginPath(), this.path.rebuildPath(t)),
            o)
          ) {
            if (n.fillOpacity != null) {
              var x = t.globalAlpha;
              (t.globalAlpha = n.fillOpacity * n.opacity), i.fill(t), (t.globalAlpha = x);
            } else i.fill(t);
          }
          if ((v && y && (t.setLineDash(v), (t.lineDashOffset = g)), a)) {
            if (n.strokeOpacity != null) {
              x = t.globalAlpha;
              (t.globalAlpha = n.strokeOpacity * n.opacity), i.stroke(t), (t.globalAlpha = x);
            } else i.stroke(t);
          }
          v && y && t.setLineDash([]), n.text != null && (this.restoreTransform(t), this.drawRectText(t, this.getBoundingRect()));
        },
        buildPath(t, e, r) {},
        createPathProxy() {
          this.path = new a();
        },
        getBoundingRect() {
          let t = this._rect;
          const e = this.style;
          const r = !t;
          if (r) {
            let n = this.path;
            n || (n = this.path = new a()), this.__dirtyPath && (n.beginPath(), this.buildPath(n, this.shape, !1)), (t = n.getBoundingRect());
          }
          if (((this._rect = t), e.hasStroke())) {
            const i = this._rectWithStroke || (this._rectWithStroke = t.clone());
            if (this.__dirty || r) {
              i.copy(t);
              let o = e.lineWidth;
              const s = e.strokeNoScale ? this.getLineScale() : 1;
              e.hasFill() || (o = Math.max(o, this.strokeContainThreshold || 4)), s > 1e-10 && ((i.width += o / s), (i.height += o / s), (i.x -= o / s / 2), (i.y -= o / s / 2));
            }
            return i;
          }
          return t;
        },
        contain(t, e) {
          const r = this.transformCoordToLocal(t, e);
          const n = this.getBoundingRect();
          const i = this.style;
          if (((t = r[0]), (e = r[1]), n.contain(t, e))) {
            const a = this.path.data;
            if (i.hasStroke()) {
              let s = i.lineWidth;
              const l = i.strokeNoScale ? this.getLineScale() : 1;
              if (l > 1e-10 && (i.hasFill() || (s = Math.max(s, this.strokeContainThreshold)), o.containStroke(a, s / l, t, e))) return !0;
            }
            if (i.hasFill()) return o.contain(a, t, e);
          }
          return !1;
        },
        dirty(t) {
          t == null && (t = !0),
            t && ((this.__dirtyPath = t), (this._rect = null)),
            (this.__dirty = this.__dirtyText = !0),
            this.__zr && this.__zr.refresh(),
            this.__clipTarget && this.__clipTarget.dirty();
        },
        animateShape(t) {
          return this.animate("shape", t);
        },
        attrKV(t, e) {
          t === "shape" ? (this.setShape(e), (this.__dirtyPath = !0), (this._rect = null)) : n.prototype.attrKV.call(this, t, e);
        },
        setShape(t, e) {
          const r = this.shape;
          if (r) {
            if (i.isObject(t)) for (const n in t) t.hasOwnProperty(n) && (r[n] = t[n]);
            else r[t] = e;
            this.dirty(!0);
          }
          return this;
        },
        getLineScale() {
          const t = this.transform;
          return t && l(t[0] - 1) > 1e-10 && l(t[3] - 1) > 1e-10 ? Math.sqrt(l(t[0] * t[3] - t[2] * t[1])) : 1;
        }
      }),
        (u.extend = function(t) {
          const e = function(e) {
            u.call(this, e), t.style && this.style.extendFrom(t.style, !1);
            const r = t.shape;
            if (r) {
              this.shape = this.shape || {};
              const n = this.shape;
              for (const i in r) !n.hasOwnProperty(i) && r.hasOwnProperty(i) && (n[i] = r[i]);
            }
            t.init && t.init.call(this, e);
          };
          for (const r in (i.inherits(e, u), t)) r !== "style" && r !== "shape" && (e.prototype[r] = t[r]);
          return e;
        }),
        i.inherits(u, n);
      const c = u;
      t.exports = c;
    },
    function(t, e) {
      const r = typeof Float32Array === "undefined" ? Array : Float32Array;
      function n(t) {
        return Math.sqrt(a(t));
      }
      const i = n;
      function a(t) {
        return t[0] * t[0] + t[1] * t[1];
      }
      const o = a;
      function s(t, e) {
        return Math.sqrt((t[0] - e[0]) * (t[0] - e[0]) + (t[1] - e[1]) * (t[1] - e[1]));
      }
      const l = s;
      function h(t, e) {
        return (t[0] - e[0]) * (t[0] - e[0]) + (t[1] - e[1]) * (t[1] - e[1]);
      }
      const u = h;
      (e.create = function(t, e) {
        const n = new r(2);
        return t == null && (t = 0), e == null && (e = 0), (n[0] = t), (n[1] = e), n;
      }),
        (e.copy = function(t, e) {
          return (t[0] = e[0]), (t[1] = e[1]), t;
        }),
        (e.clone = function(t) {
          const e = new r(2);
          return (e[0] = t[0]), (e[1] = t[1]), e;
        }),
        (e.set = function(t, e, r) {
          return (t[0] = e), (t[1] = r), t;
        }),
        (e.add = function(t, e, r) {
          return (t[0] = e[0] + r[0]), (t[1] = e[1] + r[1]), t;
        }),
        (e.scaleAndAdd = function(t, e, r, n) {
          return (t[0] = e[0] + r[0] * n), (t[1] = e[1] + r[1] * n), t;
        }),
        (e.sub = function(t, e, r) {
          return (t[0] = e[0] - r[0]), (t[1] = e[1] - r[1]), t;
        }),
        (e.len = n),
        (e.length = i),
        (e.lenSquare = a),
        (e.lengthSquare = o),
        (e.mul = function(t, e, r) {
          return (t[0] = e[0] * r[0]), (t[1] = e[1] * r[1]), t;
        }),
        (e.div = function(t, e, r) {
          return (t[0] = e[0] / r[0]), (t[1] = e[1] / r[1]), t;
        }),
        (e.dot = function(t, e) {
          return t[0] * e[0] + t[1] * e[1];
        }),
        (e.scale = function(t, e, r) {
          return (t[0] = e[0] * r), (t[1] = e[1] * r), t;
        }),
        (e.normalize = function(t, e) {
          const r = n(e);
          return r === 0 ? ((t[0] = 0), (t[1] = 0)) : ((t[0] = e[0] / r), (t[1] = e[1] / r)), t;
        }),
        (e.distance = s),
        (e.dist = l),
        (e.distanceSquare = h),
        (e.distSquare = u),
        (e.negate = function(t, e) {
          return (t[0] = -e[0]), (t[1] = -e[1]), t;
        }),
        (e.lerp = function(t, e, r, n) {
          return (t[0] = e[0] + n * (r[0] - e[0])), (t[1] = e[1] + n * (r[1] - e[1])), t;
        }),
        (e.applyTransform = function(t, e, r) {
          const n = e[0];
          const i = e[1];
          return (t[0] = r[0] * n + r[2] * i + r[4]), (t[1] = r[1] * n + r[3] * i + r[5]), t;
        }),
        (e.min = function(t, e, r) {
          return (t[0] = Math.min(e[0], r[0])), (t[1] = Math.min(e[1], r[1])), t;
        }),
        (e.max = function(t, e, r) {
          return (t[0] = Math.max(e[0], r[0])), (t[1] = Math.max(e[1], r[1])), t;
        });
    },
    function(t, e, r) {
      let n;
      let i;
      let a;
      let o;
      const s = r(2);
      const l = r(10);
      const h = s.applyTransform;
      const u = Math.min;
      const c = Math.max;
      function f(t, e, r, n) {
        r < 0 && ((t += r), (r = -r)), n < 0 && ((e += n), (n = -n)), (this.x = t), (this.y = e), (this.width = r), (this.height = n);
      }
      (f.prototype = {
        constructor: f,
        union(t) {
          const e = u(t.x, this.x);
          const r = u(t.y, this.y);
          (this.width = c(t.x + t.width, this.x + this.width) - e), (this.height = c(t.y + t.height, this.y + this.height) - r), (this.x = e), (this.y = r);
        },
        applyTransform:
          ((n = []),
          (i = []),
          (a = []),
          (o = []),
          function(t) {
            if (t) {
              (n[0] = a[0] = this.x),
                (n[1] = o[1] = this.y),
                (i[0] = o[0] = this.x + this.width),
                (i[1] = a[1] = this.y + this.height),
                h(n, n, t),
                h(i, i, t),
                h(a, a, t),
                h(o, o, t),
                (this.x = u(n[0], i[0], a[0], o[0])),
                (this.y = u(n[1], i[1], a[1], o[1]));
              const e = c(n[0], i[0], a[0], o[0]);
              const r = c(n[1], i[1], a[1], o[1]);
              (this.width = e - this.x), (this.height = r - this.y);
            }
          }),
        calculateTransform(t) {
          const e = this;
          const r = t.width / e.width;
          const n = t.height / e.height;
          const i = l.create();
          return l.translate(i, i, [-e.x, -e.y]), l.scale(i, i, [r, n]), l.translate(i, i, [t.x, t.y]), i;
        },
        intersect(t) {
          if (!t) return !1;
          t instanceof f || (t = f.create(t));
          const e = this;
          const r = e.x;
          const n = e.x + e.width;
          const i = e.y;
          const a = e.y + e.height;
          const o = t.x;
          const s = t.x + t.width;
          const l = t.y;
          const h = t.y + t.height;
          return !(n < o || s < r || a < l || h < i);
        },
        contain(t, e) {
          return t >= this.x && t <= this.x + this.width && e >= this.y && e <= this.y + this.height;
        },
        clone() {
          return new f(this.x, this.y, this.width, this.height);
        },
        copy(t) {
          (this.x = t.x), (this.y = t.y), (this.width = t.width), (this.height = t.height);
        },
        plain() {
          return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height
          };
        }
      }),
        (f.create = function(t) {
          return new f(t.x, t.y, t.width, t.height);
        });
      const d = f;
      t.exports = d;
    },
    function(t, e, r) {
      const n = r(2);
      const i = n.create;
      const a = n.distSquare;
      const o = Math.pow;
      const s = Math.sqrt;
      const l = s(3);
      const h = i();
      const u = i();
      const c = i();
      function f(t) {
        return t > -1e-8 && t < 1e-8;
      }
      function d(t) {
        return t > 1e-8 || t < -1e-8;
      }
      function p(t, e, r, n, i) {
        const a = 1 - i;
        return a * a * (a * t + 3 * i * e) + i * i * (i * n + 3 * a * r);
      }
      function v(t, e, r, n) {
        const i = 1 - n;
        return i * (i * t + 2 * n * e) + n * n * r;
      }
      (e.cubicAt = p),
        (e.cubicDerivativeAt = function(t, e, r, n, i) {
          const a = 1 - i;
          return 3 * (((e - t) * a + 2 * (r - e) * i) * a + (n - r) * i * i);
        }),
        (e.cubicRootAt = function(t, e, r, n, i, a) {
          const h = n + 3 * (e - r) - t;
          const u = 3 * (r - 2 * e + t);
          const c = 3 * (e - t);
          const d = t - i;
          const p = u * u - 3 * h * c;
          const v = u * c - 9 * h * d;
          const g = c * c - 3 * u * d;
          let y = 0;
          if (f(p) && f(v)) {
            if (f(u)) a[0] = 0;
            else (k = -c / u) >= 0 && k <= 1 && (a[y++] = k);
          } else {
            const m = v * v - 4 * p * g;
            if (f(m)) {
              const x = v / p;
              var _ = -x / 2;
              (k = -u / h + x) >= 0 && k <= 1 && (a[y++] = k), _ >= 0 && _ <= 1 && (a[y++] = _);
            } else if (m > 0) {
              const b = s(m);
              let w = p * u + 1.5 * h * (-v + b);
              let S = p * u + 1.5 * h * (-v - b);
              (k = (-u - ((w = w < 0 ? -o(-w, 1 / 3) : o(w, 1 / 3)) + (S = S < 0 ? -o(-S, 1 / 3) : o(S, 1 / 3)))) / (3 * h)) >= 0 && k <= 1 && (a[y++] = k);
            } else {
              const T = (2 * p * u - 3 * h * v) / (2 * s(p * p * p));
              const P = Math.acos(T) / 3;
              const M = s(p);
              const O = Math.cos(P);
              var k = (-u - 2 * M * O) / (3 * h);
              const C = ((_ = (-u + M * (O + l * Math.sin(P))) / (3 * h)), (-u + M * (O - l * Math.sin(P))) / (3 * h));
              k >= 0 && k <= 1 && (a[y++] = k), _ >= 0 && _ <= 1 && (a[y++] = _), C >= 0 && C <= 1 && (a[y++] = C);
            }
          }
          return y;
        }),
        (e.cubicExtrema = function(t, e, r, n, i) {
          const a = 6 * r - 12 * e + 6 * t;
          const o = 9 * e + 3 * n - 3 * t - 9 * r;
          const l = 3 * e - 3 * t;
          let h = 0;
          if (f(o)) {
            if (d(a)) (c = -l / a) >= 0 && c <= 1 && (i[h++] = c);
          } else {
            const u = a * a - 4 * o * l;
            if (f(u)) i[0] = -a / (2 * o);
            else if (u > 0) {
              var c;
              const p = s(u);
              const v = (-a - p) / (2 * o);
              (c = (-a + p) / (2 * o)) >= 0 && c <= 1 && (i[h++] = c), v >= 0 && v <= 1 && (i[h++] = v);
            }
          }
          return h;
        }),
        (e.cubicSubdivide = function(t, e, r, n, i, a) {
          const o = (e - t) * i + t;
          const s = (r - e) * i + e;
          const l = (n - r) * i + r;
          const h = (s - o) * i + o;
          const u = (l - s) * i + s;
          const c = (u - h) * i + h;
          (a[0] = t), (a[1] = o), (a[2] = h), (a[3] = c), (a[4] = c), (a[5] = u), (a[6] = l), (a[7] = n);
        }),
        (e.cubicProjectPoint = function(t, e, r, n, i, o, l, f, d, v, g) {
          let y;
          let m;
          let x;
          let _;
          let b;
          let w = 0.005;
          let S = 1 / 0;
          (h[0] = d), (h[1] = v);
          for (let T = 0; T < 1; T += 0.05) (u[0] = p(t, r, i, l, T)), (u[1] = p(e, n, o, f, T)), (_ = a(h, u)) < S && ((y = T), (S = _));
          S = 1 / 0;
          for (let P = 0; P < 32 && !(w < 1e-4); P++) {
            (m = y - w),
              (x = y + w),
              (u[0] = p(t, r, i, l, m)),
              (u[1] = p(e, n, o, f, m)),
              (_ = a(u, h)),
              m >= 0 && _ < S ? ((y = m), (S = _)) : ((c[0] = p(t, r, i, l, x)), (c[1] = p(e, n, o, f, x)), (b = a(c, h)), x <= 1 && b < S ? ((y = x), (S = b)) : (w *= 0.5));
          }
          return g && ((g[0] = p(t, r, i, l, y)), (g[1] = p(e, n, o, f, y))), s(S);
        }),
        (e.quadraticAt = v),
        (e.quadraticDerivativeAt = function(t, e, r, n) {
          return 2 * ((1 - n) * (e - t) + n * (r - e));
        }),
        (e.quadraticRootAt = function(t, e, r, n, i) {
          const a = t - 2 * e + r;
          const o = 2 * (e - t);
          const l = t - n;
          let h = 0;
          if (f(a)) {
            if (d(o)) (c = -l / o) >= 0 && c <= 1 && (i[h++] = c);
          } else {
            const u = o * o - 4 * a * l;
            if (f(u)) (c = -o / (2 * a)) >= 0 && c <= 1 && (i[h++] = c);
            else if (u > 0) {
              var c;
              const p = s(u);
              const v = (-o - p) / (2 * a);
              (c = (-o + p) / (2 * a)) >= 0 && c <= 1 && (i[h++] = c), v >= 0 && v <= 1 && (i[h++] = v);
            }
          }
          return h;
        }),
        (e.quadraticExtremum = function(t, e, r) {
          const n = t + r - 2 * e;
          return n === 0 ? 0.5 : (t - e) / n;
        }),
        (e.quadraticSubdivide = function(t, e, r, n, i) {
          const a = (e - t) * n + t;
          const o = (r - e) * n + e;
          const s = (o - a) * n + a;
          (i[0] = t), (i[1] = a), (i[2] = s), (i[3] = s), (i[4] = o), (i[5] = r);
        }),
        (e.quadraticProjectPoint = function(t, e, r, n, i, o, l, f, d) {
          let p;
          let g = 0.005;
          let y = 1 / 0;
          (h[0] = l), (h[1] = f);
          for (let m = 0; m < 1; m += 0.05) {
            (u[0] = v(t, r, i, m)), (u[1] = v(e, n, o, m)), (w = a(h, u)) < y && ((p = m), (y = w));
          }
          y = 1 / 0;
          for (let x = 0; x < 32 && !(g < 1e-4); x++) {
            const _ = p - g;
            const b = p + g;
            (u[0] = v(t, r, i, _)), (u[1] = v(e, n, o, _));
            var w = a(u, h);
            if (_ >= 0 && w < y) (p = _), (y = w);
            else {
              (c[0] = v(t, r, i, b)), (c[1] = v(e, n, o, b));
              const S = a(c, h);
              b <= 1 && S < y ? ((p = b), (y = S)) : (g *= 0.5);
            }
          }
          return d && ((d[0] = v(t, r, i, p)), (d[1] = v(e, n, o, p))), s(y);
        });
    },
    function(e, r) {
      e.exports = t;
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(42);
      const a = r(19);
      const o = r(50);
      function s(t) {
        for (const e in ((t = t || {}), a.call(this, t), t)) t.hasOwnProperty(e) && e !== "style" && (this[e] = t[e]);
        (this.style = new i(t.style, this)), (this._rect = null), (this.__clipPaths = null);
      }
      (s.prototype = {
        constructor: s,
        type: "displayable",
        __dirty: !0,
        invisible: !1,
        z: 0,
        z2: 0,
        zlevel: 0,
        draggable: !1,
        dragging: !1,
        silent: !1,
        culling: !1,
        cursor: "pointer",
        rectHover: !1,
        progressive: !1,
        incremental: !1,
        globalScaleRatio: 1,
        beforeBrush(t) {},
        afterBrush(t) {},
        brush(t, e) {},
        getBoundingRect() {},
        contain(t, e) {
          return this.rectContain(t, e);
        },
        traverse(t, e) {
          t.call(e, this);
        },
        rectContain(t, e) {
          const r = this.transformCoordToLocal(t, e);
          return this.getBoundingRect().contain(r[0], r[1]);
        },
        dirty() {
          (this.__dirty = this.__dirtyText = !0), (this._rect = null), this.__zr && this.__zr.refresh();
        },
        animateStyle(t) {
          return this.animate("style", t);
        },
        attrKV(t, e) {
          t !== "style" ? a.prototype.attrKV.call(this, t, e) : this.style.set(e);
        },
        setStyle(t, e) {
          return this.style.set(t, e), this.dirty(!1), this;
        },
        useStyle(t) {
          return (this.style = new i(t, this)), this.dirty(!1), this;
        },
        calculateTextPosition: null
      }),
        n.inherits(s, a),
        n.mixin(s, o);
      const l = s;
      t.exports = l;
    },
    function(t, e) {
      (e.ContextCachedBy = { NONE: 0, STYLE_BIND: 1, PLAIN_TEXT: 2 }), (e.WILL_BE_RESTORED = 9);
    },
    function(t, e, r) {
      const n = r(4);
      const i = r(2);
      const a = r(51);
      const o = r(3);
      const s = r(23).devicePixelRatio;
      const l = {
        M: 1,
        L: 2,
        C: 3,
        Q: 4,
        A: 5,
        Z: 6,
        R: 7
      };
      const h = [];
      const u = [];
      const c = [];
      const f = [];
      const d = Math.min;
      const p = Math.max;
      const v = Math.cos;
      const g = Math.sin;
      const y = Math.sqrt;
      const m = Math.abs;
      const x = typeof Float32Array !== "undefined";
      const _ = function(t) {
        (this._saveData = !t), this._saveData && (this.data = []), (this._ctx = null);
      };
      (_.prototype = {
        constructor: _,
        _xi: 0,
        _yi: 0,
        _x0: 0,
        _y0: 0,
        _ux: 0,
        _uy: 0,
        _len: 0,
        _lineDash: null,
        _dashOffset: 0,
        _dashIdx: 0,
        _dashSum: 0,
        setScale(t, e, r) {
          (r = r || 0), (this._ux = m(r / s / t) || 0), (this._uy = m(r / s / e) || 0);
        },
        getContext() {
          return this._ctx;
        },
        beginPath(t) {
          return (this._ctx = t), t && t.beginPath(), t && (this.dpr = t.dpr), this._saveData && (this._len = 0), this._lineDash && ((this._lineDash = null), (this._dashOffset = 0)), this;
        },
        moveTo(t, e) {
          return this.addData(l.M, t, e), this._ctx && this._ctx.moveTo(t, e), (this._x0 = t), (this._y0 = e), (this._xi = t), (this._yi = e), this;
        },
        lineTo(t, e) {
          const r = m(t - this._xi) > this._ux || m(e - this._yi) > this._uy || this._len < 5;
          return this.addData(l.L, t, e), this._ctx && r && (this._needsDash() ? this._dashedLineTo(t, e) : this._ctx.lineTo(t, e)), r && ((this._xi = t), (this._yi = e)), this;
        },
        bezierCurveTo(t, e, r, n, i, a) {
          return (
            this.addData(l.C, t, e, r, n, i, a),
            this._ctx && (this._needsDash() ? this._dashedBezierTo(t, e, r, n, i, a) : this._ctx.bezierCurveTo(t, e, r, n, i, a)),
            (this._xi = i),
            (this._yi = a),
            this
          );
        },
        quadraticCurveTo(t, e, r, n) {
          return this.addData(l.Q, t, e, r, n), this._ctx && (this._needsDash() ? this._dashedQuadraticTo(t, e, r, n) : this._ctx.quadraticCurveTo(t, e, r, n)), (this._xi = r), (this._yi = n), this;
        },
        arc(t, e, r, n, i, a) {
          return this.addData(l.A, t, e, r, r, n, i - n, 0, a ? 0 : 1), this._ctx && this._ctx.arc(t, e, r, n, i, a), (this._xi = v(i) * r + t), (this._yi = g(i) * r + e), this;
        },
        arcTo(t, e, r, n, i) {
          return this._ctx && this._ctx.arcTo(t, e, r, n, i), this;
        },
        rect(t, e, r, n) {
          return this._ctx && this._ctx.rect(t, e, r, n), this.addData(l.R, t, e, r, n), this;
        },
        closePath() {
          this.addData(l.Z);
          const t = this._ctx;
          const e = this._x0;
          const r = this._y0;
          return t && (this._needsDash() && this._dashedLineTo(e, r), t.closePath()), (this._xi = e), (this._yi = r), this;
        },
        fill(t) {
          t && t.fill(), this.toStatic();
        },
        stroke(t) {
          t && t.stroke(), this.toStatic();
        },
        setLineDash(t) {
          if (t instanceof Array) {
            (this._lineDash = t), (this._dashIdx = 0);
            for (var e = 0, r = 0; r < t.length; r++) e += t[r];
            this._dashSum = e;
          }
          return this;
        },
        setLineDashOffset(t) {
          return (this._dashOffset = t), this;
        },
        len() {
          return this._len;
        },
        setData(t) {
          const e = t.length;
          (this.data && this.data.length === e) || !x || (this.data = new Float32Array(e));
          for (let r = 0; r < e; r++) this.data[r] = t[r];
          this._len = e;
        },
        appendPath(t) {
          t instanceof Array || (t = [t]);
          for (var e = t.length, r = 0, n = this._len, i = 0; i < e; i++) r += t[i].len();
          x && this.data instanceof Float32Array && (this.data = new Float32Array(n + r));
          for (i = 0; i < e; i++) for (let a = t[i].data, o = 0; o < a.length; o++) this.data[n++] = a[o];
          this._len = n;
        },
        addData(t) {
          if (this._saveData) {
            let e = this.data;
            this._len + arguments.length > e.length && (this._expandData(), (e = this.data));
            for (let r = 0; r < arguments.length; r++) e[this._len++] = arguments[r];
            this._prevCmd = t;
          }
        },
        _expandData() {
          if (!(this.data instanceof Array)) {
            for (var t = [], e = 0; e < this._len; e++) t[e] = this.data[e];
            this.data = t;
          }
        },
        _needsDash() {
          return this._lineDash;
        },
        _dashedLineTo(t, e) {
          let r;
          let n;
          const i = this._dashSum;
          let a = this._dashOffset;
          const o = this._lineDash;
          const s = this._ctx;
          const l = this._xi;
          const h = this._yi;
          let u = t - l;
          let c = e - h;
          const f = y(u * u + c * c);
          let v = l;
          let g = h;
          const m = o.length;
          for (a < 0 && (a = i + a), v -= (a %= i) * (u /= f), g -= a * (c /= f); (u > 0 && v <= t) || (u < 0 && v >= t) || (u === 0 && ((c > 0 && g <= e) || (c < 0 && g >= e)));) {
            (v += u * (r = o[(n = this._dashIdx)])),
              (g += c * r),
              (this._dashIdx = (n + 1) % m),
              (u > 0 && v < l) || (u < 0 && v > l) || (c > 0 && g < h) || (c < 0 && g > h) || s[n % 2 ? "moveTo" : "lineTo"](u >= 0 ? d(v, t) : p(v, t), c >= 0 ? d(g, e) : p(g, e));
          }
          (u = v - t), (c = g - e), (this._dashOffset = -y(u * u + c * c));
        },
        _dashedBezierTo(t, e, r, i, a, o) {
          let s;
          let l;
          let h;
          let u;
          let c;
          const f = this._dashSum;
          let d = this._dashOffset;
          const p = this._lineDash;
          const v = this._ctx;
          const g = this._xi;
          const m = this._yi;
          const x = n.cubicAt;
          let _ = 0;
          let b = this._dashIdx;
          const w = p.length;
          let S = 0;
          for (d < 0 && (d = f + d), d %= f, s = 0; s < 1; s += 0.1) (l = x(g, t, r, a, s + 0.1) - x(g, t, r, a, s)), (h = x(m, e, i, o, s + 0.1) - x(m, e, i, o, s)), (_ += y(l * l + h * h));
          for (; b < w && !((S += p[b]) > d); b++);
          for (s = (S - d) / _; s <= 1;) (u = x(g, t, r, a, s)), (c = x(m, e, i, o, s)), b % 2 ? v.moveTo(u, c) : v.lineTo(u, c), (s += p[b] / _), (b = (b + 1) % w);
          b % 2 != 0 && v.lineTo(a, o), (l = a - u), (h = o - c), (this._dashOffset = -y(l * l + h * h));
        },
        _dashedQuadraticTo(t, e, r, n) {
          const i = r;
          const a = n;
          (r = (r + 2 * t) / 3), (n = (n + 2 * e) / 3), (t = (this._xi + 2 * t) / 3), (e = (this._yi + 2 * e) / 3), this._dashedBezierTo(t, e, r, n, i, a);
        },
        toStatic() {
          const t = this.data;
          t instanceof Array && ((t.length = this._len), x && (this.data = new Float32Array(t)));
        },
        getBoundingRect() {
          (h[0] = h[1] = c[0] = c[1] = Number.MAX_VALUE), (u[0] = u[1] = f[0] = f[1] = -Number.MAX_VALUE);
          for (var t = this.data, e = 0, r = 0, n = 0, s = 0, d = 0; d < t.length;) {
            const p = t[d++];
            switch ((d === 1 && ((n = e = t[d]), (s = r = t[d + 1])), p)) {
              case l.M:
                (e = n = t[d++]), (r = s = t[d++]), (c[0] = n), (c[1] = s), (f[0] = n), (f[1] = s);
                break;
              case l.L:
                a.fromLine(e, r, t[d], t[d + 1], c, f), (e = t[d++]), (r = t[d++]);
                break;
              case l.C:
                a.fromCubic(e, r, t[d++], t[d++], t[d++], t[d++], t[d], t[d + 1], c, f), (e = t[d++]), (r = t[d++]);
                break;
              case l.Q:
                a.fromQuadratic(e, r, t[d++], t[d++], t[d], t[d + 1], c, f), (e = t[d++]), (r = t[d++]);
                break;
              case l.A:
                var y = t[d++];
                var m = t[d++];
                var x = t[d++];
                var _ = t[d++];
                var b = t[d++];
                var w = t[d++] + b;
                d += 1;
                var S = 1 - t[d++];
                d === 1 && ((n = v(b) * x + y), (s = g(b) * _ + m)), a.fromArc(y, m, x, _, b, w, S, c, f), (e = v(w) * x + y), (r = g(w) * _ + m);
                break;
              case l.R:
                (n = e = t[d++]), (s = r = t[d++]);
                var T = t[d++];
                var P = t[d++];
                a.fromLine(n, s, n + T, s + P, c, f);
                break;
              case l.Z:
                (e = n), (r = s);
            }
            i.min(h, h, c), i.max(u, u, f);
          }
          return d === 0 && (h[0] = h[1] = u[0] = u[1] = 0), new o(h[0], h[1], u[0] - h[0], u[1] - h[1]);
        },
        rebuildPath(t) {
          for (var e, r, n, i, a, o, s = this.data, h = this._ux, u = this._uy, c = this._len, f = 0; f < c;) {
            const d = s[f++];
            switch ((f === 1 && ((e = n = s[f]), (r = i = s[f + 1])), d)) {
              case l.M:
                (e = n = s[f++]), (r = i = s[f++]), t.moveTo(n, i);
                break;
              case l.L:
                (a = s[f++]), (o = s[f++]), (m(a - n) > h || m(o - i) > u || f === c - 1) && (t.lineTo(a, o), (n = a), (i = o));
                break;
              case l.C:
                t.bezierCurveTo(s[f++], s[f++], s[f++], s[f++], s[f++], s[f++]), (n = s[f - 2]), (i = s[f - 1]);
                break;
              case l.Q:
                t.quadraticCurveTo(s[f++], s[f++], s[f++], s[f++]), (n = s[f - 2]), (i = s[f - 1]);
                break;
              case l.A:
                var p = s[f++];
                var y = s[f++];
                var x = s[f++];
                var _ = s[f++];
                var b = s[f++];
                var w = s[f++];
                var S = s[f++];
                var T = s[f++];
                var P = x > _ ? x : _;
                var M = x > _ ? 1 : x / _;
                var O = x > _ ? _ / x : 1;
                var k = b + w;
                Math.abs(x - _) > 0.001
                  ? (t.translate(p, y), t.rotate(S), t.scale(M, O), t.arc(0, 0, P, b, k, 1 - T), t.scale(1 / M, 1 / O), t.rotate(-S), t.translate(-p, -y))
                  : t.arc(p, y, P, b, k, 1 - T),
                  f === 1 && ((e = v(b) * x + p), (r = g(b) * _ + y)),
                  (n = v(k) * x + p),
                  (i = g(k) * _ + y);
                break;
              case l.R:
                (e = n = s[f]), (r = i = s[f + 1]), t.rect(s[f++], s[f++], s[f++], s[f++]);
                break;
              case l.Z:
                t.closePath(), (n = e), (i = r);
            }
          }
        }
      }),
        (_.CMD = l);
      const b = _;
      t.exports = b;
    },
    function(t, e, r) {
      (function(t) {
        let r;
        typeof window !== "undefined" ? (r = window.__DEV__) : void 0 !== t && (r = t.__DEV__), void 0 === r && (r = !0);
        const n = r;
        e.__DEV__ = n;
      }.call(this, r(34)));
    },
    function(t, e) {
      const r = typeof Float32Array === "undefined" ? Array : Float32Array;
      function n() {
        const t = new r(6);
        return i(t), t;
      }
      function i(t) {
        return (t[0] = 1), (t[1] = 0), (t[2] = 0), (t[3] = 1), (t[4] = 0), (t[5] = 0), t;
      }
      function a(t, e) {
        return (t[0] = e[0]), (t[1] = e[1]), (t[2] = e[2]), (t[3] = e[3]), (t[4] = e[4]), (t[5] = e[5]), t;
      }
      (e.create = n),
        (e.identity = i),
        (e.copy = a),
        (e.mul = function(t, e, r) {
          const n = e[0] * r[0] + e[2] * r[1];
          const i = e[1] * r[0] + e[3] * r[1];
          const a = e[0] * r[2] + e[2] * r[3];
          const o = e[1] * r[2] + e[3] * r[3];
          const s = e[0] * r[4] + e[2] * r[5] + e[4];
          const l = e[1] * r[4] + e[3] * r[5] + e[5];
          return (t[0] = n), (t[1] = i), (t[2] = a), (t[3] = o), (t[4] = s), (t[5] = l), t;
        }),
        (e.translate = function(t, e, r) {
          return (t[0] = e[0]), (t[1] = e[1]), (t[2] = e[2]), (t[3] = e[3]), (t[4] = e[4] + r[0]), (t[5] = e[5] + r[1]), t;
        }),
        (e.rotate = function(t, e, r) {
          const n = e[0];
          const i = e[2];
          const a = e[4];
          const o = e[1];
          const s = e[3];
          const l = e[5];
          const h = Math.sin(r);
          const u = Math.cos(r);
          return (t[0] = n * u + o * h), (t[1] = -n * h + o * u), (t[2] = i * u + s * h), (t[3] = -i * h + u * s), (t[4] = u * a + h * l), (t[5] = u * l - h * a), t;
        }),
        (e.scale = function(t, e, r) {
          const n = r[0];
          const i = r[1];
          return (t[0] = e[0] * n), (t[1] = e[1] * i), (t[2] = e[2] * n), (t[3] = e[3] * i), (t[4] = e[4] * n), (t[5] = e[5] * i), t;
        }),
        (e.invert = function(t, e) {
          const r = e[0];
          const n = e[2];
          const i = e[4];
          const a = e[1];
          const o = e[3];
          const s = e[5];
          let l = r * o - a * n;
          return l ? ((l = 1 / l), (t[0] = o * l), (t[1] = -a * l), (t[2] = -n * l), (t[3] = r * l), (t[4] = (n * s - o * i) * l), (t[5] = (a * i - r * s) * l), t) : null;
        }),
        (e.clone = function(t) {
          const e = n();
          return a(e, t), e;
        });
    },
    function(t, e, r) {
      const n = r(3);
      const i = r(12);
      const a = r(0);
      const o = a.getContext;
      const s = a.extend;
      const l = a.retrieve2;
      const h = a.retrieve3;
      const u = a.trim;
      let c = {};
      let f = 0;
      const d = /\{([a-zA-Z0-9_]+)\|([^}]*)\}/g;
      const p = {};
      function v(t, e) {
        const r = `${t}:${(e = e || "12px sans-serif")}`;
        if (c[r]) return c[r];
        for (var n = `${t}`.split("\n"), i = 0, a = 0, o = n.length; a < o; a++) i = Math.max(T(n[a], e).width, i);
        return f > 5e3 && ((f = 0), (c = {})), f++, (c[r] = i), i;
      }
      function g(t, e, r) {
        return r === "right" ? (t -= e) : r === "center" && (t -= e / 2), t;
      }
      function y(t, e, r) {
        return r === "middle" ? (t -= e / 2) : r === "bottom" && (t -= e), t;
      }
      function m(t, e, r) {
        const n = e.textPosition;
        let i = e.textDistance;
        let a = r.x;
        let o = r.y;
        i = i || 0;
        const s = r.height;
        const l = r.width;
        const h = s / 2;
        let u = "left";
        let c = "top";
        switch (n) {
          case "left":
            (a -= i), (o += h), (u = "right"), (c = "middle");
            break;
          case "right":
            (a += i + l), (o += h), (c = "middle");
            break;
          case "top":
            (a += l / 2), (o -= i), (u = "center"), (c = "bottom");
            break;
          case "bottom":
            (a += l / 2), (o += s + i), (u = "center");
            break;
          case "inside":
            (a += l / 2), (o += h), (u = "center"), (c = "middle");
            break;
          case "insideLeft":
            (a += i), (o += h), (c = "middle");
            break;
          case "insideRight":
            (a += l - i), (o += h), (u = "right"), (c = "middle");
            break;
          case "insideTop":
            (a += l / 2), (o += i), (u = "center");
            break;
          case "insideBottom":
            (a += l / 2), (o += s - i), (u = "center"), (c = "bottom");
            break;
          case "insideTopLeft":
            (a += i), (o += i);
            break;
          case "insideTopRight":
            (a += l - i), (o += i), (u = "right");
            break;
          case "insideBottomLeft":
            (a += i), (o += s - i), (c = "bottom");
            break;
          case "insideBottomRight":
            (a += l - i), (o += s - i), (u = "right"), (c = "bottom");
        }
        return ((t = t || {}).x = a), (t.y = o), (t.textAlign = u), (t.textVerticalAlign = c), t;
      }
      function x(t, e, r, n, i) {
        if (!e) return "";
        const a = `${t}`.split("\n");
        i = _(e, r, n, i);
        for (let o = 0, s = a.length; o < s; o++) a[o] = b(a[o], i);
        return a.join("\n");
      }
      function _(t, e, r, n) {
        (n = s({}, n)).font = e;
        r = l(r, "...");
        n.maxIterations = l(n.maxIterations, 2);
        const i = (n.minChar = l(n.minChar, 0));
        n.cnCharWidth = v("国", e);
        const a = (n.ascCharWidth = v("a", e));
        n.placeholder = l(n.placeholder, "");
        for (var o = (t = Math.max(0, t - 1)), h = 0; h < i && o >= a; h++) o -= a;
        let u = v(r, e);
        return u > o && ((r = ""), (u = 0)), (o = t - u), (n.ellipsis = r), (n.ellipsisWidth = u), (n.contentWidth = o), (n.containerWidth = t), n;
      }
      function b(t, e) {
        const r = e.containerWidth;
        const n = e.font;
        const i = e.contentWidth;
        if (!r) return "";
        let a = v(t, n);
        if (a <= r) return t;
        for (let o = 0; ; o++) {
          if (a <= i || o >= e.maxIterations) {
            t += e.ellipsis;
            break;
          }
          const s = o === 0 ? w(t, i, e.ascCharWidth, e.cnCharWidth) : a > 0 ? Math.floor((t.length * i) / a) : 0;
          a = v((t = t.substr(0, s)), n);
        }
        return t === "" && (t = e.placeholder), t;
      }
      function w(t, e, r, n) {
        for (var i = 0, a = 0, o = t.length; a < o && i < e; a++) {
          const s = t.charCodeAt(a);
          i += s >= 0 && s <= 127 ? r : n;
        }
        return a;
      }
      function S(t) {
        return v("国", t);
      }
      function T(t, e) {
        return p.measureText(t, e);
      }
      function P(t, e, r, n, i) {
        t != null && (t += "");
        const a = l(n, S(e));
        let o = t ? t.split("\n") : [];
        const s = o.length * a;
        let h = s;
        let u = !0;
        if ((r && (h += r[0] + r[2]), t && i)) {
          u = !1;
          const c = i.outerHeight;
          const f = i.outerWidth;
          if (c != null && h > c) (t = ""), (o = []);
          else if (f != null) for (let d = _(f - (r ? r[1] + r[3] : 0), e, i.ellipsis, { minChar: i.minChar, placeholder: i.placeholder }), p = 0, v = o.length; p < v; p++) o[p] = b(o[p], d);
        }
        return {
          lines: o,
          height: s,
          outerHeight: h,
          lineHeight: a,
          canCacheByTextString: u
        };
      }
      function M(t, e) {
        const r = { lines: [], width: 0, height: 0 };
        if ((t != null && (t += ""), !t)) return r;
        for (var n, a = (d.lastIndex = 0); (n = d.exec(t)) != null;) {
          const o = n.index;
          o > a && O(r, t.substring(a, o)), O(r, n[2], n[1]), (a = d.lastIndex);
        }
        a < t.length && O(r, t.substring(a, t.length));
        const s = r.lines;
        let u = 0;
        let c = 0;
        const f = [];
        const p = e.textPadding;
        const g = e.truncate;
        let y = g && g.outerWidth;
        let m = g && g.outerHeight;
        p && (y != null && (y -= p[1] + p[3]), m != null && (m -= p[0] + p[2]));
        for (var _ = 0; _ < s.length; _++) {
          for (var b = s[_], w = 0, T = 0, P = 0; P < b.tokens.length; P++) {
            const M = ((E = b.tokens[P]).styleName && e.rich[E.styleName]) || {};
            const k = (E.textPadding = M.textPadding);
            const C = (E.font = M.font || e.font);
            let A = (E.textHeight = l(M.textHeight, S(C)));
            if (
              (k && (A += k[0] + k[2]),
              (E.height = A),
              (E.lineHeight = h(M.textLineHeight, e.textLineHeight, A)),
              (E.textAlign = (M && M.textAlign) || e.textAlign),
              (E.textVerticalAlign = (M && M.textVerticalAlign) || "middle"),
              m != null && u + E.lineHeight > m)
            ) return { lines: [], width: 0, height: 0 };
            E.textWidth = v(E.text, C);
            let D = M.textWidth;
            const I = D == null || D === "auto";
            if (typeof D === "string" && D.charAt(D.length - 1) === "%") (E.percentWidth = D), f.push(E), (D = 0);
            else {
              if (I) {
                D = E.textWidth;
                const L = M.textBackgroundColor;
                let R = L && L.image;
                R && ((R = i.findExistImage(R)), i.isImageReady(R) && (D = Math.max(D, (R.width * A) / R.height)));
              }
              const B = k ? k[1] + k[3] : 0;
              D += B;
              const F = y != null ? y - T : null;
              F != null
                && F < D
                && (!I || F < B ? ((E.text = ""), (E.textWidth = D = 0)) : ((E.text = x(E.text, F - B, C, g.ellipsis, { minChar: g.minChar })), (E.textWidth = v(E.text, C)), (D = E.textWidth + B)));
            }
            (T += E.width = D), M && (w = Math.max(w, E.lineHeight));
          }
          (b.width = T), (b.lineHeight = w), (u += w), (c = Math.max(c, T));
        }
        (r.outerWidth = r.width = l(e.textWidth, c)), (r.outerHeight = r.height = l(e.textHeight, u)), p && ((r.outerWidth += p[1] + p[3]), (r.outerHeight += p[0] + p[2]));
        for (_ = 0; _ < f.length; _++) {
          var E;
          const N = (E = f[_]).percentWidth;
          E.width = (parseInt(N, 10) / 100) * c;
        }
        return r;
      }
      function O(t, e, r) {
        for (let n = e === "", i = e.split("\n"), a = t.lines, o = 0; o < i.length; o++) {
          const s = i[o];
          const l = { styleName: r, text: s, isLineHolder: !s && !n };
          if (o) a.push({ tokens: [l] });
          else {
            const h = (a[a.length - 1] || (a[0] = { tokens: [] })).tokens;
            const u = h.length;
            u === 1 && h[0].isLineHolder ? (h[0] = l) : (s || !u || n) && h.push(l);
          }
        }
      }
      (p.measureText = function(t, e) {
        const r = o();
        return (r.font = e || "12px sans-serif"), r.measureText(t);
      }),
        (e.DEFAULT_FONT = "12px sans-serif"),
        (e.$override = function(t, e) {
          p[t] = e;
        }),
        (e.getWidth = v),
        (e.getBoundingRect = function(t, e, r, i, a, o, s, l) {
          return s
            ? (function(t, e, r, i, a, o, s, l) {
                const h = M(t, {
                  rich: s,
                  truncate: l,
                  font: e,
                  textAlign: r,
                  textPadding: a,
                  textLineHeight: o
                });
                const u = h.outerWidth;
                const c = h.outerHeight;
                const f = g(0, u, r);
                const d = y(0, c, i);
                return new n(f, d, u, c);
              }(t, e, r, i, a, o, s, l))
            : (function(t, e, r, i, a, o, s) {
                const l = P(t, e, a, o, s);
                let h = v(t, e);
                a && (h += a[1] + a[3]);
                const u = l.outerHeight;
                const c = g(0, h, r);
                const f = y(0, u, i);
                const d = new n(c, f, h, u);
                return (d.lineHeight = l.lineHeight), d;
              }(t, e, r, i, a, o, l));
        }),
        (e.adjustTextX = g),
        (e.adjustTextY = y),
        (e.calculateTextPosition = m),
        (e.adjustTextPositionOnRect = function(t, e, r) {
          return m({}, { textPosition: t, textDistance: r }, e);
        }),
        (e.truncateText = x),
        (e.getLineHeight = S),
        (e.measureText = T),
        (e.parsePlainText = P),
        (e.parseRichText = M),
        (e.makeFont = function(t) {
          const e = (t.fontSize || t.fontFamily) && [t.fontStyle, t.fontWeight, `${t.fontSize || 12}px`, t.fontFamily || "sans-serif"].join(" ");
          return (e && u(e)) || t.textFont || t.font;
        });
    },
    function(t, e, r) {
      const n = new (r(22))(50);
      function i() {
        const t = this.__cachedImgObj;
        this.onload = this.onerror = this.__cachedImgObj = null;
        for (let e = 0; e < t.pending.length; e++) {
          const r = t.pending[e];
          const n = r.cb;
          n && n(this, r.cbPayload), r.hostEl.dirty();
        }
        t.pending.length = 0;
      }
      function a(t) {
        return t && t.width && t.height;
      }
      (e.findExistImage = function(t) {
        if (typeof t === "string") {
          const e = n.get(t);
          return e && e.image;
        }
        return t;
      }),
        (e.createOrUpdateImage = function(t, e, r, o, s) {
          if (t) {
            if (typeof t === "string") {
              if ((e && e.__zrImageSrc === t) || !r) return e;
              const l = n.get(t);
              const h = { hostEl: r, cb: o, cbPayload: s };
              return (
                l ? !a((e = l.image)) && l.pending.push(h) : (((e = new Image()).onload = e.onerror = i), n.put(t, (e.__cachedImgObj = { image: e, pending: [h] })), (e.src = e.__zrImageSrc = t)), e
              );
            }
            return t;
          }
          return e;
        }),
        (e.isImageReady = a);
    },
    function(t, e) {
      const r = Math.round;
      function n(t, e, n) {
        if (!e) return t;
        const i = r(2 * t);
        return (i + r(e)) % 2 == 0 ? i / 2 : (i + (n ? 1 : -1)) / 2;
      }
      (e.subPixelOptimizeLine = function(t, e, i) {
        if (e) {
          const a = e.x1;
          const o = e.x2;
          const s = e.y1;
          const l = e.y2;
          (t.x1 = a), (t.x2 = o), (t.y1 = s), (t.y2 = l);
          const h = i && i.lineWidth;
          h && (r(2 * a) === r(2 * o) && (t.x1 = t.x2 = n(a, h, !0)), r(2 * s) === r(2 * l) && (t.y1 = t.y2 = n(s, h, !0)));
        }
      }),
        (e.subPixelOptimizeRect = function(t, e, r) {
          if (e) {
            const i = e.x;
            const a = e.y;
            const o = e.width;
            const s = e.height;
            (t.x = i), (t.y = a), (t.width = o), (t.height = s);
            const l = r && r.lineWidth;
            l && ((t.x = n(i, l, !0)), (t.y = n(a, l, !0)), (t.width = Math.max(n(i + o, l, !1) - t.x, o === 0 ? 0 : 1)), (t.height = Math.max(n(a + s, l, !1) - t.y, s === 0 ? 0 : 1)));
          }
        }),
        (e.subPixelOptimize = n);
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(15);
      const a = n.each;
      const o = n.isObject;
      const s = n.isArray;
      function l(t) {
        return t instanceof Array ? t : t == null ? [] : [t];
      }
      function h(t) {
        return o(t) && t.id && `${t.id}`.indexOf("\0_ec_\0") === 0;
      }
      let u = 0;
      function c(t, e) {
        return t && t.hasOwnProperty(e);
      }
      (e.normalizeToArray = l),
        (e.defaultEmphasis = function(t, e, r) {
          if (t) {
            (t[e] = t[e] || {}), (t.emphasis = t.emphasis || {}), (t.emphasis[e] = t.emphasis[e] || {});
            for (let n = 0, i = r.length; n < i; n++) {
              const a = r[n];
              !t.emphasis[e].hasOwnProperty(a) && t[e].hasOwnProperty(a) && (t.emphasis[e][a] = t[e][a]);
            }
          }
        }),
        (e.TEXT_STYLE_OPTIONS = [
          "fontStyle",
          "fontWeight",
          "fontSize",
          "fontFamily",
          "rich",
          "tag",
          "color",
          "textBorderColor",
          "textBorderWidth",
          "width",
          "height",
          "lineHeight",
          "align",
          "verticalAlign",
          "baseline",
          "shadowColor",
          "shadowBlur",
          "shadowOffsetX",
          "shadowOffsetY",
          "textShadowColor",
          "textShadowBlur",
          "textShadowOffsetX",
          "textShadowOffsetY",
          "backgroundColor",
          "borderColor",
          "borderWidth",
          "borderRadius",
          "padding"
        ]),
        (e.getDataItemValue = function(t) {
          return !o(t) || s(t) || t instanceof Date ? t : t.value;
        }),
        (e.isDataItemOption = function(t) {
          return o(t) && !(t instanceof Array);
        }),
        (e.mappingToExists = function(t, e) {
          e = (e || []).slice();
          const r = n.map(t || [], (t, e) => ({ exist: t }));
          return (
            a(e, (t, n) => {
              if (o(t)) {
                for (var i = 0; i < r.length; i++) if (!r[i].option && t.id != null && r[i].exist.id === `${t.id}`) return (r[i].option = t), void (e[n] = null);
                for (i = 0; i < r.length; i++) {
                  const a = r[i].exist;
                  if (!(r[i].option || (a.id != null && t.id != null) || t.name == null || h(t) || h(a) || a.name !== `${t.name}`)) return (r[i].option = t), void (e[n] = null);
                }
              }
            }),
            a(e, (t, e) => {
              if (o(t)) {
                for (var n = 0; n < r.length; n++) {
                  const i = r[n].exist;
                  if (!r[n].option && !h(i) && t.id == null) {
                    r[n].option = t;
                    break;
                  }
                }
                n >= r.length && r.push({ option: t });
              }
            }),
            r
          );
        }),
        (e.makeIdAndName = function(t) {
          const e = n.createHashMap();
          a(t, (t, r) => {
            const n = t.exist;
            n && e.set(n.id, t);
          }),
            a(t, (t, r) => {
              const i = t.option;
              n.assert(!i || i.id == null || !e.get(i.id) || e.get(i.id) === t, `id duplicates: ${i && i.id}`), i && i.id != null && e.set(i.id, t), !t.keyInfo && (t.keyInfo = {});
            }),
            a(t, (t, r) => {
              const n = t.exist;
              const i = t.option;
              const a = t.keyInfo;
              if (o(i)) {
                if (((a.name = i.name != null ? `${i.name}` : n ? n.name : `series\0${r}`), n)) a.id = n.id;
                else if (i.id != null) a.id = `${i.id}`;
                else {
                  let s = 0;
                  do {
                    a.id = `\0${a.name}\0${s++}`;
                  } while (e.get(a.id));
                }
                e.set(a.id, t);
              }
            });
        }),
        (e.isNameSpecified = function(t) {
          const e = t.name;
          return !(!e || !e.indexOf("series\0"));
        }),
        (e.isIdInner = h),
        (e.compressBatches = function(t, e) {
          const r = {};
          const n = {};
          return i(t || [], r), i(e || [], n, r), [a(r), a(n)];
          function i(t, e, r) {
            for (let n = 0, i = t.length; n < i; n++) {
              for (let a = t[n].seriesId, o = l(t[n].dataIndex), s = r && r[a], h = 0, u = o.length; h < u; h++) {
                const c = o[h];
                s && s[c] ? (s[c] = null) : ((e[a] || (e[a] = {}))[c] = 1);
              }
            }
          }
          function a(t, e) {
            const r = [];
            for (const n in t) {
              if (t.hasOwnProperty(n) && t[n] != null) {
                if (e) r.push(+n);
                else {
                  const i = a(t[n], !0);
                  i.length && r.push({ seriesId: n, dataIndex: i });
                }
              }
            }
            return r;
          }
        }),
        (e.queryDataIndex = function(t, e) {
          return e.dataIndexInside != null
            ? e.dataIndexInside
            : e.dataIndex != null
            ? n.isArray(e.dataIndex)
              ? n.map(e.dataIndex, (e) => t.indexOfRawIndex(e))
              : t.indexOfRawIndex(e.dataIndex)
            : e.name != null
            ? n.isArray(e.name)
              ? n.map(e.name, (e) => t.indexOfName(e))
              : t.indexOfName(e.name)
            : void 0;
        }),
        (e.makeInner = function() {
          const t = `__\0ec_inner_${u++}_${Math.random().toFixed(5)}`;
          return function(e) {
            return e[t] || (e[t] = {});
          };
        }),
        (e.parseFinder = function(t, e, r) {
          if (n.isString(e)) {
            const i = {};
            (i[`${e}Index`] = 0), (e = i);
          }
          const o = r && r.defaultMainType;
          !o || c(e, `${o}Index`) || c(e, `${o}Id`) || c(e, `${o}Name`) || (e[`${o}Index`] = 0);
          const s = {};
          return (
            a(e, (i, a) => {
              i = e[a];
              if (a !== "dataIndex" && a !== "dataIndexInside") {
                const o = a.match(/^(\w+)(Index|Id|Name)$/) || [];
                const l = o[1];
                const h = (o[2] || "").toLowerCase();
                if (!(!l || !h || i == null || (h === "index" && i === "none") || (r && r.includeMainTypes && n.indexOf(r.includeMainTypes, l) < 0))) {
                  const u = { mainType: l };
                  (h === "index" && i === "all") || (u[h] = i);
                  const c = t.queryComponents(u);
                  (s[`${l}Models`] = c), (s[`${l}Model`] = c[0]);
                }
              } else s[a] = i;
            }),
            s
          );
        }),
        (e.setAttribute = function(t, e, r) {
          t.setAttribute ? t.setAttribute(e, r) : (t[e] = r);
        }),
        (e.getAttribute = function(t, e) {
          return t.getAttribute ? t.getAttribute(e) : t[e];
        }),
        (e.getTooltipRenderMode = function(t) {
          return t === "auto" ? (i.domSupported ? "html" : "richText") : t || "html";
        }),
        (e.groupData = function(t, e) {
          const r = n.createHashMap();
          const i = [];
          return (
            n.each(t, (t) => {
              const n = e(t);
              (r.get(n) || (i.push(n), r.set(n, []))).push(t);
            }),
            { keys: i, buckets: r }
          );
        });
    },
    function(t, e) {
      const r = typeof wx === "object" && typeof wx.getSystemInfoSync === "function"
          ? {
              browser: {},
              os: {},
              node: !1,
              wxa: !0,
              canvasSupported: !0,
              svgSupported: !1,
              touchEventsSupported: !0,
              domSupported: !1
            }
          : typeof document === "undefined" && typeof self !== "undefined"
          ? {
              browser: {},
              os: {},
              node: !1,
              worker: !0,
              canvasSupported: !0,
              domSupported: !1
            }
          : typeof navigator === "undefined"
          ? {
              browser: {},
              os: {},
              node: !0,
              worker: !1,
              canvasSupported: !0,
              svgSupported: !0,
              domSupported: !1
            }
          : (function(t) {
              const e = {};
              const r = t.match(/Firefox\/([\d.]+)/);
              const n = t.match(/MSIE\s([\d.]+)/) || t.match(/Trident\/.+?rv:(([\d.]+))/);
              const i = t.match(/Edge\/([\d.]+)/);
              const a = /micromessenger/i.test(t);
              r && ((e.firefox = !0), (e.version = r[1]));
              n && ((e.ie = !0), (e.version = n[1]));
              i && ((e.edge = !0), (e.version = i[1]));
              a && (e.weChat = !0);
              return {
                browser: e,
                os: {},
                node: !1,
                canvasSupported: !!document.createElement("canvas").getContext,
                svgSupported: typeof SVGRect !== "undefined",
                touchEventsSupported: "ontouchstart" in window && !e.ie && !e.edge,
                pointerEventsSupported: "onpointerdown" in window && (e.edge || (e.ie && e.version >= 11)),
                domSupported: typeof document !== "undefined"
              };
            }(navigator.userAgent));
      t.exports = r;
    },
    function(t, e, r) {
      const n = r(0);
      const i = n.createHashMap;
      const a = n.isTypedArray;
      const o = r(35).enableClassCheck;
      const s = r(17);
      const l = s.SOURCE_FORMAT_ORIGINAL;
      const h = s.SERIES_LAYOUT_BY_COLUMN;
      const u = s.SOURCE_FORMAT_UNKNOWN;
      const c = s.SOURCE_FORMAT_TYPED_ARRAY;
      const f = s.SOURCE_FORMAT_KEYED_COLUMNS;
      function d(t) {
        (this.fromDataset = t.fromDataset),
          (this.data = t.data || (t.sourceFormat === f ? {} : [])),
          (this.sourceFormat = t.sourceFormat || u),
          (this.seriesLayoutBy = t.seriesLayoutBy || h),
          (this.dimensionsDefine = t.dimensionsDefine),
          (this.encodeDefine = t.encodeDefine && i(t.encodeDefine)),
          (this.startIndex = t.startIndex || 0),
          (this.dimensionsDetectCount = t.dimensionsDetectCount);
      }
      (d.seriesDataToSource = function(t) {
        return new d({ data: t, sourceFormat: a(t) ? c : l, fromDataset: !1 });
      }),
        o(d);
      const p = d;
      t.exports = p;
    },
    function(t, e) {
      (e.SOURCE_FORMAT_ORIGINAL = "original"),
        (e.SOURCE_FORMAT_ARRAY_ROWS = "arrayRows"),
        (e.SOURCE_FORMAT_OBJECT_ROWS = "objectRows"),
        (e.SOURCE_FORMAT_KEYED_COLUMNS = "keyedColumns"),
        (e.SOURCE_FORMAT_UNKNOWN = "unknown"),
        (e.SOURCE_FORMAT_TYPED_ARRAY = "typedArray"),
        (e.SERIES_LAYOUT_BY_COLUMN = "column"),
        (e.SERIES_LAYOUT_BY_ROW = "row");
    },
    function(t, e) {
      const r = {
        shadowBlur: 1,
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        textShadowBlur: 1,
        textShadowOffsetX: 1,
        textShadowOffsetY: 1,
        textBoxShadowBlur: 1,
        textBoxShadowOffsetX: 1,
        textBoxShadowOffsetY: 1
      };
      t.exports = function(t, e, n) {
        return r.hasOwnProperty(e) ? n * t.dpr : n;
      };
    },
    function(t, e, r) {
      const n = r(43);
      const i = r(44);
      const a = r(20);
      const o = r(45);
      const s = r(0);
      const l = function(t) {
        a.call(this, t), i.call(this, t), o.call(this, t), (this.id = t.id || n());
      };
      (l.prototype = {
        type: "element",
        name: "",
        __zr: null,
        ignore: !1,
        clipPath: null,
        isGroup: !1,
        drift(t, e) {
          switch (this.draggable) {
            case "horizontal":
              e = 0;
              break;
            case "vertical":
              t = 0;
          }
          let r = this.transform;
          r || (r = this.transform = [1, 0, 0, 1, 0, 0]), (r[4] += t), (r[5] += e), this.decomposeTransform(), this.dirty(!1);
        },
        beforeUpdate() {},
        afterUpdate() {},
        update() {
          this.updateTransform();
        },
        traverse(t, e) {},
        attrKV(t, e) {
          if (t === "position" || t === "scale" || t === "origin") {
            if (e) {
              let r = this[t];
              r || (r = this[t] = []), (r[0] = e[0]), (r[1] = e[1]);
            }
          } else this[t] = e;
        },
        hide() {
          (this.ignore = !0), this.__zr && this.__zr.refresh();
        },
        show() {
          (this.ignore = !1), this.__zr && this.__zr.refresh();
        },
        attr(t, e) {
          if (typeof t === "string") this.attrKV(t, e);
          else if (s.isObject(t)) for (const r in t) t.hasOwnProperty(r) && this.attrKV(r, t[r]);
          return this.dirty(!1), this;
        },
        setClipPath(t) {
          const e = this.__zr;
          e && t.addSelfToZr(e), this.clipPath && this.clipPath !== t && this.removeClipPath(), (this.clipPath = t), (t.__zr = e), (t.__clipTarget = this), this.dirty(!1);
        },
        removeClipPath() {
          const t = this.clipPath;
          t && (t.__zr && t.removeSelfFromZr(t.__zr), (t.__zr = null), (t.__clipTarget = null), (this.clipPath = null), this.dirty(!1));
        },
        addSelfToZr(t) {
          this.__zr = t;
          const e = this.animators;
          if (e) for (let r = 0; r < e.length; r++) t.animation.addAnimator(e[r]);
          this.clipPath && this.clipPath.addSelfToZr(t);
        },
        removeSelfFromZr(t) {
          this.__zr = null;
          const e = this.animators;
          if (e) for (let r = 0; r < e.length; r++) t.animation.removeAnimator(e[r]);
          this.clipPath && this.clipPath.removeSelfFromZr(t);
        }
      }),
        s.mixin(l, o),
        s.mixin(l, a),
        s.mixin(l, i);
      const h = l;
      t.exports = h;
    },
    function(t, e, r) {
      const n = r(10);
      const i = r(2);
      const a = n.identity;
      function o(t) {
        return t > 5e-5 || t < -5e-5;
      }
      const s = function(t) {
        (t = t || {}).position || (this.position = [0, 0]), t.rotation == null && (this.rotation = 0), t.scale || (this.scale = [1, 1]), (this.origin = this.origin || null);
      };
      const l = s.prototype;
      (l.transform = null),
        (l.needLocalTransform = function() {
          return o(this.rotation) || o(this.position[0]) || o(this.position[1]) || o(this.scale[0] - 1) || o(this.scale[1] - 1);
        });
      const h = [];
      (l.updateTransform = function() {
        const t = this.parent;
        const e = t && t.transform;
        const r = this.needLocalTransform();
        let i = this.transform;
        if (r || e) {
          (i = i || n.create()), r ? this.getLocalTransform(i) : a(i), e && (r ? n.mul(i, t.transform, i) : n.copy(i, t.transform)), (this.transform = i);
          const o = this.globalScaleRatio;
          if (o != null && o !== 1) {
            this.getGlobalScale(h);
            const s = h[0] < 0 ? -1 : 1;
            const l = h[1] < 0 ? -1 : 1;
            const u = ((h[0] - s) * o + s) / h[0] || 0;
            const c = ((h[1] - l) * o + l) / h[1] || 0;
            (i[0] *= u), (i[1] *= u), (i[2] *= c), (i[3] *= c);
          }
          (this.invTransform = this.invTransform || n.create()), n.invert(this.invTransform, i);
        } else i && a(i);
      }),
        (l.getLocalTransform = function(t) {
          return s.getLocalTransform(this, t);
        }),
        (l.setTransform = function(t) {
          const e = this.transform;
          const r = t.dpr || 1;
          e ? t.setTransform(r * e[0], r * e[1], r * e[2], r * e[3], r * e[4], r * e[5]) : t.setTransform(r, 0, 0, r, 0, 0);
        }),
        (l.restoreTransform = function(t) {
          const e = t.dpr || 1;
          t.setTransform(e, 0, 0, e, 0, 0);
        });
      const u = [];
      const c = n.create();
      (l.setLocalTransform = function(t) {
        if (t) {
          let e = t[0] * t[0] + t[1] * t[1];
          let r = t[2] * t[2] + t[3] * t[3];
          const n = this.position;
          const i = this.scale;
          o(e - 1) && (e = Math.sqrt(e)),
            o(r - 1) && (r = Math.sqrt(r)),
            t[0] < 0 && (e = -e),
            t[3] < 0 && (r = -r),
            (n[0] = t[4]),
            (n[1] = t[5]),
            (i[0] = e),
            (i[1] = r),
            (this.rotation = Math.atan2(-t[1] / r, t[0] / e));
        }
      }),
        (l.decomposeTransform = function() {
          if (this.transform) {
            const t = this.parent;
            let e = this.transform;
            t && t.transform && (n.mul(u, t.invTransform, e), (e = u));
            const r = this.origin;
            r && (r[0] || r[1]) && ((c[4] = r[0]), (c[5] = r[1]), n.mul(u, e, c), (u[4] -= r[0]), (u[5] -= r[1]), (e = u)), this.setLocalTransform(e);
          }
        }),
        (l.getGlobalScale = function(t) {
          const e = this.transform;
          return (
            (t = t || []),
            e ? ((t[0] = Math.sqrt(e[0] * e[0] + e[1] * e[1])), (t[1] = Math.sqrt(e[2] * e[2] + e[3] * e[3])), e[0] < 0 && (t[0] = -t[0]), e[3] < 0 && (t[1] = -t[1]), t) : ((t[0] = 1), (t[1] = 1), t)
          );
        }),
        (l.transformCoordToLocal = function(t, e) {
          const r = [t, e];
          const n = this.invTransform;
          return n && i.applyTransform(r, r, n), r;
        }),
        (l.transformCoordToGlobal = function(t, e) {
          const r = [t, e];
          const n = this.transform;
          return n && i.applyTransform(r, r, n), r;
        }),
        (s.getLocalTransform = function(t, e) {
          a((e = e || []));
          const r = t.origin;
          const i = t.scale || [1, 1];
          const o = t.rotation || 0;
          const s = t.position || [0, 0];
          return r && ((e[4] -= r[0]), (e[5] -= r[1])), n.scale(e, e, i), o && n.rotate(e, e, o), r && ((e[4] += r[0]), (e[5] += r[1])), (e[4] += s[0]), (e[5] += s[1]), e;
        });
      const f = s;
      t.exports = f;
    },
    function(t, e, r) {
      const n = r(22);
      const i = {
        transparent: [0, 0, 0, 0],
        aliceblue: [240, 248, 255, 1],
        antiquewhite: [250, 235, 215, 1],
        aqua: [0, 255, 255, 1],
        aquamarine: [127, 255, 212, 1],
        azure: [240, 255, 255, 1],
        beige: [245, 245, 220, 1],
        bisque: [255, 228, 196, 1],
        black: [0, 0, 0, 1],
        blanchedalmond: [255, 235, 205, 1],
        blue: [0, 0, 255, 1],
        blueviolet: [138, 43, 226, 1],
        brown: [165, 42, 42, 1],
        burlywood: [222, 184, 135, 1],
        cadetblue: [95, 158, 160, 1],
        chartreuse: [127, 255, 0, 1],
        chocolate: [210, 105, 30, 1],
        coral: [255, 127, 80, 1],
        cornflowerblue: [100, 149, 237, 1],
        cornsilk: [255, 248, 220, 1],
        crimson: [220, 20, 60, 1],
        cyan: [0, 255, 255, 1],
        darkblue: [0, 0, 139, 1],
        darkcyan: [0, 139, 139, 1],
        darkgoldenrod: [184, 134, 11, 1],
        darkgray: [169, 169, 169, 1],
        darkgreen: [0, 100, 0, 1],
        darkgrey: [169, 169, 169, 1],
        darkkhaki: [189, 183, 107, 1],
        darkmagenta: [139, 0, 139, 1],
        darkolivegreen: [85, 107, 47, 1],
        darkorange: [255, 140, 0, 1],
        darkorchid: [153, 50, 204, 1],
        darkred: [139, 0, 0, 1],
        darksalmon: [233, 150, 122, 1],
        darkseagreen: [143, 188, 143, 1],
        darkslateblue: [72, 61, 139, 1],
        darkslategray: [47, 79, 79, 1],
        darkslategrey: [47, 79, 79, 1],
        darkturquoise: [0, 206, 209, 1],
        darkviolet: [148, 0, 211, 1],
        deeppink: [255, 20, 147, 1],
        deepskyblue: [0, 191, 255, 1],
        dimgray: [105, 105, 105, 1],
        dimgrey: [105, 105, 105, 1],
        dodgerblue: [30, 144, 255, 1],
        firebrick: [178, 34, 34, 1],
        floralwhite: [255, 250, 240, 1],
        forestgreen: [34, 139, 34, 1],
        fuchsia: [255, 0, 255, 1],
        gainsboro: [220, 220, 220, 1],
        ghostwhite: [248, 248, 255, 1],
        gold: [255, 215, 0, 1],
        goldenrod: [218, 165, 32, 1],
        gray: [128, 128, 128, 1],
        green: [0, 128, 0, 1],
        greenyellow: [173, 255, 47, 1],
        grey: [128, 128, 128, 1],
        honeydew: [240, 255, 240, 1],
        hotpink: [255, 105, 180, 1],
        indianred: [205, 92, 92, 1],
        indigo: [75, 0, 130, 1],
        ivory: [255, 255, 240, 1],
        khaki: [240, 230, 140, 1],
        lavender: [230, 230, 250, 1],
        lavenderblush: [255, 240, 245, 1],
        lawngreen: [124, 252, 0, 1],
        lemonchiffon: [255, 250, 205, 1],
        lightblue: [173, 216, 230, 1],
        lightcoral: [240, 128, 128, 1],
        lightcyan: [224, 255, 255, 1],
        lightgoldenrodyellow: [250, 250, 210, 1],
        lightgray: [211, 211, 211, 1],
        lightgreen: [144, 238, 144, 1],
        lightgrey: [211, 211, 211, 1],
        lightpink: [255, 182, 193, 1],
        lightsalmon: [255, 160, 122, 1],
        lightseagreen: [32, 178, 170, 1],
        lightskyblue: [135, 206, 250, 1],
        lightslategray: [119, 136, 153, 1],
        lightslategrey: [119, 136, 153, 1],
        lightsteelblue: [176, 196, 222, 1],
        lightyellow: [255, 255, 224, 1],
        lime: [0, 255, 0, 1],
        limegreen: [50, 205, 50, 1],
        linen: [250, 240, 230, 1],
        magenta: [255, 0, 255, 1],
        maroon: [128, 0, 0, 1],
        mediumaquamarine: [102, 205, 170, 1],
        mediumblue: [0, 0, 205, 1],
        mediumorchid: [186, 85, 211, 1],
        mediumpurple: [147, 112, 219, 1],
        mediumseagreen: [60, 179, 113, 1],
        mediumslateblue: [123, 104, 238, 1],
        mediumspringgreen: [0, 250, 154, 1],
        mediumturquoise: [72, 209, 204, 1],
        mediumvioletred: [199, 21, 133, 1],
        midnightblue: [25, 25, 112, 1],
        mintcream: [245, 255, 250, 1],
        mistyrose: [255, 228, 225, 1],
        moccasin: [255, 228, 181, 1],
        navajowhite: [255, 222, 173, 1],
        navy: [0, 0, 128, 1],
        oldlace: [253, 245, 230, 1],
        olive: [128, 128, 0, 1],
        olivedrab: [107, 142, 35, 1],
        orange: [255, 165, 0, 1],
        orangered: [255, 69, 0, 1],
        orchid: [218, 112, 214, 1],
        palegoldenrod: [238, 232, 170, 1],
        palegreen: [152, 251, 152, 1],
        paleturquoise: [175, 238, 238, 1],
        palevioletred: [219, 112, 147, 1],
        papayawhip: [255, 239, 213, 1],
        peachpuff: [255, 218, 185, 1],
        peru: [205, 133, 63, 1],
        pink: [255, 192, 203, 1],
        plum: [221, 160, 221, 1],
        powderblue: [176, 224, 230, 1],
        purple: [128, 0, 128, 1],
        red: [255, 0, 0, 1],
        rosybrown: [188, 143, 143, 1],
        royalblue: [65, 105, 225, 1],
        saddlebrown: [139, 69, 19, 1],
        salmon: [250, 128, 114, 1],
        sandybrown: [244, 164, 96, 1],
        seagreen: [46, 139, 87, 1],
        seashell: [255, 245, 238, 1],
        sienna: [160, 82, 45, 1],
        silver: [192, 192, 192, 1],
        skyblue: [135, 206, 235, 1],
        slateblue: [106, 90, 205, 1],
        slategray: [112, 128, 144, 1],
        slategrey: [112, 128, 144, 1],
        snow: [255, 250, 250, 1],
        springgreen: [0, 255, 127, 1],
        steelblue: [70, 130, 180, 1],
        tan: [210, 180, 140, 1],
        teal: [0, 128, 128, 1],
        thistle: [216, 191, 216, 1],
        tomato: [255, 99, 71, 1],
        turquoise: [64, 224, 208, 1],
        violet: [238, 130, 238, 1],
        wheat: [245, 222, 179, 1],
        white: [255, 255, 255, 1],
        whitesmoke: [245, 245, 245, 1],
        yellow: [255, 255, 0, 1],
        yellowgreen: [154, 205, 50, 1]
      };
      function a(t) {
        return (t = Math.round(t)) < 0 ? 0 : t > 255 ? 255 : t;
      }
      function o(t) {
        return t < 0 ? 0 : t > 1 ? 1 : t;
      }
      function s(t) {
        return t.length && t.charAt(t.length - 1) === "%" ? a((parseFloat(t) / 100) * 255) : a(parseInt(t, 10));
      }
      function l(t) {
        return t.length && t.charAt(t.length - 1) === "%" ? o(parseFloat(t) / 100) : o(parseFloat(t));
      }
      function h(t, e, r) {
        return r < 0 ? (r += 1) : r > 1 && (r -= 1), 6 * r < 1 ? t + (e - t) * r * 6 : 2 * r < 1 ? e : 3 * r < 2 ? t + (e - t) * (2 / 3 - r) * 6 : t;
      }
      function u(t, e, r) {
        return t + (e - t) * r;
      }
      function c(t, e, r, n, i) {
        return (t[0] = e), (t[1] = r), (t[2] = n), (t[3] = i), t;
      }
      function f(t, e) {
        return (t[0] = e[0]), (t[1] = e[1]), (t[2] = e[2]), (t[3] = e[3]), t;
      }
      const d = new n(20);
      let p = null;
      function v(t, e) {
        p && f(p, e), (p = d.put(t, p || e.slice()));
      }
      function g(t, e) {
        if (t) {
          e = e || [];
          const r = d.get(t);
          if (r) return f(e, r);
          let n;
          const a = (t += "").replace(/ /g, "").toLowerCase();
          if (a in i) return f(e, i[a]), v(t, e), e;
          if (a.charAt(0) === "#") {
            return a.length === 4
              ? (n = parseInt(a.substr(1), 16)) >= 0 && n <= 4095
                ? (c(e, ((3840 & n) >> 4) | ((3840 & n) >> 8), (240 & n) | ((240 & n) >> 4), (15 & n) | ((15 & n) << 4), 1), v(t, e), e)
                : void c(e, 0, 0, 0, 1)
              : a.length === 7
              ? (n = parseInt(a.substr(1), 16)) >= 0 && n <= 16777215
                ? (c(e, (16711680 & n) >> 16, (65280 & n) >> 8, 255 & n, 1), v(t, e), e)
                : void c(e, 0, 0, 0, 1)
              : void 0;
          }
          const o = a.indexOf("(");
          const h = a.indexOf(")");
          if (o !== -1 && h + 1 === a.length) {
            const u = a.substr(0, o);
            const p = a.substr(o + 1, h - (o + 1)).split(",");
            let g = 1;
            switch (u) {
              case "rgba":
                if (p.length !== 4) return void c(e, 0, 0, 0, 1);
                g = l(p.pop());
              case "rgb":
                return p.length !== 3 ? void c(e, 0, 0, 0, 1) : (c(e, s(p[0]), s(p[1]), s(p[2]), g), v(t, e), e);
              case "hsla":
                return p.length !== 4 ? void c(e, 0, 0, 0, 1) : ((p[3] = l(p[3])), y(p, e), v(t, e), e);
              case "hsl":
                return p.length !== 3 ? void c(e, 0, 0, 0, 1) : (y(p, e), v(t, e), e);
              default:
                return;
            }
          }
          c(e, 0, 0, 0, 1);
        }
      }
      function y(t, e) {
        const r = (((parseFloat(t[0]) % 360) + 360) % 360) / 360;
        const n = l(t[1]);
        const i = l(t[2]);
        const o = i <= 0.5 ? i * (n + 1) : i + n - i * n;
        const s = 2 * i - o;
        return c((e = e || []), a(255 * h(s, o, r + 1 / 3)), a(255 * h(s, o, r)), a(255 * h(s, o, r - 1 / 3)), 1), t.length === 4 && (e[3] = t[3]), e;
      }
      function m(t, e, r) {
        if (e && e.length && t >= 0 && t <= 1) {
          r = r || [];
          const n = t * (e.length - 1);
          const i = Math.floor(n);
          const s = Math.ceil(n);
          const l = e[i];
          const h = e[s];
          const c = n - i;
          return (r[0] = a(u(l[0], h[0], c))), (r[1] = a(u(l[1], h[1], c))), (r[2] = a(u(l[2], h[2], c))), (r[3] = o(u(l[3], h[3], c))), r;
        }
      }
      const x = m;
      function _(t, e, r) {
        if (e && e.length && t >= 0 && t <= 1) {
          const n = t * (e.length - 1);
          const i = Math.floor(n);
          const s = Math.ceil(n);
          const l = g(e[i]);
          const h = g(e[s]);
          const c = n - i;
          const f = w([a(u(l[0], h[0], c)), a(u(l[1], h[1], c)), a(u(l[2], h[2], c)), o(u(l[3], h[3], c))], "rgba");
          return r
            ? {
                color: f,
                leftIndex: i,
                rightIndex: s,
                value: n
              }
            : f;
        }
      }
      const b = _;
      function w(t, e) {
        if (t && t.length) {
          let r = `${t[0]},${t[1]},${t[2]}`;
          return (e !== "rgba" && e !== "hsva" && e !== "hsla") || (r += `,${t[3]}`), `${e}(${r})`;
        }
      }
      (e.parse = g),
        (e.lift = function(t, e) {
          const r = g(t);
          if (r) {
            for (let n = 0; n < 3; n++) (r[n] = e < 0 ? (r[n] * (1 - e)) | 0 : ((255 - r[n]) * e + r[n]) | 0), r[n] > 255 ? (r[n] = 255) : t[n] < 0 && (r[n] = 0);
            return w(r, r.length === 4 ? "rgba" : "rgb");
          }
        }),
        (e.toHex = function(t) {
          const e = g(t);
          if (e) return ((1 << 24) + (e[0] << 16) + (e[1] << 8) + +e[2]).toString(16).slice(1);
        }),
        (e.fastLerp = m),
        (e.fastMapToColor = x),
        (e.lerp = _),
        (e.mapToColor = b),
        (e.modifyHSL = function(t, e, r, n) {
          if ((t = g(t))) {
            return (
              (t = (function(t) {
                if (t) {
                  let e;
                  let r;
                  const n = t[0] / 255;
                  const i = t[1] / 255;
                  const a = t[2] / 255;
                  const o = Math.min(n, i, a);
                  const s = Math.max(n, i, a);
                  const l = s - o;
                  const h = (s + o) / 2;
                  if (l === 0) (e = 0), (r = 0);
                  else {
                    r = h < 0.5 ? l / (s + o) : l / (2 - s - o);
                    const u = ((s - n) / 6 + l / 2) / l;
                    const c = ((s - i) / 6 + l / 2) / l;
                    const f = ((s - a) / 6 + l / 2) / l;
                    n === s ? (e = f - c) : i === s ? (e = 1 / 3 + u - f) : a === s && (e = 2 / 3 + c - u), e < 0 && (e += 1), e > 1 && (e -= 1);
                  }
                  const d = [360 * e, r, h];
                  return t[3] != null && d.push(t[3]), d;
                }
              }(t))),
              e != null && (t[0] = ((i = e), (i = Math.round(i)) < 0 ? 0 : i > 360 ? 360 : i)),
              r != null && (t[1] = l(r)),
              n != null && (t[2] = l(n)),
              w(y(t), "rgba")
            );
          }
          let i;
        }),
        (e.modifyAlpha = function(t, e) {
          if ((t = g(t)) && e != null) return (t[3] = o(e)), w(t, "rgba");
        }),
        (e.stringify = w);
    },
    function(t, e) {
      const r = function() {
        (this.head = null), (this.tail = null), (this._len = 0);
      };
      const n = r.prototype;
      (n.insert = function(t) {
        const e = new i(t);
        return this.insertEntry(e), e;
      }),
        (n.insertEntry = function(t) {
          this.head ? ((this.tail.next = t), (t.prev = this.tail), (t.next = null), (this.tail = t)) : (this.head = this.tail = t), this._len++;
        }),
        (n.remove = function(t) {
          const e = t.prev;
          const r = t.next;
          e ? (e.next = r) : (this.head = r), r ? (r.prev = e) : (this.tail = e), (t.next = t.prev = null), this._len--;
        }),
        (n.len = function() {
          return this._len;
        }),
        (n.clear = function() {
          (this.head = this.tail = null), (this._len = 0);
        });
      var i = function(t) {
        (this.value = t), this.next, this.prev;
      };
      const a = function(t) {
        (this._list = new r()), (this._map = {}), (this._maxSize = t || 10), (this._lastRemovedEntry = null);
      };
      const o = a.prototype;
      (o.put = function(t, e) {
        const r = this._list;
        const n = this._map;
        let a = null;
        if (n[t] == null) {
          const o = r.len();
          let s = this._lastRemovedEntry;
          if (o >= this._maxSize && o > 0) {
            const l = r.head;
            r.remove(l), delete n[l.key], (a = l.value), (this._lastRemovedEntry = l);
          }
          s ? (s.value = e) : (s = new i(e)), (s.key = t), r.insertEntry(s), (n[t] = s);
        }
        return a;
      }),
        (o.get = function(t) {
          const e = this._map[t];
          const r = this._list;
          if (e != null) return e !== r.tail && (r.remove(e), r.insertEntry(e)), e.value;
        }),
        (o.clear = function() {
          this._list.clear(), (this._map = {});
        });
      const s = a;
      t.exports = s;
    },
    function(t, e) {
      let r = 1;
      typeof window !== "undefined" && (r = Math.max(window.devicePixelRatio || 1, 1));
      const n = r;
      (e.debugMode = 0), (e.devicePixelRatio = n);
    },
    function(t, e, r) {
      const n = r(0);
      const i = n.retrieve2;
      const a = n.retrieve3;
      const o = n.each;
      const s = n.normalizeCssArray;
      const l = n.isString;
      const h = n.isObject;
      const u = r(11);
      const c = r(25);
      const f = r(12);
      const d = r(18);
      const p = r(7);
      const v = p.ContextCachedBy;
      const g = p.WILL_BE_RESTORED;
      const y = u.DEFAULT_FONT;
      const m = { left: 1, right: 1, center: 1 };
      const x = { top: 1, bottom: 1, middle: 1 };
      const _ = [
        ["textShadowBlur", "shadowBlur", 0],
        ["textShadowOffsetX", "shadowOffsetX", 0],
        ["textShadowOffsetY", "shadowOffsetY", 0],
        ["textShadowColor", "shadowColor", "transparent"]
      ];
      const b = {};
      const w = {};
      function S(t) {
        if (t) {
          t.font = u.makeFont(t);
          let e = t.textAlign;
          e === "middle" && (e = "center"), (t.textAlign = e == null || m[e] ? e : "left");
          let r = t.textVerticalAlign || t.textBaseline;
          r === "center" && (r = "middle"), (t.textVerticalAlign = r == null || x[r] ? r : "top"), t.textPadding && (t.textPadding = s(t.textPadding));
        }
      }
      function T(t, e, r, n, i) {
        if (r && e.textRotation) {
          const a = e.textOrigin;
          a === "center" ? ((n = r.width / 2 + r.x), (i = r.height / 2 + r.y)) : a && ((n = a[0] + r.x), (i = a[1] + r.y)), t.translate(n, i), t.rotate(-e.textRotation), t.translate(-n, -i);
        }
      }
      function P(t, e, r, n, o, s, l, h) {
        const u = n.rich[r.styleName] || {};
        u.text = r.text;
        const c = r.textVerticalAlign;
        let f = s + o / 2;
        c === "top" ? (f = s + r.height / 2) : c === "bottom" && (f = s + o - r.height / 2),
          !r.isLineHolder && M(u) && O(t, e, u, h === "right" ? l - r.width : h === "center" ? l - r.width / 2 : l, f - r.height / 2, r.width, r.height);
        const d = r.textPadding;
        d && ((l = R(l, h, d)), (f -= r.height / 2 - d[2] - r.textHeight / 2)),
          A(e, "shadowBlur", a(u.textShadowBlur, n.textShadowBlur, 0)),
          A(e, "shadowColor", u.textShadowColor || n.textShadowColor || "transparent"),
          A(e, "shadowOffsetX", a(u.textShadowOffsetX, n.textShadowOffsetX, 0)),
          A(e, "shadowOffsetY", a(u.textShadowOffsetY, n.textShadowOffsetY, 0)),
          A(e, "textAlign", h),
          A(e, "textBaseline", "middle"),
          A(e, "font", r.font || y);
        const p = D(u.textStroke || n.textStroke, g);
        const v = I(u.textFill || n.textFill);
        var g = i(u.textStrokeWidth, n.textStrokeWidth);
        p && (A(e, "lineWidth", g), A(e, "strokeStyle", p), e.strokeText(r.text, l, f)), v && (A(e, "fillStyle", v), e.fillText(r.text, l, f));
      }
      function M(t) {
        return !!(t.textBackgroundColor || (t.textBorderWidth && t.textBorderColor));
      }
      function O(t, e, r, n, i, a, o) {
        const s = r.textBackgroundColor;
        const u = r.textBorderWidth;
        const d = r.textBorderColor;
        const p = l(s);
        if (
          (A(e, "shadowBlur", r.textBoxShadowBlur || 0),
          A(e, "shadowColor", r.textBoxShadowColor || "transparent"),
          A(e, "shadowOffsetX", r.textBoxShadowOffsetX || 0),
          A(e, "shadowOffsetY", r.textBoxShadowOffsetY || 0),
          p || (u && d))
        ) {
          e.beginPath();
          const v = r.textBorderRadius;
          v
            ? c.buildPath(e, {
                x: n,
                y: i,
                width: a,
                height: o,
                r: v
              })
            : e.rect(n, i, a, o),
            e.closePath();
        }
        if (p) {
          if ((A(e, "fillStyle", s), r.fillOpacity != null)) {
            var g = e.globalAlpha;
            (e.globalAlpha = r.fillOpacity * r.opacity), e.fill(), (e.globalAlpha = g);
          } else e.fill();
        } else if (h(s)) {
          let y = s.image;
          (y = f.createOrUpdateImage(y, null, t, k, s)) && f.isImageReady(y) && e.drawImage(y, n, i, a, o);
        }
        if (u && d) {
          if ((A(e, "lineWidth", u), A(e, "strokeStyle", d), r.strokeOpacity != null)) {
            g = e.globalAlpha;
            (e.globalAlpha = r.strokeOpacity * r.opacity), e.stroke(), (e.globalAlpha = g);
          } else e.stroke();
        }
      }
      function k(t, e) {
        e.image = t;
      }
      function C(t, e, r, n) {
        let i = r.x || 0;
        let a = r.y || 0;
        let o = r.textAlign;
        let s = r.textVerticalAlign;
        if (n) {
          const l = r.textPosition;
          if (l instanceof Array) (i = n.x + L(l[0], n.width)), (a = n.y + L(l[1], n.height));
          else {
            const h = e && e.calculateTextPosition ? e.calculateTextPosition(b, r, n) : u.calculateTextPosition(b, r, n);
            (i = h.x), (a = h.y), (o = o || h.textAlign), (s = s || h.textVerticalAlign);
          }
          const c = r.textOffset;
          c && ((i += c[0]), (a += c[1]));
        }
        return ((t = t || {}).baseX = i), (t.baseY = a), (t.textAlign = o), (t.textVerticalAlign = s), t;
      }
      function A(t, e, r) {
        return (t[e] = d(t, e, r)), t[e];
      }
      function D(t, e) {
        return t == null || e <= 0 || t === "transparent" || t === "none" ? null : t.image || t.colorStops ? "#000" : t;
      }
      function I(t) {
        return t == null || t === "none" ? null : t.image || t.colorStops ? "#000" : t;
      }
      function L(t, e) {
        return typeof t === "string" ? (t.lastIndexOf("%") >= 0 ? (parseFloat(t) / 100) * e : parseFloat(t)) : t;
      }
      function R(t, e, r) {
        return e === "right" ? t - r[1] : e === "center" ? t + r[3] / 2 - r[1] / 2 : t + r[3];
      }
      (e.normalizeTextStyle = function(t) {
        return S(t), o(t.rich, S), t;
      }),
        (e.renderText = function(t, e, r, n, i, a) {
          n.rich
            ? (function(t, e, r, n, i, a) {
                a !== g && (e.__attrCachedBy = v.NONE);
                let o = t.__textCotentBlock;
                (o && !t.__dirtyText) || (o = t.__textCotentBlock = u.parseRichText(r, n));
                !(function(t, e, r, n, i) {
                  const a = r.width;
                  const o = r.outerWidth;
                  const s = r.outerHeight;
                  const l = n.textPadding;
                  const h = C(w, t, n, i);
                  const c = h.baseX;
                  const f = h.baseY;
                  const d = h.textAlign;
                  const p = h.textVerticalAlign;
                  T(e, n, i, c, f);
                  const v = u.adjustTextX(c, o, d);
                  const g = u.adjustTextY(f, s, p);
                  let y = v;
                  let m = g;
                  l && ((y += l[3]), (m += l[0]));
                  const x = y + a;
                  M(n) && O(t, e, n, v, g, o, s);
                  for (let _ = 0; _ < r.lines.length; _++) {
                    for (var b, S = r.lines[_], k = S.tokens, A = k.length, D = S.lineHeight, I = S.width, L = 0, R = y, B = x, F = A - 1; L < A && (!(b = k[L]).textAlign || b.textAlign === "left");) P(t, e, b, n, D, m, R, "left"), (I -= b.width), (R += b.width), L++;
                    for (; F >= 0 && (b = k[F]).textAlign === "right";) P(t, e, b, n, D, m, B, "right"), (I -= b.width), (B -= b.width), F--;
                    for (R += (a - (R - y) - (x - B) - I) / 2; L <= F;) (b = k[L]), P(t, e, b, n, D, m, R + b.width / 2, "center"), (R += b.width), L++;
                    m += D;
                  }
                }(t, e, o, n, i));
              }(t, e, r, n, i, a))
            : (function(t, e, r, n, i, a) {
                let o;
                const s = M(n);
                let l = !1;
                const h = e.__attrCachedBy === v.PLAIN_TEXT;
                a !== g ? (a && ((o = a.style), (l = !s && h && o)), (e.__attrCachedBy = s ? v.NONE : v.PLAIN_TEXT)) : h && (e.__attrCachedBy = v.NONE);
                const c = n.font || y;
                (l && c === (o.font || y)) || (e.font = c);
                let f = t.__computedFont;
                t.__styleFont !== c && ((t.__styleFont = c), (f = t.__computedFont = e.font));
                const p = n.textPadding;
                const m = n.textLineHeight;
                let x = t.__textCotentBlock;
                (x && !t.__dirtyText) || (x = t.__textCotentBlock = u.parsePlainText(r, f, p, m, n.truncate));
                const b = x.outerHeight;
                const S = x.lines;
                const P = x.lineHeight;
                const k = C(w, t, n, i);
                const A = k.baseX;
                const L = k.baseY;
                const B = k.textAlign || "left";
                const F = k.textVerticalAlign;
                T(e, n, i, A, L);
                const E = u.adjustTextY(L, b, F);
                let N = A;
                let z = E;
                if (s || p) {
                  let W = u.getWidth(r, f);
                  p && (W += p[1] + p[3]);
                  const q = u.adjustTextX(A, W, B);
                  s && O(t, e, n, q, E, W, b), p && ((N = R(A, B, p)), (z += p[0]));
                }
                (e.textAlign = B), (e.textBaseline = "middle"), (e.globalAlpha = n.opacity || 1);
                for (var H = 0; H < _.length; H++) {
                  const j = _[H];
                  const Y = j[0];
                  const U = j[1];
                  const V = n[Y];
                  (l && V === o[Y]) || (e[U] = d(e, U, V || j[2]));
                }
                z += P / 2;
                const G = n.textStrokeWidth;
                const X = l ? o.textStrokeWidth : null;
                const Z = !l || G !== X;
                const Q = !l || Z || n.textStroke !== o.textStroke;
                const $ = D(n.textStroke, G);
                const K = I(n.textFill);
                $ && (Z && (e.lineWidth = G), Q && (e.strokeStyle = $));
                K && ((l && n.textFill === o.textFill) || (e.fillStyle = K));
                if (S.length === 1) $ && e.strokeText(S[0], N, z), K && e.fillText(S[0], N, z);
                else for (H = 0; H < S.length; H++) $ && e.strokeText(S[H], N, z), K && e.fillText(S[H], N, z), (z += P);
              }(t, e, r, n, i, a));
        }),
        (e.getBoxPosition = C),
        (e.getStroke = D),
        (e.getFill = I),
        (e.parsePercent = L),
        (e.needDrawText = function(t, e) {
          return t != null && (t || e.textBackgroundColor || (e.textBorderWidth && e.textBorderColor) || e.textPadding);
        });
    },
    function(t, e) {
      e.buildPath = function(t, e) {
        let r;
        let n;
        let i;
        let a;
        let o;
        let s = e.x;
        let l = e.y;
        let h = e.width;
        let u = e.height;
        const c = e.r;
        h < 0 && ((s += h), (h = -h)),
          u < 0 && ((l += u), (u = -u)),
          typeof c === "number"
            ? (r = n = i = a = c)
            : c instanceof Array
            ? c.length === 1
              ? (r = n = i = a = c[0])
              : c.length === 2
              ? ((r = i = c[0]), (n = a = c[1]))
              : c.length === 3
              ? ((r = c[0]), (n = a = c[1]), (i = c[2]))
              : ((r = c[0]), (n = c[1]), (i = c[2]), (a = c[3]))
            : (r = n = i = a = 0),
          r + n > h && ((r *= h / (o = r + n)), (n *= h / o)),
          i + a > h && ((i *= h / (o = i + a)), (a *= h / o)),
          n + i > u && ((n *= u / (o = n + i)), (i *= u / o)),
          r + a > u && ((r *= u / (o = r + a)), (a *= u / o)),
          t.moveTo(s + r, l),
          t.lineTo(s + h - n, l),
          n !== 0 && t.arc(s + h - n, l + n, n, -Math.PI / 2, 0),
          t.lineTo(s + h, l + u - i),
          i !== 0 && t.arc(s + h - i, l + u - i, i, 0, Math.PI / 2),
          t.lineTo(s + a, l + u),
          a !== 0 && t.arc(s + a, l + u - a, a, Math.PI / 2, Math.PI),
          t.lineTo(s, l + r),
          r !== 0 && t.arc(s + r, l + r, r, Math.PI, 1.5 * Math.PI);
      };
    },
    function(t, e) {
      const r = 2 * Math.PI;
      e.normalizeRadian = function(t) {
        return (t %= r) < 0 && (t += r), t;
      };
    },
    function(t, e, r) {
      const n = r(68);
      const i = r(69);
      e.buildPath = function(t, e, r) {
        let a = e.points;
        const o = e.smooth;
        if (a && a.length >= 2) {
          if (o && o !== "spline") {
            const s = i(a, o, r, e.smoothConstraint);
            t.moveTo(a[0][0], a[0][1]);
            for (var l = a.length, h = 0; h < (r ? l : l - 1); h++) {
              const u = s[2 * h];
              const c = s[2 * h + 1];
              const f = a[(h + 1) % l];
              t.bezierCurveTo(u[0], u[1], c[0], c[1], f[0], f[1]);
            }
          } else {
            o === "spline" && (a = n(a, r)), t.moveTo(a[0][0], a[0][1]);
            h = 1;
            for (let d = a.length; h < d; h++) t.lineTo(a[h][0], a[h][1]);
          }
          r && t.closePath();
        }
      };
    },
    function(t, e) {
      const r = function(t) {
        this.colorStops = t || [];
      };
      r.prototype = {
        constructor: r,
        addColorStop(t, e) {
          this.colorStops.push({ offset: t, color: e });
        }
      };
      const n = r;
      t.exports = n;
    },
    function(t, e, r) {
      t.exports = r(30);
    },
    function(t, e, r) {
      const n = r(5);
      r(31), r(38), n.registerVisual(n.util.curry(r(80), "liquidFill"));
    },
    function(t, e, r) {
      const n = r(32);
      const i = r(5);
      i.extendSeriesModel({
        type: "series.liquidFill",
        visualColorAccessPath: "textStyle.normal.color",
        optionUpdated() {
          const t = this.option;
          t.gridSize = Math.max(Math.floor(t.gridSize), 4);
        },
        getInitialData(t, e) {
          const r = n(["value"], t.data);
          const a = new i.List(r, this);
          return a.initData(t.data), a;
        },
        defaultOption: {
          color: ["#294D99", "#156ACF", "#1598ED", "#45BDFF"],
          center: ["50%", "50%"],
          radius: "50%",
          amplitude: "8%",
          waveLength: "80%",
          phase: "auto",
          period: "auto",
          direction: "right",
          shape: "circle",
          waveAnimation: !0,
          animationEasing: "linear",
          animationEasingUpdate: "linear",
          animationDuration: 2e3,
          animationDurationUpdate: 1e3,
          outline: {
            show: !0,
            borderDistance: 8,
            itemStyle: {
              color: "none",
              borderColor: "#294D99",
              borderWidth: 8,
              shadowBlur: 20,
              shadowColor: "rgba(0, 0, 0, 0.25)"
            }
          },
          backgroundStyle: { color: "#E3F7FF" },
          itemStyle: { opacity: 0.95, shadowBlur: 50, shadowColor: "rgba(0, 0, 0, 0.4)" },
          label: {
            show: !0,
            color: "#294D99",
            insideColor: "#fff",
            fontSize: 50,
            fontWeight: "bold",
            align: "center",
            baseline: "middle",
            position: "inside"
          },
          emphasis: { itemStyle: { opacity: 0.8 } }
        }
      });
    },
    function(t, e, r) {
      const n = r(0);
      const i = n.createHashMap;
      const a = n.each;
      const o = n.isString;
      const s = n.defaults;
      const l = n.extend;
      const h = n.isObject;
      const u = n.clone;
      const c = r(14).normalizeToArray;
      const f = r(33);
      const d = f.guessOrdinal;
      const p = f.BE_ORDINAL;
      const v = r(16);
      const g = r(36).OTHER_DIMENSIONS;
      const y = r(37);
      function m(t, e, r) {
        if (r || e.get(t) != null) {
          for (var n = 0; e.get(t + n) != null;) n++;
          t += n;
        }
        return e.set(t, !0), t;
      }
      const x = function(t, e, r) {
        v.isInstance(e) || (e = v.seriesDataToSource(e)), (r = r || {}), (t = (t || []).slice());
        for (
          var n = (r.dimsDef || []).slice(),
            f = i(),
            x = i(),
            _ = [],
            b = (function(t, e, r, n) {
              let i = Math.max(t.dimensionsDetectCount || 1, e.length, r.length, n || 0);
              return (
                a(e, (t) => {
                  const e = t.dimsDef;
                  e && (i = Math.max(i, e.length));
                }),
                i
              );
            }(e, t, n, r.dimCount)),
            w = 0;
          w < b;
          w++
        ) {
          const S = (n[w] = l({}, h(n[w]) ? n[w] : { name: n[w] }));
          const T = S.name;
          var P = (_[w] = new y());
          T != null && f.get(T) == null && ((P.name = P.displayName = T), f.set(T, w)), S.type != null && (P.type = S.type), S.displayName != null && (P.displayName = S.displayName);
        }
        let M = r.encodeDef;
        !M && r.encodeDefaulter && (M = r.encodeDefaulter(e, b)),
          (M = i(M)).each((t, e) => {
            if ((t = c(t).slice()).length === 1 && !o(t[0]) && t[0] < 0) M.set(e, !1);
            else {
              const r = M.set(e, []);
              a(t, (t, n) => {
                o(t) && (t = f.get(t)), t != null && t < b && ((r[n] = t), k(_[t], e, n));
              });
            }
          });
        let O = 0;
        function k(t, e, r) {
          g.get(e) != null ? (t.otherDims[e] = r) : ((t.coordDim = e), (t.coordDimIndex = r), x.set(e, !0));
        }
        a(t, (t, e) => {
          let r;
          let n;
          let i;
          if (o(t)) (r = t), (t = {});
          else {
            r = t.name;
            const l = t.ordinalMeta;
            (t.ordinalMeta = null), ((t = u(t)).ordinalMeta = l), (n = t.dimsDef), (i = t.otherDims), (t.name = t.coordDim = t.coordDimIndex = t.dimsDef = t.otherDims = null);
          }
          if (!1 !== (f = M.get(r))) {
            var f;
            if (!(f = c(f)).length) {
              for (let d = 0; d < ((n && n.length) || 1); d++) {
                for (; O < _.length && _[O].coordDim != null;) O++;
                O < _.length && f.push(O++);
              }
            }
            a(f, (e, a) => {
              const o = _[e];
              if ((k(s(o, t), r, a), o.name == null && n)) {
                let l = n[a];
                !h(l) && (l = { name: l }), (o.name = o.displayName = l.name), (o.defaultTooltip = l.defaultTooltip);
              }
              i && s(o.otherDims, i);
            });
          }
        });
        const C = r.generateCoord;
        let A = r.generateCoordCount;
        const D = A != null;
        A = C ? A || 1 : 0;
        for (let I = C || "value", L = 0; L < b; L++) {
          (P = _[L] = _[L] || new y()).coordDim == null && ((P.coordDim = m(I, x, D)), (P.coordDimIndex = 0), (!C || A <= 0) && (P.isExtraCoord = !0), A--),
            P.name == null && (P.name = m(P.coordDim, f)),
            P.type != null || (d(e, L, P.name) !== p.Must && (!P.isExtraCoord || (P.otherDims.itemName == null && P.otherDims.seriesName == null))) || (P.type = "ordinal");
        }
        return _;
      };
      t.exports = x;
    },
    function(t, e, r) {
      r(9).__DEV__;
      const n = r(14);
      const i = n.makeInner;
      const a = n.getDataItemValue;
      const o = r(0);
      const s = o.createHashMap;
      const l = o.each;
      const h = o.map;
      const u = o.isArray;
      const c = o.isString;
      const f = o.isObject;
      const d = o.isTypedArray;
      const p = o.isArrayLike;
      const v = o.extend;
      const g = (o.assert, r(16));
      const y = r(17);
      const m = y.SOURCE_FORMAT_ORIGINAL;
      const x = y.SOURCE_FORMAT_ARRAY_ROWS;
      const _ = y.SOURCE_FORMAT_OBJECT_ROWS;
      const b = y.SOURCE_FORMAT_KEYED_COLUMNS;
      const w = y.SOURCE_FORMAT_UNKNOWN;
      const S = y.SOURCE_FORMAT_TYPED_ARRAY;
      const T = y.SERIES_LAYOUT_BY_ROW;
      const P = { Must: 1, Might: 2, Not: 3 };
      const M = i();
      function O(t) {
        if (t) {
          const e = s();
          return h(t, (t, r) => {
            if ((t = v({}, f(t) ? t : { name: t })).name == null) return t;
            (t.name += ""), t.displayName == null && (t.displayName = t.name);
            const n = e.get(t.name);
            return n ? (t.name += `-${n.count++}`) : e.set(t.name, { count: 1 }), t;
          });
        }
      }
      function k(t, e, r, n) {
        if ((n == null && (n = 1 / 0), e === T)) for (var i = 0; i < r.length && i < n; i++) t(r[i] ? r[i][0] : null, i);
        else {
          const a = r[0] || [];
          for (i = 0; i < a.length && i < n; i++) t(a[i], i);
        }
      }
      function C(t) {
        const e = t.option;
        if (!e.data) return t.ecModel.getComponent("dataset", e.datasetIndex || 0);
      }
      function A(t, e, r, n, i, o) {
        let s;
        let l;
        let h;
        if (d(t)) return P.Not;
        if (n) {
          const p = n[o];
          f(p) ? ((l = p.name), (h = p.type)) : c(p) && (l = p);
        }
        if (h != null) return h === "ordinal" ? P.Must : P.Not;
        if (e === x) {
          if (r === T) {
            for (var v = t[o], g = 0; g < (v || []).length && g < 5; g++) if ((s = M(v[i + g])) != null) return s;
          } else {
            for (g = 0; g < t.length && g < 5; g++) {
              const y = t[i + g];
              if (y && (s = M(y[o])) != null) return s;
            }
          }
        } else if (e === _) {
          if (!l) return P.Not;
          for (g = 0; g < t.length && g < 5; g++) {
            if ((w = t[g]) && (s = M(w[l])) != null) return s;
          }
        } else if (e === b) {
          if (!l) return P.Not;
          if (!(v = t[l]) || d(v)) return P.Not;
          for (g = 0; g < v.length && g < 5; g++) if ((s = M(v[g])) != null) return s;
        } else if (e === m) {
          for (g = 0; g < t.length && g < 5; g++) {
            var w = t[g];
            const S = a(w);
            if (!u(S)) return P.Not;
            if ((s = M(S[o])) != null) return s;
          }
        }
        function M(t) {
          const e = c(t);
          return t != null && isFinite(t) && t !== "" ? (e ? P.Might : P.Not) : e && t !== "-" ? P.Must : void 0;
        }
        return P.Not;
      }
      (e.BE_ORDINAL = P),
        (e.detectSourceFormat = function(t) {
          const e = t.option.source;
          let r = w;
          if (d(e)) r = S;
          else if (u(e)) {
            e.length === 0 && (r = x);
            for (let n = 0, i = e.length; n < i; n++) {
              const a = e[n];
              if (a != null) {
                if (u(a)) {
                  r = x;
                  break;
                }
                if (f(a)) {
                  r = _;
                  break;
                }
              }
            }
          } else if (f(e)) {
            for (const o in e) {
              if (e.hasOwnProperty(o) && p(e[o])) {
                r = b;
                break;
              }
            }
          } else if (e != null) throw new Error("Invalid data");
          M(t).sourceFormat = r;
        }),
        (e.getSource = function(t) {
          return M(t).source;
        }),
        (e.resetSourceDefaulter = function(t) {
          M(t).datasetMap = s();
        }),
        (e.prepareSource = function(t) {
          const e = t.option;
          let r = e.data;
          let n = d(r) ? S : m;
          let i = !1;
          let o = e.seriesLayoutBy;
          let s = e.sourceHeader;
          let h = e.dimensions;
          const f = C(t);
          if (f) {
            const p = f.option;
            (r = p.source), (n = M(f).sourceFormat), (i = !0), (o = o || p.seriesLayoutBy), s == null && (s = p.sourceHeader), (h = h || p.dimensions);
          }
          const v = (function(t, e, r, n, i) {
            if (!t) return { dimensionsDefine: O(i) };
            let o;
            let s;
            if (e === x) {
              n === "auto" || n == null
                ? k(
                    (t) => {
                      t != null && t !== "-" && (c(t) ? s == null && (s = 1) : (s = 0));
                    },
                    r,
                    t,
                    10
                  )
                : (s = n ? 1 : 0),
                i
                  || s !== 1
                  || ((i = []),
                  k(
                    (t, e) => {
                      i[e] = t != null ? t : "";
                    },
                    r,
                    t
                  )),
                (o = i ? i.length : r === T ? t.length : t[0] ? t[0].length : null);
            } else if (e === _) {
              i
                || (i = (function(t) {
                  let e;
                  let r = 0;
                  for (; r < t.length && !(e = t[r++]););
                  if (e) {
                    const n = [];
                    return (
                      l(e, (t, e) => {
                        n.push(e);
                      }),
                      n
                    );
                  }
                }(t)));
            } else if (e === b) {
              i
                || ((i = []),
                l(t, (t, e) => {
                  i.push(e);
                }));
            } else if (e === m) {
              const h = a(t[0]);
              o = (u(h) && h.length) || 1;
            }
            return { startIndex: s, dimensionsDefine: O(i), dimensionsDetectCount: o };
          }(r, n, o, s, h));
          M(t).source = new g({
            data: r,
            fromDataset: i,
            seriesLayoutBy: o,
            sourceFormat: n,
            dimensionsDefine: v.dimensionsDefine,
            startIndex: v.startIndex,
            dimensionsDetectCount: v.dimensionsDetectCount,
            encodeDefine: e.encode
          });
        }),
        (e.makeSeriesEncodeForAxisCoordSys = function(t, e, r) {
          const n = {};
          const i = C(e);
          if (!i || !t) return n;
          let a;
          let o;
          const s = [];
          const h = [];
          const u = e.ecModel;
          const c = M(u).datasetMap;
          const d = `${i.uid}_${r.seriesLayoutBy}`;
          (t = t.slice()),
            l(t, (e, r) => {
              !f(e) && (t[r] = { name: e }), e.type === "ordinal" && a == null && ((a = r), (o = g(t[r]))), (n[e.name] = []);
            });
          const p = c.get(d) || c.set(d, { categoryWayDim: o, valueWayDim: 0 });
          function v(t, e, r) {
            for (let n = 0; n < r; n++) t.push(e + n);
          }
          function g(t) {
            const e = t.dimsDef;
            return e ? e.length : 1;
          }
          return (
            l(t, (t, e) => {
              const r = t.name;
              const i = g(t);
              if (a == null) {
                var o = p.valueWayDim;
                v(n[r], o, i), v(h, o, i), (p.valueWayDim += i);
              } else if (a === e) v(n[r], 0, i), v(s, 0, i);
              else {
                o = p.categoryWayDim;
                v(n[r], o, i), v(h, o, i), (p.categoryWayDim += i);
              }
            }),
            s.length && (n.itemName = s),
            h.length && (n.seriesName = h),
            n
          );
        }),
        (e.makeSeriesEncodeForNameBased = function(t, e, r) {
          const n = {};
          if (!C(t)) return n;
          let i;
          const a = e.sourceFormat;
          const o = e.dimensionsDefine;
          (a !== _ && a !== b)
            || l(o, (t, e) => {
              (f(t) ? t.name : t) === "name" && (i = e);
            });
          const s = (function() {
            for (var t = {}, n = {}, s = [], l = 0, h = Math.min(5, r); l < h; l++) {
              const u = A(e.data, a, e.seriesLayoutBy, o, e.startIndex, l);
              s.push(u);
              const c = u === P.Not;
              if ((c && t.v == null && l !== i && (t.v = l), (t.n == null || t.n === t.v || (!c && s[t.n] === P.Not)) && (t.n = l), f(t) && s[t.n] !== P.Not)) return t;
              c || (u === P.Might && n.v == null && l !== i && (n.v = l), (n.n != null && n.n !== n.v) || (n.n = l));
            }
            function f(t) {
              return t.v != null && t.n != null;
            }
            return f(t) ? t : f(n) ? n : null;
          }());
          if (s) {
            n.value = s.v;
            const h = i != null ? i : s.n;
            (n.itemName = [h]), (n.seriesName = [h]);
          }
          return n;
        }),
        (e.guessOrdinal = function(t, e) {
          return A(t.data, t.sourceFormat, t.seriesLayoutBy, t.dimensionsDefine, t.startIndex, e);
        });
    },
    function(t, e) {
      let r;
      r = (function() {
        return this;
      }());
      try {
        r = r || new Function("return this")();
      } catch (t) {
        typeof window === "object" && (r = window);
      }
      t.exports = r;
    },
    function(t, e, r) {
      r(9).__DEV__;
      const n = r(0);
      const i = "___EC__COMPONENT__CONTAINER___";
      function a(t) {
        const e = { main: "", sub: "" };
        return t && ((t = t.split(".")), (e.main = t[0] || ""), (e.sub = t[1] || "")), e;
      }
      let o = 0;
      function s(t, e) {
        const r = n.slice(arguments, 2);
        return this.superClass.prototype[e].apply(t, r);
      }
      function l(t, e, r) {
        return this.superClass.prototype[e].apply(t, r);
      }
      (e.parseClassType = a),
        (e.enableClassExtend = function(t, e) {
          (t.$constructor = t),
            (t.extend = function(t) {
              const e = this;
              const r = function() {
                t.$constructor ? t.$constructor.apply(this, arguments) : e.apply(this, arguments);
              };
              return n.extend(r.prototype, t), (r.extend = this.extend), (r.superCall = s), (r.superApply = l), n.inherits(r, this), (r.superClass = e), r;
            });
        }),
        (e.enableClassCheck = function(t) {
          const e = ["__\0is_clz", o++, Math.random().toFixed(3)].join("_");
          (t.prototype[e] = !0),
            (t.isInstance = function(t) {
              return !(!t || !t[e]);
            });
        }),
        (e.enableClassManagement = function(t, e) {
          e = e || {};
          const r = {};
          if (
            ((t.registerClass = function(t, e) {
              if (e) {
                if (
                  ((function(t) {
                    n.assert(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)?$/.test(t), `componentType "${t}" illegal`);
                  }(e)),
                  (e = a(e)).sub)
                ) {
                  if (e.sub !== i) {
                    (function(t) {
                      let e = r[t.main];
                      (e && e[i]) || ((e = r[t.main] = {})[i] = !0);
                      return e;
                    }(e))[e.sub] = t;
                  }
                } else r[e.main] = t;
              }
              return t;
            }),
            (t.getClass = function(t, e, n) {
              let a = r[t];
              if ((a && a[i] && (a = e ? a[e] : null), n && !a)) throw new Error(e ? `Component ${t}.${e || ""} not exists. Load it first.` : `${t}.type should be specified.`);
              return a;
            }),
            (t.getClassesByMainType = function(t) {
              t = a(t);
              const e = [];
              const o = r[t.main];
              return (
                o && o[i]
                  ? n.each(o, (t, r) => {
                      r !== i && e.push(t);
                    })
                  : e.push(o),
                e
              );
            }),
            (t.hasClass = function(t) {
              return (t = a(t)), !!r[t.main];
            }),
            (t.getAllClassMainTypes = function() {
              const t = [];
              return (
                n.each(r, (e, r) => {
                  t.push(r);
                }),
                t
              );
            }),
            (t.hasSubTypes = function(t) {
              t = a(t);
              const e = r[t.main];
              return e && e[i];
            }),
            (t.parseClassType = a),
            e.registerWhenExtend)
          ) {
            const o = t.extend;
            o
              && (t.extend = function(e) {
                const r = o.call(this, e);
                return t.registerClass(r, e.type);
              });
          }
          return t;
        }),
        (e.setReadOnly = function(t, e) {});
    },
    function(t, e, r) {
      const n = r(0);
      const i = n.each;
      const a = n.createHashMap;
      const o = (n.assert, r(9).__DEV__, a(["tooltip", "label", "itemName", "itemId", "seriesName"]));
      function s(t, e) {
        return t.hasOwnProperty(e) || (t[e] = []), t[e];
      }
      (e.OTHER_DIMENSIONS = o),
        (e.summarizeDimensions = function(t) {
          const e = {};
          const r = (e.encode = {});
          const n = a();
          let l = [];
          let h = [];
          const u = (e.userOutput = { dimensionNames: t.dimensions.slice(), encode: {} });
          i(t.dimensions, (e) => {
            let i;
            const a = t.getDimensionInfo(e);
            const c = a.coordDim;
            if (c) {
              const f = a.coordDimIndex;
              (s(r, c)[f] = e), a.isExtraCoord || (n.set(c, 1), (i = a.type) !== "ordinal" && i !== "time" && (l[0] = e), (s(u.encode, c)[f] = a.index)), a.defaultTooltip && h.push(e);
            }
            o.each((t, e) => {
              const n = s(r, e);
              const i = a.otherDims[e];
              i != null && !1 !== i && (n[i] = a.name);
            });
          });
          let c = [];
          const f = {};
          n.each((t, e) => {
            const n = r[e];
            (f[e] = n[0]), (c = c.concat(n));
          }),
            (e.dataDimsOnCoord = c),
            (e.encodeFirstDimNotExtra = f);
          const d = r.label;
          d && d.length && (l = d.slice());
          const p = r.tooltip;
          return p && p.length ? (h = p.slice()) : h.length || (h = l.slice()), (r.defaultedLabel = l), (r.defaultedTooltip = h), e;
        }),
        (e.getDimensionTypeByAxis = function(t) {
          return t === "category" ? "ordinal" : t === "time" ? "time" : "float";
        });
    },
    function(t, e, r) {
      const n = r(0);
      const i = function(t) {
        t != null && n.extend(this, t), (this.otherDims = {});
      };
      t.exports = i;
    },
    function(t, e, r) {
      const n = r(5);
      const i = n.number;
      const a = r(39);
      const o = i.parsePercent;
      const s = r(79);
      n.extendChartView({
        type: "liquidFill",
        render(t, e, r) {
          const i = this.group;
          i.removeAll();
          const l = t.getData();
          const h = l.getItemModel(0);
          const u = h.get("center");
          let c = h.get("radius");
          const f = r.getWidth();
          const d = r.getHeight();
          const p = Math.min(f, d);
          let v = 0;
          let g = 0;
          const y = t.get("outline.show");
          y && ((v = t.get("outline.borderDistance")), (g = o(t.get("outline.itemStyle.borderWidth"), p)));
          let m;
          let x;
          let _;
          const b = o(u[0], f);
          const w = o(u[1], d);
          let S = !1;
          const T = t.get("shape");
          (T === "container"
            ? ((S = !0), (x = [(m = [f / 2, d / 2])[0] - g / 2, m[1] - g / 2]), (_ = [o(v, f), o(v, d)]), (c = [Math.max(x[0] - _[0], 0), Math.max(x[1] - _[1], 0)]))
            : ((x = (m = o(c, p) / 2) - g / 2), (_ = o(v, p)), (c = Math.max(x - _, 0))),
          y) && ((D().style.lineWidth = g), i.add(D()));
          const P = S ? 0 : b - c;
          const M = S ? 0 : w - c;
          let O = null;
          i.add(
            (function() {
              const e = A(c);
              e.setStyle(t.getModel("backgroundStyle").getItemStyle()), (e.style.fill = null), (e.z2 = 5);
              const r = A(c);
              r.setStyle(t.getModel("backgroundStyle").getItemStyle()), (r.style.stroke = null);
              const i = new n.graphic.Group();
              return i.add(e), i.add(r), i;
            })()
          );
          const k = this._data;
          const C = [];
          function A(t, e) {
            if (T) {
              if (T.indexOf("path://") === 0) {
                let r = n.graphic.makePath(T.slice(7), {});
                const i = r.getBoundingRect();
                let o = i.width;
                let s = i.height;
                o > s ? ((s *= (2 * t) / o), (o = 2 * t)) : ((o *= (2 * t) / s), (s = 2 * t));
                const l = e ? 0 : b - o / 2;
                const h = e ? 0 : w - s / 2;
                return (r = n.graphic.makePath(T.slice(7), {}, new n.graphic.BoundingRect(l, h, o, s))), e && (r.position = [-o / 2, -s / 2]), r;
              }
              if (S) {
                var u = e ? -t[0] : b - t[0];
                var c = e ? -t[1] : w - t[1];
                return a.createSymbol("rect", u, c, 2 * t[0], 2 * t[1]);
              }
              (u = e ? -t : b - t), (c = e ? -t : w - t);
              return T === "pin" ? (c += t) : T === "arrow" && (c -= t), a.createSymbol(T, u, c, 2 * t, 2 * t);
            }
            return new n.graphic.Circle({ shape: { cx: e ? 0 : b, cy: e ? 0 : w, r: t } });
          }
          function D() {
            const e = A(m);
            return (e.style.fill = null), e.setStyle(t.getModel("outline.itemStyle").getItemStyle()), e;
          }
          function I(e, r, i) {
            const a = S ? c[0] : c;
            const h = S ? d / 2 : c;
            const u = l.getItemModel(e);
            const f = u.getModel("itemStyle");
            let p = u.get("phase");
            const v = o(u.get("amplitude"), 2 * h);
            const g = o(u.get("waveLength"), 2 * a);
            const y = h - l.get("value", e) * h * 2;
            p = i ? i.shape.phase : p === "auto" ? (e * Math.PI) / 4 : p;
            const m = f.getItemStyle();
            if (!m.fill) {
              const x = t.get("color");
              const _ = e % x.length;
              m.fill = x[_];
            }
            const T = new s({
              shape: {
                waveLength: g,
                radius: a,
                radiusY: h,
                cx: 2 * a,
                cy: 0,
                waterLevel: y,
                amplitude: v,
                phase: p,
                inverse: r
              },
              style: m,
              position: [b, w]
            });
            T.shape._waterLevel = y;
            const P = u.getModel("emphasis.itemStyle").getItemStyle();
            (P.lineWidth = 0), n.graphic.setHoverStyle(T, P);
            const M = A(c, !0);
            return M.setStyle({ fill: "white" }), T.setClipPath(M), T;
          }
          function L(t, e, r) {
            const n = l.getItemModel(t);
            const i = n.get("period");
            const a = n.get("direction");
            const o = l.get("value", t);
            let s = n.get("phase");
            s = r ? r.shape.phase : s === "auto" ? (t * Math.PI) / 4 : s;
            let h = 0;
            h = i === "auto"
                ? (function(e) {
                    const r = l.count();
                    return r === 0 ? e : e * (0.2 + ((r - t) / r) * 0.8);
                  }(5e3))
                : typeof i === "function"
                ? i(o, t)
                : i;
            let u = 0;
            a === "right" || a == null ? (u = Math.PI) : a === "left" ? (u = -Math.PI) : a === "none" ? (u = 0) : console.error("Illegal direction value for liquid fill."),
              a !== "none"
                && n.get("waveAnimation")
                && e
                  .animate("shape", !0)
                  .when(0, { phase: s })
                  .when(h / 2, { phase: u + s })
                  .when(h, { phase: 2 * u + s })
                  .during(() => {
                    O && O.dirty(!0);
                  })
                  .start();
          }
          l
            .diff(k)
            .add((e) => {
              const r = I(e, !1);
              const a = r.shape.waterLevel;
              (r.shape.waterLevel = S ? d / 2 : c), n.graphic.initProps(r, { shape: { waterLevel: a } }, t), (r.z2 = 2), L(e, r, null), i.add(r), l.setItemGraphicEl(e, r), C.push(r);
            })
            .update((e, r) => {
              for (var a = k.getItemGraphicEl(r), o = I(e, !1, a), s = {}, h = ["amplitude", "cx", "cy", "phase", "radius", "radiusY", "waterLevel", "waveLength"], u = 0; u < h.length; ++u) {
                var c = h[u];
                o.shape.hasOwnProperty(c) && (s[c] = o.shape[c]);
              }
              const f = {};
              const p = ["fill", "opacity", "shadowBlur", "shadowColor"];
              for (u = 0; u < p.length; ++u) {
                c = p[u];
                o.style.hasOwnProperty(c) && (f[c] = o.style[c]);
              }
              S && (s.radiusY = d / 2),
                n.graphic.updateProps(a, { shape: s }, t),
                a.useStyle(f),
                (a.position = o.position),
                a.setClipPath(o.clipPath),
                (a.shape.inverse = o.inverse),
                L(e, a, a),
                i.add(a),
                l.setItemGraphicEl(e, a),
                C.push(a);
            })
            .remove((t) => {
              const e = k.getItemGraphicEl(t);
              i.remove(e);
            })
            .execute(),
            h.get("label.show")
              && i.add(
                (function(e) {
                  const r = h.getModel("label");
                  const i = {
                    z2: 10,
                    shape: {
                      x: P,
                      y: M,
                      width: 2 * (S ? c[0] : c),
                      height: 2 * (S ? c[1] : c)
                    },
                    style: {
                      fill: "transparent",
                      text: ((s = t.getFormattedLabel(0, "normal")), (u = 100 * l.get("value", 0)), (f = l.getName(0) || t.name), isNaN(u) || (f = `${u.toFixed(0)}%`), s == null ? f : s),
                      textAlign: r.get("align"),
                      textVerticalAlign: r.get("baseline")
                    },
                    silent: !0
                  };
                  const a = new n.graphic.Rect(i);
                  const o = r.get("color");
                  let s;
                  let u;
                  let f;
                  n.graphic.setText(a.style, r, o);
                  const d = new n.graphic.Rect(i);
                  const p = r.get("insideColor");
                  n.graphic.setText(d.style, r, p), (d.style.textFill = p);
                  const v = new n.graphic.Group();
                  v.add(a), v.add(d);
                  const g = A(c, !0);
                  return (O = new n.graphic.CompoundPath({ shape: { paths: e }, position: [b, w] })).setClipPath(g), d.setClipPath(O), v;
                })(C)
              ),
            (this._data = l);
        },
        dispose() {}
      });
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(40);
      const a = r(3);
      const o = r(11).calculateTextPosition;
      const s = i.extendShape({
        type: "triangle",
        shape: {
          cx: 0,
          cy: 0,
          width: 0,
          height: 0
        },
        buildPath(t, e) {
          const r = e.cx;
          const n = e.cy;
          const i = e.width / 2;
          const a = e.height / 2;
          t.moveTo(r, n - a), t.lineTo(r + i, n + a), t.lineTo(r - i, n + a), t.closePath();
        }
      });
      const l = i.extendShape({
        type: "diamond",
        shape: {
          cx: 0,
          cy: 0,
          width: 0,
          height: 0
        },
        buildPath(t, e) {
          const r = e.cx;
          const n = e.cy;
          const i = e.width / 2;
          const a = e.height / 2;
          t.moveTo(r, n - a), t.lineTo(r + i, n), t.lineTo(r, n + a), t.lineTo(r - i, n), t.closePath();
        }
      });
      const h = i.extendShape({
        type: "pin",
        shape: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        buildPath(t, e) {
          const r = e.x;
          const n = e.y;
          const i = (e.width / 5) * 3;
          const a = Math.max(i, e.height);
          const o = i / 2;
          const s = (o * o) / (a - o);
          const l = n - a + o + s;
          const h = Math.asin(s / o);
          const u = Math.cos(h) * o;
          const c = Math.sin(h);
          const f = Math.cos(h);
          const d = 0.6 * o;
          const p = 0.7 * o;
          t.moveTo(r - u, l + s),
            t.arc(r, l, o, Math.PI - h, 2 * Math.PI + h),
            t.bezierCurveTo(r + u - c * d, l + s + f * d, r, n - p, r, n),
            t.bezierCurveTo(r, n - p, r - u + c * d, l + s + f * d, r - u, l + s),
            t.closePath();
        }
      });
      const u = i.extendShape({
        type: "arrow",
        shape: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        buildPath(t, e) {
          const r = e.height;
          const n = e.width;
          const i = e.x;
          const a = e.y;
          const o = (n / 3) * 2;
          t.moveTo(i, a), t.lineTo(i + o, a + r), t.lineTo(i, a + (r / 4) * 3), t.lineTo(i - o, a + r), t.lineTo(i, a), t.closePath();
        }
      });
      const c = {
        line: i.Line,
        rect: i.Rect,
        roundRect: i.Rect,
        square: i.Rect,
        circle: i.Circle,
        diamond: l,
        pin: h,
        arrow: u,
        triangle: s
      };
      const f = {
        line(t, e, r, n, i) {
          (i.x1 = t), (i.y1 = e + n / 2), (i.x2 = t + r), (i.y2 = e + n / 2);
        },
        rect(t, e, r, n, i) {
          (i.x = t), (i.y = e), (i.width = r), (i.height = n);
        },
        roundRect(t, e, r, n, i) {
          (i.x = t), (i.y = e), (i.width = r), (i.height = n), (i.r = Math.min(r, n) / 4);
        },
        square(t, e, r, n, i) {
          const a = Math.min(r, n);
          (i.x = t), (i.y = e), (i.width = a), (i.height = a);
        },
        circle(t, e, r, n, i) {
          (i.cx = t + r / 2), (i.cy = e + n / 2), (i.r = Math.min(r, n) / 2);
        },
        diamond(t, e, r, n, i) {
          (i.cx = t + r / 2), (i.cy = e + n / 2), (i.width = r), (i.height = n);
        },
        pin(t, e, r, n, i) {
          (i.x = t + r / 2), (i.y = e + n / 2), (i.width = r), (i.height = n);
        },
        arrow(t, e, r, n, i) {
          (i.x = t + r / 2), (i.y = e + n / 2), (i.width = r), (i.height = n);
        },
        triangle(t, e, r, n, i) {
          (i.cx = t + r / 2), (i.cy = e + n / 2), (i.width = r), (i.height = n);
        }
      };
      const d = {};
      n.each(c, (t, e) => {
        d[e] = new t();
      });
      const p = i.extendShape({
        type: "symbol",
        shape: {
          symbolType: "",
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        calculateTextPosition(t, e, r) {
          const n = o(t, e, r);
          const i = this.shape;
          return i && i.symbolType === "pin" && e.textPosition === "inside" && (n.y = r.y + 0.4 * r.height), n;
        },
        buildPath(t, e, r) {
          let n = e.symbolType;
          if (n !== "none") {
            let i = d[n];
            i || (i = d[(n = "rect")]), f[n](e.x, e.y, e.width, e.height, i.shape), i.buildPath(t, i.shape, r);
          }
        }
      });
      function v(t, e) {
        if (this.type !== "image") {
          const r = this.style;
          const n = this.shape;
          n && n.symbolType === "line" ? (r.stroke = t) : this.__isEmptyBrush ? ((r.stroke = t), (r.fill = e || "#fff")) : (r.fill && (r.fill = t), r.stroke && (r.stroke = t)), this.dirty(!1);
        }
      }
      e.createSymbol = function(t, e, r, n, o, s, l) {
        let h;
        const u = t.indexOf("empty") === 0;
        return (
          u && (t = t.substr(5, 1).toLowerCase() + t.substr(6)),
          ((h = t.indexOf("image://") === 0
              ? i.makeImage(t.slice(8), new a(e, r, n, o), l ? "center" : "cover")
              : t.indexOf("path://") === 0
              ? i.makePath(t.slice(7), {}, new a(e, r, n, o), l ? "center" : "cover")
              : new p({
                  shape: {
                    symbolType: t,
                    x: e,
                    y: r,
                    width: n,
                    height: o
                  }
                })).__isEmptyBrush = u),
          (h.setColor = v),
          h.setColor(s),
          h
        );
      };
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(41);
      const a = r(21);
      const o = r(10);
      const s = r(2);
      const l = r(1);
      const h = r(20);
      const u = r(60);
      e.Image = u;
      const c = r(61);
      e.Group = c;
      const f = r(62);
      e.Text = f;
      const d = r(63);
      e.Circle = d;
      const p = r(64);
      e.Sector = p;
      const v = r(66);
      e.Ring = v;
      const g = r(67);
      e.Polygon = g;
      const y = r(70);
      e.Polyline = y;
      const m = r(71);
      e.Rect = m;
      const x = r(72);
      e.Line = x;
      const _ = r(73);
      e.BezierCurve = _;
      const b = r(74);
      e.Arc = b;
      const w = r(75);
      e.CompoundPath = w;
      const S = r(76);
      e.LinearGradient = S;
      const T = r(77);
      e.RadialGradient = T;
      const P = r(3);
      e.BoundingRect = P;
      const M = r(78);
      e.IncrementalDisplayable = M;
      const O = r(13);
      const k = Math.max;
      const C = Math.min;
      const A = {};
      let D = 1;
      const I = {};
      const L = {};
      function R(t, e) {
        L[t] = e;
      }
      function B(t, e, r, n) {
        const a = i.createFromString(t, e);
        return r && (n === "center" && (r = F(r, a.getBoundingRect())), N(a, r)), a;
      }
      function F(t, e) {
        let r;
        const n = e.width / e.height;
        let i = t.height * n;
        return (
          (r = i <= t.width ? t.height : (i = t.width) / n),
          {
            x: t.x + t.width / 2 - i / 2,
            y: t.y + t.height / 2 - r / 2,
            width: i,
            height: r
          }
        );
      }
      const E = i.mergePath;
      function N(t, e) {
        if (t.applyTransform) {
          const r = t.getBoundingRect().calculateTransform(e);
          t.applyTransform(r);
        }
      }
      const z = O.subPixelOptimize;
      function W(t) {
        return t != null && t !== "none";
      }
      const q = n.createHashMap();
      let H = 0;
      function j(t) {
        const e = t.__hoverStl;
        if (e && !t.__highlighted) {
          const r = t.__zr;
          const n = t.useHoverLayer && r && r.painter.type === "canvas";
          if (((t.__highlighted = n ? "layer" : "plain"), !(t.isGroup || (!r && t.useHoverLayer)))) {
            let i = t;
            let a = t.style;
            n && (a = (i = r.addHover(t)).style),
              at(a),
              n
                || (function(t) {
                  if (t.__hoverStlDirty) {
                    t.__hoverStlDirty = !1;
                    const e = t.__hoverStl;
                    if (e) {
                      const r = (t.__cachedNormalStl = {});
                      t.__cachedNormalZ2 = t.z2;
                      const n = t.style;
                      for (const i in e) e[i] != null && (r[i] = n[i]);
                      (r.fill = n.fill), (r.stroke = n.stroke);
                    } else t.__cachedNormalStl = t.__cachedNormalZ2 = null;
                  }
                }(i)),
              a.extendFrom(e),
              Y(a, e, "fill"),
              Y(a, e, "stroke"),
              it(a),
              n || (t.dirty(!1), (t.z2 += 1));
          }
        }
      }
      function Y(t, e, r) {
        !W(e[r])
          && W(t[r])
          && (t[r] = (function(t) {
            if (typeof t !== "string") return t;
            let e = q.get(t);
            return e || ((e = a.lift(t, -0.1)), H < 1e4 && (q.set(t, e), H++)), e;
          }(t[r])));
      }
      function U(t) {
        const e = t.__highlighted;
        if (e && ((t.__highlighted = !1), !t.isGroup)) {
          if (e === "layer") t.__zr && t.__zr.removeHover(t);
          else {
            const r = t.style;
            const n = t.__cachedNormalStl;
            n && (at(r), t.setStyle(n), it(r));
            const i = t.__cachedNormalZ2;
            i != null && t.z2 - i == 1 && (t.z2 = i);
          }
        }
      }
      function V(t, e, r) {
        let n;
        let i = "normal";
        let a = "normal";
        t.__highlighted && ((i = "emphasis"), (n = !0)),
          e(t, r),
          t.__highlighted && ((a = "emphasis"), (n = !0)),
          t.isGroup
            && t.traverse((t) => {
              !t.isGroup && e(t, r);
            }),
          n && t.__highDownOnUpdate && t.__highDownOnUpdate(i, a);
      }
      function G(t, e) {
        (e = t.__hoverStl = !1 !== e && (t.hoverStyle || e || {})), (t.__hoverStlDirty = !0), t.__highlighted && ((t.__cachedNormalStl = null), U(t), j(t));
      }
      function X(t) {
        !K(this, t) && !this.__highByOuter && V(this, j);
      }
      function Z(t) {
        !K(this, t) && !this.__highByOuter && V(this, U);
      }
      function Q(t) {
        (this.__highByOuter |= 1 << (t || 0)), V(this, j);
      }
      function $(t) {
        !(this.__highByOuter &= ~(1 << (t || 0))) && V(this, U);
      }
      function K(t, e) {
        return t.__highDownSilentOnTouch && e.zrByTouch;
      }
      function J(t, e) {
        const r = !1 === e;
        if (((t.__highDownSilentOnTouch = t.highDownSilentOnTouch), (t.__highDownOnUpdate = t.highDownOnUpdate), !r || t.__highDownDispatcher)) {
          const n = r ? "off" : "on";
          t[n]("mouseover", X)[n]("mouseout", Z), t[n]("emphasis", Q)[n]("normal", $), (t.__highByOuter = t.__highByOuter || 0), (t.__highDownDispatcher = !r);
        }
      }
      function tt(t, e, r, i, a) {
        return et(t, e, i, a), r && n.extend(t, r), t;
      }
      function et(t, e, r, i) {
        if ((r = r || A).isRectText) {
          let a;
          r.getTextPosition ? (a = r.getTextPosition(e, i)) : (a = e.getShallow("position") || (i ? null : "inside")) === "outside" && (a = "top"),
            (t.textPosition = a),
            (t.textOffset = e.getShallow("offset"));
          let o = e.getShallow("rotate");
          o != null && (o *= Math.PI / 180), (t.textRotation = o), (t.textDistance = n.retrieve2(e.getShallow("distance"), i ? null : 5));
        }
        let s;
        const l = e.ecModel;
        const h = l && l.option.textStyle;
        const u = (function(t) {
          let e;
          for (; t && t !== t.ecModel;) {
            const r = (t.option || A).rich;
            if (r) for (const n in ((e = e || {}), r)) r.hasOwnProperty(n) && (e[n] = 1);
            t = t.parentModel;
          }
          return e;
        }(e));
        if (u) {
          for (const c in ((s = {}), u)) {
            if (u.hasOwnProperty(c)) {
              const f = e.getModel(["rich", c]);
              rt((s[c] = {}), f, h, r, i);
            }
          }
        }
        return (t.rich = s), rt(t, e, h, r, i, !0), r.forceRich && !r.textStyle && (r.textStyle = {}), t;
      }
      function rt(t, e, r, i, a, o) {
        (r = (!a && r) || A),
          (t.textFill = nt(e.getShallow("color"), i) || r.color),
          (t.textStroke = nt(e.getShallow("textBorderColor"), i) || r.textBorderColor),
          (t.textStrokeWidth = n.retrieve2(e.getShallow("textBorderWidth"), r.textBorderWidth)),
          a || (o && ((t.insideRollbackOpt = i), it(t)), t.textFill == null && (t.textFill = i.autoColor)),
          (t.fontStyle = e.getShallow("fontStyle") || r.fontStyle),
          (t.fontWeight = e.getShallow("fontWeight") || r.fontWeight),
          (t.fontSize = e.getShallow("fontSize") || r.fontSize),
          (t.fontFamily = e.getShallow("fontFamily") || r.fontFamily),
          (t.textAlign = e.getShallow("align")),
          (t.textVerticalAlign = e.getShallow("verticalAlign") || e.getShallow("baseline")),
          (t.textLineHeight = e.getShallow("lineHeight")),
          (t.textWidth = e.getShallow("width")),
          (t.textHeight = e.getShallow("height")),
          (t.textTag = e.getShallow("tag")),
          (o && i.disableBox)
            || ((t.textBackgroundColor = nt(e.getShallow("backgroundColor"), i)),
            (t.textPadding = e.getShallow("padding")),
            (t.textBorderColor = nt(e.getShallow("borderColor"), i)),
            (t.textBorderWidth = e.getShallow("borderWidth")),
            (t.textBorderRadius = e.getShallow("borderRadius")),
            (t.textBoxShadowColor = e.getShallow("shadowColor")),
            (t.textBoxShadowBlur = e.getShallow("shadowBlur")),
            (t.textBoxShadowOffsetX = e.getShallow("shadowOffsetX")),
            (t.textBoxShadowOffsetY = e.getShallow("shadowOffsetY"))),
          (t.textShadowColor = e.getShallow("textShadowColor") || r.textShadowColor),
          (t.textShadowBlur = e.getShallow("textShadowBlur") || r.textShadowBlur),
          (t.textShadowOffsetX = e.getShallow("textShadowOffsetX") || r.textShadowOffsetX),
          (t.textShadowOffsetY = e.getShallow("textShadowOffsetY") || r.textShadowOffsetY);
      }
      function nt(t, e) {
        return t !== "auto" ? t : e && e.autoColor ? e.autoColor : null;
      }
      function it(t) {
        let e;
        const r = t.textPosition;
        const n = t.insideRollbackOpt;
        if (n && t.textFill == null) {
          const i = n.autoColor;
          const a = n.isRectText;
          const o = n.useInsideStyle;
          const s = !1 !== o && (!0 === o || (a && r && typeof r === "string" && r.indexOf("inside") >= 0));
          const l = !s && i != null;
          (s || l) && (e = { textFill: t.textFill, textStroke: t.textStroke, textStrokeWidth: t.textStrokeWidth }),
            s && ((t.textFill = "#fff"), t.textStroke == null && ((t.textStroke = i), t.textStrokeWidth == null && (t.textStrokeWidth = 2))),
            l && (t.textFill = i);
        }
        t.insideRollback = e;
      }
      function at(t) {
        const e = t.insideRollback;
        e && ((t.textFill = e.textFill), (t.textStroke = e.textStroke), (t.textStrokeWidth = e.textStrokeWidth), (t.insideRollback = null));
      }
      function ot(t, e, r, n, i, a) {
        if ((typeof i === "function" && ((a = i), (i = null)), n && n.isAnimationEnabled())) {
          const o = t ? "Update" : "";
          let s = n.getShallow(`animationDuration${o}`);
          const l = n.getShallow(`animationEasing${o}`);
          let h = n.getShallow(`animationDelay${o}`);
          typeof h === "function" && (h = h(i, n.getAnimationDelayParams ? n.getAnimationDelayParams(e, i) : null)),
            typeof s === "function" && (s = s(i)),
            s > 0 ? e.animateTo(r, s, h || 0, l, a, !!a) : (e.stopAnimation(), e.attr(r), a && a());
        } else e.stopAnimation(), e.attr(r), a && a();
      }
      function st(t, e, r, n, i) {
        ot(!0, t, e, r, n, i);
      }
      function lt(t, e, r) {
        return e && !n.isArrayLike(e) && (e = h.getLocalTransform(e)), r && (e = o.invert([], e)), s.applyTransform([], t, e);
      }
      function ht(t, e, r, n, i, a, o, s) {
        let l;
        const h = r - t;
        const u = n - e;
        const c = o - i;
        const f = s - a;
        const d = ut(c, f, h, u);
        if ((l = d) <= 1e-6 && l >= -1e-6) return !1;
        const p = t - i;
        const v = e - a;
        const g = ut(p, v, h, u) / d;
        if (g < 0 || g > 1) return !1;
        const y = ut(p, v, c, f) / d;
        return !(y < 0 || y > 1);
      }
      function ut(t, e, r, n) {
        return t * n - r * e;
      }
      R("circle", d),
        R("sector", p),
        R("ring", v),
        R("polygon", g),
        R("polyline", y),
        R("rect", m),
        R("line", x),
        R("bezierCurve", _),
        R("arc", b),
        (e.Z2_EMPHASIS_LIFT = 1),
        (e.CACHED_LABEL_STYLE_PROPERTIES = { color: "textFill", textBorderColor: "textStroke", textBorderWidth: "textStrokeWidth" }),
        (e.extendShape = function(t) {
          return l.extend(t);
        }),
        (e.extendPath = function(t, e) {
          return i.extendFromString(t, e);
        }),
        (e.registerShape = R),
        (e.getShapeClass = function(t) {
          if (L.hasOwnProperty(t)) return L[t];
        }),
        (e.makePath = B),
        (e.makeImage = function(t, e, r) {
          var n = new u({
            style: {
              image: t,
              x: e.x,
              y: e.y,
              width: e.width,
              height: e.height
            },
            onload(t) {
              if (r === "center") {
                const i = { width: t.width, height: t.height };
                n.setStyle(F(e, i));
              }
            }
          });
          return n;
        }),
        (e.mergePath = E),
        (e.resizePath = N),
        (e.subPixelOptimizeLine = function(t) {
          return O.subPixelOptimizeLine(t.shape, t.shape, t.style), t;
        }),
        (e.subPixelOptimizeRect = function(t) {
          return O.subPixelOptimizeRect(t.shape, t.shape, t.style), t;
        }),
        (e.subPixelOptimize = z),
        (e.setElementHoverStyle = G),
        (e.setHoverStyle = function(t, e) {
          J(t, !0), V(t, G, e);
        }),
        (e.setAsHighDownDispatcher = J),
        (e.isHighDownDispatcher = function(t) {
          return !(!t || !t.__highDownDispatcher);
        }),
        (e.getHighlightDigit = function(t) {
          let e = I[t];
          return e == null && D <= 32 && (e = I[t] = D++), e;
        }),
        (e.setLabelStyle = function(t, e, r, i, a, o, s) {
          let l;
          const h = (a = a || A).labelFetcher;
          const u = a.labelDataIndex;
          const c = a.labelDimIndex;
          const f = a.labelProp;
          const d = r.getShallow("show");
          const p = i.getShallow("show");
          (d || p) && (h && (l = h.getFormattedLabel(u, "normal", null, c, f)), l == null && (l = n.isFunction(a.defaultText) ? a.defaultText(u, a) : a.defaultText));
          const v = d ? l : null;
          const g = p ? n.retrieve2(h ? h.getFormattedLabel(u, "emphasis", null, c, f) : null, l) : null;
          (v == null && g == null) || (tt(t, r, o, a), tt(e, i, s, a, !0)), (t.text = v), (e.text = g);
        }),
        (e.modifyLabelStyle = function(t, e, r) {
          let i = t.style;
          e && (at(i), t.setStyle(e), it(i)), (i = t.__hoverStl), r && i && (at(i), n.extend(i, r), it(i));
        }),
        (e.setTextStyle = tt),
        (e.setText = function(t, e, r) {
          let n;
          const i = { isRectText: !0 };
          !1 === r ? (n = !0) : (i.autoColor = r), et(t, e, i, n);
        }),
        (e.getFont = function(t, e) {
          const r = e && e.getModel("textStyle");
          return n.trim(
            [
              t.fontStyle || (r && r.getShallow("fontStyle")) || "",
              t.fontWeight || (r && r.getShallow("fontWeight")) || "",
              `${t.fontSize || (r && r.getShallow("fontSize")) || 12}px`,
              t.fontFamily || (r && r.getShallow("fontFamily")) || "sans-serif"
            ].join(" ")
          );
        }),
        (e.updateProps = st),
        (e.initProps = function(t, e, r, n, i) {
          ot(!1, t, e, r, n, i);
        }),
        (e.getTransform = function(t, e) {
          for (var r = o.identity([]); t && t !== e;) o.mul(r, t.getLocalTransform(), r), (t = t.parent);
          return r;
        }),
        (e.applyTransform = lt),
        (e.transformDirection = function(t, e, r) {
          const n = e[4] === 0 || e[5] === 0 || e[0] === 0 ? 1 : Math.abs((2 * e[4]) / e[0]);
          const i = e[4] === 0 || e[5] === 0 || e[2] === 0 ? 1 : Math.abs((2 * e[4]) / e[2]);
          let a = [t === "left" ? -n : t === "right" ? n : 0, t === "top" ? -i : t === "bottom" ? i : 0];
          return (a = lt(a, e, r)), Math.abs(a[0]) > Math.abs(a[1]) ? (a[0] > 0 ? "right" : "left") : a[1] > 0 ? "bottom" : "top";
        }),
        (e.groupTransition = function(t, e, r, i) {
          if (t && e) {
            let a;
            const o = ((a = {}),
              t.traverse((t) => {
                !t.isGroup && t.anid && (a[t.anid] = t);
              }),
              a);
            e.traverse((t) => {
              if (!t.isGroup && t.anid) {
                const e = o[t.anid];
                if (e) {
                  const n = l(t);
                  t.attr(l(e)), st(t, n, r, t.dataIndex);
                }
              }
            });
          }
          function l(t) {
            const e = { position: s.clone(t.position), rotation: t.rotation };
            return t.shape && (e.shape = n.extend({}, t.shape)), e;
          }
        }),
        (e.clipPointsByRect = function(t, e) {
          return n.map(t, (t) => {
            let r = t[0];
            (r = k(r, e.x)), (r = C(r, e.x + e.width));
            let n = t[1];
            return (n = k(n, e.y)), [r, (n = C(n, e.y + e.height))];
          });
        }),
        (e.clipRectByRect = function(t, e) {
          const r = k(t.x, e.x);
          const n = C(t.x + t.width, e.x + e.width);
          const i = k(t.y, e.y);
          const a = C(t.y + t.height, e.y + e.height);
          if (n >= r && a >= i) {
            return {
              x: r,
              y: i,
              width: n - r,
              height: a - i
            };
          }
        }),
        (e.createIcon = function(t, e, r) {
          const i = ((e = n.extend({ rectHover: !0 }, e)).style = { strokeNoScale: !0 });
          if (
            ((r = r || {
              x: -1,
              y: -1,
              width: 2,
              height: 2
            }),
            t)
          ) return t.indexOf("image://") === 0 ? ((i.image = t.slice(8)), n.defaults(i, r), new u(e)) : B(t.replace("path://", ""), e, r, "center");
        }),
        (e.linePolygonIntersect = function(t, e, r, n, i) {
          for (let a = 0, o = i[i.length - 1]; a < i.length; a++) {
            const s = i[a];
            if (ht(t, e, r, n, s[0], s[1], o[0], o[1])) return !0;
            o = s;
          }
        }),
        (e.lineLineIntersect = ht);
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(8);
      const a = r(59);
      const o = Math.sqrt;
      const s = Math.sin;
      const l = Math.cos;
      const h = Math.PI;
      const u = function(t) {
        return Math.sqrt(t[0] * t[0] + t[1] * t[1]);
      };
      const c = function(t, e) {
        return (t[0] * e[0] + t[1] * e[1]) / (u(t) * u(e));
      };
      const f = function(t, e) {
        return (t[0] * e[1] < t[1] * e[0] ? -1 : 1) * Math.acos(c(t, e));
      };
      function d(t, e, r, n, i, a, u, d, p, v, g) {
        const y = p * (h / 180);
        const m = (l(y) * (t - r)) / 2 + (s(y) * (e - n)) / 2;
        const x = (-1 * s(y) * (t - r)) / 2 + (l(y) * (e - n)) / 2;
        const _ = (m * m) / (u * u) + (x * x) / (d * d);
        _ > 1 && ((u *= o(_)), (d *= o(_)));
        const b = (i === a ? -1 : 1) * o((u * u * (d * d) - u * u * (x * x) - d * d * (m * m)) / (u * u * (x * x) + d * d * (m * m))) || 0;
        const w = (b * u * x) / d;
        const S = (b * -d * m) / u;
        const T = (t + r) / 2 + l(y) * w - s(y) * S;
        const P = (e + n) / 2 + s(y) * w + l(y) * S;
        const M = f([1, 0], [(m - w) / u, (x - S) / d]);
        const O = [(m - w) / u, (x - S) / d];
        const k = [(-1 * m - w) / u, (-1 * x - S) / d];
        let C = f(O, k);
        c(O, k) <= -1 && (C = h), c(O, k) >= 1 && (C = 0), a === 0 && C > 0 && (C -= 2 * h), a === 1 && C < 0 && (C += 2 * h), g.addData(v, T, P, u, d, M, C, y, a);
      }
      const p = /([mlvhzcqtsa])([^mlvhzcqtsa]*)/gi;
      const v = /-?([0-9]*\.)?[0-9]+([eE]-?[0-9]+)?/g;
      function g(t, e) {
        const r = (function(t) {
          if (!t) return new i();
          for (var e, r = 0, n = 0, a = r, o = n, s = new i(), l = i.CMD, h = t.match(p), u = 0; u < h.length; u++) {
            for (var c, f = h[u], g = f.charAt(0), y = f.match(v) || [], m = y.length, x = 0; x < m; x++) y[x] = parseFloat(y[x]);
            for (let _ = 0; _ < m;) {
              var b;
              var w;
              var S;
              var T;
              var P;
              var M;
              var O;
              let k = r;
              let C = n;
              switch (g) {
                case "l":
                  (r += y[_++]), (n += y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "L":
                  (r = y[_++]), (n = y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "m":
                  (r += y[_++]), (n += y[_++]), (c = l.M), s.addData(c, r, n), (a = r), (o = n), (g = "l");
                  break;
                case "M":
                  (r = y[_++]), (n = y[_++]), (c = l.M), s.addData(c, r, n), (a = r), (o = n), (g = "L");
                  break;
                case "h":
                  (r += y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "H":
                  (r = y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "v":
                  (n += y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "V":
                  (n = y[_++]), (c = l.L), s.addData(c, r, n);
                  break;
                case "C":
                  (c = l.C), s.addData(c, y[_++], y[_++], y[_++], y[_++], y[_++], y[_++]), (r = y[_ - 2]), (n = y[_ - 1]);
                  break;
                case "c":
                  (c = l.C), s.addData(c, y[_++] + r, y[_++] + n, y[_++] + r, y[_++] + n, y[_++] + r, y[_++] + n), (r += y[_ - 2]), (n += y[_ - 1]);
                  break;
                case "S":
                  (b = r), (w = n);
                  var A = s.len();
                  var D = s.data;
                  e === l.C && ((b += r - D[A - 4]), (w += n - D[A - 3])), (c = l.C), (k = y[_++]), (C = y[_++]), (r = y[_++]), (n = y[_++]), s.addData(c, b, w, k, C, r, n);
                  break;
                case "s":
                  (b = r), (w = n);
                  (A = s.len()), (D = s.data);
                  e === l.C && ((b += r - D[A - 4]), (w += n - D[A - 3])), (c = l.C), (k = r + y[_++]), (C = n + y[_++]), (r += y[_++]), (n += y[_++]), s.addData(c, b, w, k, C, r, n);
                  break;
                case "Q":
                  (k = y[_++]), (C = y[_++]), (r = y[_++]), (n = y[_++]), (c = l.Q), s.addData(c, k, C, r, n);
                  break;
                case "q":
                  (k = y[_++] + r), (C = y[_++] + n), (r += y[_++]), (n += y[_++]), (c = l.Q), s.addData(c, k, C, r, n);
                  break;
                case "T":
                  (b = r), (w = n);
                  (A = s.len()), (D = s.data);
                  e === l.Q && ((b += r - D[A - 4]), (w += n - D[A - 3])), (r = y[_++]), (n = y[_++]), (c = l.Q), s.addData(c, b, w, r, n);
                  break;
                case "t":
                  (b = r), (w = n);
                  (A = s.len()), (D = s.data);
                  e === l.Q && ((b += r - D[A - 4]), (w += n - D[A - 3])), (r += y[_++]), (n += y[_++]), (c = l.Q), s.addData(c, b, w, r, n);
                  break;
                case "A":
                  (S = y[_++]), (T = y[_++]), (P = y[_++]), (M = y[_++]), (O = y[_++]), d((k = r), (C = n), (r = y[_++]), (n = y[_++]), M, O, S, T, P, (c = l.A), s);
                  break;
                case "a":
                  (S = y[_++]), (T = y[_++]), (P = y[_++]), (M = y[_++]), (O = y[_++]), d((k = r), (C = n), (r += y[_++]), (n += y[_++]), M, O, S, T, P, (c = l.A), s);
              }
            }
            (g !== "z" && g !== "Z") || ((c = l.Z), s.addData(c), (r = a), (n = o)), (e = c);
          }
          return s.toStatic(), s;
        }(t));
        return (
          ((e = e || {}).buildPath = function(t) {
            if (t.setData) {
              t.setData(r.data), (e = t.getContext()) && t.rebuildPath(e);
            } else {
              var e = t;
              r.rebuildPath(e);
            }
          }),
          (e.applyTransform = function(t) {
            a(r, t), this.dirty(!0);
          }),
          e
        );
      }
      (e.createFromString = function(t, e) {
        return new n(g(t, e));
      }),
        (e.extendFromString = function(t, e) {
          return n.extend(g(t, e));
        }),
        (e.mergePath = function(t, e) {
          for (var r = [], i = t.length, a = 0; a < i; a++) {
            const o = t[a];
            o.path || o.createPathProxy(), o.__dirtyPath && o.buildPath(o.path, o.shape, !0), r.push(o.path);
          }
          const s = new n(e);
          return (
            s.createPathProxy(),
            (s.buildPath = function(t) {
              t.appendPath(r);
              const e = t.getContext();
              e && t.rebuildPath(e);
            }),
            s
          );
        });
    },
    function(t, e, r) {
      const n = r(18);
      const i = r(7).ContextCachedBy;
      const a = [
        ["shadowBlur", 0],
        ["shadowOffsetX", 0],
        ["shadowOffsetY", 0],
        ["shadowColor", "#000"],
        ["lineCap", "butt"],
        ["lineJoin", "miter"],
        ["miterLimit", 10]
      ];
      const o = function(t) {
        this.extendFrom(t, !1);
      };
      function s(t, e, r) {
        let n = e.x == null ? 0 : e.x;
        let i = e.x2 == null ? 1 : e.x2;
        let a = e.y == null ? 0 : e.y;
        let o = e.y2 == null ? 0 : e.y2;
        return (
          e.global || ((n = n * r.width + r.x), (i = i * r.width + r.x), (a = a * r.height + r.y), (o = o * r.height + r.y)),
          (n = isNaN(n) ? 0 : n),
          (i = isNaN(i) ? 1 : i),
          (a = isNaN(a) ? 0 : a),
          (o = isNaN(o) ? 0 : o),
          t.createLinearGradient(n, a, i, o)
        );
      }
      function l(t, e, r) {
        const n = r.width;
        const i = r.height;
        const a = Math.min(n, i);
        let o = e.x == null ? 0.5 : e.x;
        let s = e.y == null ? 0.5 : e.y;
        let l = e.r == null ? 0.5 : e.r;
        return e.global || ((o = o * n + r.x), (s = s * i + r.y), (l *= a)), t.createRadialGradient(o, s, 0, o, s, l);
      }
      for (
        var h = (o.prototype = {
            constructor: o,
            fill: "#000",
            stroke: null,
            opacity: 1,
            fillOpacity: null,
            strokeOpacity: null,
            lineDash: null,
            lineDashOffset: 0,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            lineWidth: 1,
            strokeNoScale: !1,
            text: null,
            font: null,
            textFont: null,
            fontStyle: null,
            fontWeight: null,
            fontSize: null,
            fontFamily: null,
            textTag: null,
            textFill: "#000",
            textStroke: null,
            textWidth: null,
            textHeight: null,
            textStrokeWidth: 0,
            textLineHeight: null,
            textPosition: "inside",
            textRect: null,
            textOffset: null,
            textAlign: null,
            textVerticalAlign: null,
            textDistance: 5,
            textShadowColor: "transparent",
            textShadowBlur: 0,
            textShadowOffsetX: 0,
            textShadowOffsetY: 0,
            textBoxShadowColor: "transparent",
            textBoxShadowBlur: 0,
            textBoxShadowOffsetX: 0,
            textBoxShadowOffsetY: 0,
            transformText: !1,
            textRotation: 0,
            textOrigin: null,
            textBackgroundColor: null,
            textBorderColor: null,
            textBorderWidth: 0,
            textBorderRadius: 0,
            textPadding: null,
            rich: null,
            truncate: null,
            blend: null,
            bind(t, e, r) {
              const o = r && r.style;
              const s = !o || t.__attrCachedBy !== i.STYLE_BIND;
              t.__attrCachedBy = i.STYLE_BIND;
              for (let l = 0; l < a.length; l++) {
                const h = a[l];
                const u = h[0];
                (s || this[u] !== o[u]) && (t[u] = n(t, u, this[u] || h[1]));
              }
              if (
                ((s || this.fill !== o.fill) && (t.fillStyle = this.fill),
                (s || this.stroke !== o.stroke) && (t.strokeStyle = this.stroke),
                (s || this.opacity !== o.opacity) && (t.globalAlpha = this.opacity == null ? 1 : this.opacity),
                (s || this.blend !== o.blend) && (t.globalCompositeOperation = this.blend || "source-over"),
                this.hasStroke())
              ) {
                const c = this.lineWidth;
                t.lineWidth = c / (this.strokeNoScale && e && e.getLineScale ? e.getLineScale() : 1);
              }
            },
            hasFill() {
              const t = this.fill;
              return t != null && t !== "none";
            },
            hasStroke() {
              const t = this.stroke;
              return t != null && t !== "none" && this.lineWidth > 0;
            },
            extendFrom(t, e) {
              if (t) for (const r in t) !t.hasOwnProperty(r) || (!0 !== e && (!1 === e ? this.hasOwnProperty(r) : t[r] == null)) || (this[r] = t[r]);
            },
            set(t, e) {
              typeof t === "string" ? (this[t] = e) : this.extendFrom(t, !0);
            },
            clone() {
              const t = new this.constructor();
              return t.extendFrom(this, !0), t;
            },
            getGradient(t, e, r) {
              for (var n = (e.type === "radial" ? l : s)(t, e, r), i = e.colorStops, a = 0; a < i.length; a++) n.addColorStop(i[a].offset, i[a].color);
              return n;
            }
          }),
          u = 0;
        u < a.length;
        u++
      ) {
        const c = a[u];
        c[0] in h || (h[c[0]] = c[1]);
      }
      o.getGradient = h.getGradient;
      const f = o;
      t.exports = f;
    },
    function(t, e) {
      let r = 2311;
      t.exports = function() {
        return r++;
      };
    },
    function(t, e) {
      const r = Array.prototype.slice;
      const n = function(t) {
        (this._$handlers = {}), (this._$eventProcessor = t);
      };
      function i(t, e, r, n, i, a) {
        const o = t._$handlers;
        if ((typeof r === "function" && ((i = n), (n = r), (r = null)), !n || !e)) return t;
        (r = (function(t, e) {
          const r = t._$eventProcessor;
          return e != null && r && r.normalizeQuery && (e = r.normalizeQuery(e)), e;
        }(t, r))),
          o[e] || (o[e] = []);
        for (let s = 0; s < o[e].length; s++) if (o[e][s].h === n) return t;
        const l = {
          h: n,
          one: a,
          query: r,
          ctx: i || t,
          callAtLast: n.zrEventfulCallAtLast
        };
        const h = o[e].length - 1;
        const u = o[e][h];
        return u && u.callAtLast ? o[e].splice(h, 0, l) : o[e].push(l), t;
      }
      n.prototype = {
        constructor: n,
        one(t, e, r, n) {
          return i(this, t, e, r, n, !0);
        },
        on(t, e, r, n) {
          return i(this, t, e, r, n, !1);
        },
        isSilent(t) {
          const e = this._$handlers;
          return !e[t] || !e[t].length;
        },
        off(t, e) {
          const r = this._$handlers;
          if (!t) return (this._$handlers = {}), this;
          if (e) {
            if (r[t]) {
              for (var n = [], i = 0, a = r[t].length; i < a; i++) r[t][i].h !== e && n.push(r[t][i]);
              r[t] = n;
            }
            r[t] && r[t].length === 0 && delete r[t];
          } else delete r[t];
          return this;
        },
        trigger(t) {
          const e = this._$handlers[t];
          const n = this._$eventProcessor;
          if (e) {
            let i = arguments;
            const a = i.length;
            a > 3 && (i = r.call(i, 1));
            for (let o = e.length, s = 0; s < o;) {
              const l = e[s];
              if (n && n.filter && l.query != null && !n.filter(t, l.query)) s++;
              else {
                switch (a) {
                  case 1:
                    l.h.call(l.ctx);
                    break;
                  case 2:
                    l.h.call(l.ctx, i[1]);
                    break;
                  case 3:
                    l.h.call(l.ctx, i[1], i[2]);
                    break;
                  default:
                    l.h.apply(l.ctx, i);
                }
                l.one ? (e.splice(s, 1), o--) : s++;
              }
            }
          }
          return n && n.afterTrigger && n.afterTrigger(t), this;
        },
        triggerWithContext(t) {
          const e = this._$handlers[t];
          const n = this._$eventProcessor;
          if (e) {
            let i = arguments;
            const a = i.length;
            a > 4 && (i = r.call(i, 1, i.length - 1));
            for (let o = i[i.length - 1], s = e.length, l = 0; l < s;) {
              const h = e[l];
              if (n && n.filter && h.query != null && !n.filter(t, h.query)) l++;
              else {
                switch (a) {
                  case 1:
                    h.h.call(o);
                    break;
                  case 2:
                    h.h.call(o, i[1]);
                    break;
                  case 3:
                    h.h.call(o, i[1], i[2]);
                    break;
                  default:
                    h.h.apply(o, i);
                }
                h.one ? (e.splice(l, 1), s--) : l++;
              }
            }
          }
          return n && n.afterTrigger && n.afterTrigger(t), this;
        }
      };
      const a = n;
      t.exports = a;
    },
    function(t, e, r) {
      const n = r(46);
      const i = r(49);
      const a = r(0);
      const o = a.isString;
      const s = a.isFunction;
      const l = a.isObject;
      const h = a.isArrayLike;
      const u = a.indexOf;
      const c = function() {
        this.animators = [];
      };
      function f(t, e, r, n, i, a, u, c) {
        o(n) ? ((a = i), (i = n), (n = 0)) : s(i) ? ((a = i), (i = "linear"), (n = 0)) : s(n) ? ((a = n), (n = 0)) : s(r) ? ((a = r), (r = 500)) : r || (r = 500),
          t.stopAnimation(),
          (function t(e, r, n, i, a, o, s) {
            const u = {};
            let c = 0;
            for (const f in i) {
              i.hasOwnProperty(f)
                && (n[f] != null
                  ? l(i[f]) && !h(i[f])
                    ? t(e, r ? `${r}.${f}` : f, n[f], i[f], a, o, s)
                    : (s ? ((u[f] = n[f]), d(e, r, f, i[f])) : (u[f] = i[f]), c++)
                  : i[f] == null || s || d(e, r, f, i[f]));
            }
            c > 0
              && e
                .animate(r, !1)
                .when(a == null ? 500 : a, u)
                .delay(o || 0);
          }(t, "", t, e, r, n, c));
        const f = t.animators.slice();
        let p = f.length;
        function v() {
          --p || (a && a());
        }
        p || (a && a());
        for (let g = 0; g < f.length; g++) f[g].done(v).start(i, u);
      }
      function d(t, e, r, n) {
        if (e) {
          const i = {};
          (i[e] = {}), (i[e][r] = n), t.attr(i);
        } else t.attr(r, n);
      }
      c.prototype = {
        constructor: c,
        animate(t, e) {
          let r;
          let a = !1;
          const o = this;
          const s = this.__zr;
          if (t) {
            const l = t.split(".");
            let h = o;
            a = l[0] === "shape";
            for (let c = 0, f = l.length; c < f; c++) h && (h = h[l[c]]);
            h && (r = h);
          } else r = o;
          if (r) {
            const d = o.animators;
            const p = new n(r, e);
            return (
              p
                .during((t) => {
                  o.dirty(a);
                })
                .done(() => {
                  d.splice(u(d, p), 1);
                }),
              d.push(p),
              s && s.animation.addAnimator(p),
              p
            );
          }
          i(`Property "${t}" is not existed in element ${o.id}`);
        },
        stopAnimation(t) {
          for (var e = this.animators, r = e.length, n = 0; n < r; n++) e[n].stop(t);
          return (e.length = 0), this;
        },
        animateTo(t, e, r, n, i, a) {
          f(this, t, e, r, n, i, a);
        },
        animateFrom(t, e, r, n, i, a) {
          f(this, t, e, r, n, i, a, !0);
        }
      };
      const p = c;
      t.exports = p;
    },
    function(t, e, r) {
      const n = r(47);
      const i = r(21);
      const a = r(0).isArrayLike;
      const o = Array.prototype.slice;
      function s(t, e) {
        return t[e];
      }
      function l(t, e, r) {
        t[e] = r;
      }
      function h(t, e, r) {
        return (e - t) * r + t;
      }
      function u(t, e, r) {
        return r > 0.5 ? e : t;
      }
      function c(t, e, r, n, i) {
        const a = t.length;
        if (i === 1) for (var o = 0; o < a; o++) n[o] = h(t[o], e[o], r);
        else {
          const s = a && t[0].length;
          for (o = 0; o < a; o++) for (let l = 0; l < s; l++) n[o][l] = h(t[o][l], e[o][l], r);
        }
      }
      function f(t, e, r) {
        const n = t.length;
        const i = e.length;
        if (n !== i) {
          if (n > i) t.length = i;
          else for (var a = n; a < i; a++) t.push(r === 1 ? e[a] : o.call(e[a]));
        }
        const s = t[0] && t[0].length;
        for (a = 0; a < t.length; a++) {
          if (r === 1) isNaN(t[a]) && (t[a] = e[a]);
          else for (let l = 0; l < s; l++) isNaN(t[a][l]) && (t[a][l] = e[a][l]);
        }
      }
      function d(t, e, r) {
        if (t === e) return !0;
        const n = t.length;
        if (n !== e.length) return !1;
        if (r === 1) {
          for (var i = 0; i < n; i++) if (t[i] !== e[i]) return !1;
        } else {
          const a = t[0].length;
          for (i = 0; i < n; i++) for (let o = 0; o < a; o++) if (t[i][o] !== e[i][o]) return !1;
        }
        return !0;
      }
      function p(t, e, r, n, i, a, o, s, l) {
        const h = t.length;
        if (l === 1) for (var u = 0; u < h; u++) s[u] = v(t[u], e[u], r[u], n[u], i, a, o);
        else {
          const c = t[0].length;
          for (u = 0; u < h; u++) for (let f = 0; f < c; f++) s[u][f] = v(t[u][f], e[u][f], r[u][f], n[u][f], i, a, o);
        }
      }
      function v(t, e, r, n, i, a, o) {
        const s = 0.5 * (r - t);
        const l = 0.5 * (n - e);
        return (2 * (e - r) + s + l) * o + (-3 * (e - r) - 2 * s - l) * a + s * i + e;
      }
      function g(t) {
        if (a(t)) {
          const e = t.length;
          if (a(t[0])) {
            for (var r = [], n = 0; n < e; n++) r.push(o.call(t[n]));
            return r;
          }
          return o.call(t);
        }
        return t;
      }
      function y(t) {
        return (t[0] = Math.floor(t[0])), (t[1] = Math.floor(t[1])), (t[2] = Math.floor(t[2])), `rgba(${t.join(",")})`;
      }
      function m(t, e, r, o, s, l) {
        const g = t._getter;
        const m = t._setter;
        const x = e === "spline";
        const _ = o.length;
        if (_) {
          let b;
          const w = o[0].value;
          const S = a(w);
          let T = !1;
          let P = !1;
          const M = S
            ? (function(t) {
                const e = t[t.length - 1].value;
                return a(e && e[0]) ? 2 : 1;
              }(o))
            : 0;
          o.sort((t, e) => t.time - e.time), (b = o[_ - 1].time);
          for (var O = [], k = [], C = o[0].value, A = !0, D = 0; D < _; D++) {
            O.push(o[D].time / b);
            let I = o[D].value;
            if (((S && d(I, C, M)) || (!S && I === C) || (A = !1), (C = I), typeof I === "string")) {
              const L = i.parse(I);
              L ? ((I = L), (T = !0)) : (P = !0);
            }
            k.push(I);
          }
          if (l || !A) {
            const R = k[_ - 1];
            for (D = 0; D < _ - 1; D++) S ? f(k[D], R, M) : !isNaN(k[D]) || isNaN(R) || P || T || (k[D] = R);
            S && f(g(t._target, s), R, M);
            let B;
            let F;
            let E;
            let N;
            let z;
            let W = 0;
            let q = 0;
            if (T) var H = [0, 0, 0, 0];
            const j = new n({
              target: t._target,
              life: b,
              loop: t._loop,
              delay: t._delay,
              onframe(t, e) {
                let r;
                if (e < 0) r = 0;
                else if (e < q) {
                  for (r = Math.min(W + 1, _ - 1); r >= 0 && !(O[r] <= e); r--);
                  r = Math.min(r, _ - 2);
                } else {
                  for (r = W; r < _ && !(O[r] > e); r++);
                  r = Math.min(r - 1, _ - 2);
                }
                (W = r), (q = e);
                const n = O[r + 1] - O[r];
                if (n !== 0) {
                  if (((B = (e - O[r]) / n), x)) {
                    if (((E = k[r]), (F = k[r === 0 ? r : r - 1]), (N = k[r > _ - 2 ? _ - 1 : r + 1]), (z = k[r > _ - 3 ? _ - 1 : r + 2]), S)) p(F, E, N, z, B, B * B, B * B * B, g(t, s), M);
                    else {
                      if (T) (i = p(F, E, N, z, B, B * B, B * B * B, H, 1)), (i = y(H));
                      else {
                        if (P) return u(E, N, B);
                        i = v(F, E, N, z, B, B * B, B * B * B);
                      }
                      m(t, s, i);
                    }
                  } else if (S) c(k[r], k[r + 1], B, g(t, s), M);
                  else {
                    var i;
                    if (T) c(k[r], k[r + 1], B, H, 1), (i = y(H));
                    else {
                      if (P) return u(k[r], k[r + 1], B);
                      i = h(k[r], k[r + 1], B);
                    }
                    m(t, s, i);
                  }
                }
              },
              ondestroy: r
            });
            return e && e !== "spline" && (j.easing = e), j;
          }
        }
      }
      const x = function(t, e, r, n) {
        (this._tracks = {}),
          (this._target = t),
          (this._loop = e || !1),
          (this._getter = r || s),
          (this._setter = n || l),
          (this._clipCount = 0),
          (this._delay = 0),
          (this._doneList = []),
          (this._onframeList = []),
          (this._clipList = []);
      };
      x.prototype = {
        when(t, e) {
          const r = this._tracks;
          for (const n in e) {
            if (e.hasOwnProperty(n)) {
              if (!r[n]) {
                r[n] = [];
                const i = this._getter(this._target, n);
                if (i == null) continue;
                t !== 0 && r[n].push({ time: 0, value: g(i) });
              }
              r[n].push({ time: t, value: e[n] });
            }
          }
          return this;
        },
        during(t) {
          return this._onframeList.push(t), this;
        },
        pause() {
          for (let t = 0; t < this._clipList.length; t++) this._clipList[t].pause();
          this._paused = !0;
        },
        resume() {
          for (let t = 0; t < this._clipList.length; t++) this._clipList[t].resume();
          this._paused = !1;
        },
        isPaused() {
          return !!this._paused;
        },
        _doneCallback() {
          (this._tracks = {}), (this._clipList.length = 0);
          for (let t = this._doneList, e = t.length, r = 0; r < e; r++) t[r].call(this);
        },
        start(t, e) {
          let r;
          const n = this;
          let i = 0;
          const a = function() {
            --i || n._doneCallback();
          };
          for (const o in this._tracks) {
            if (this._tracks.hasOwnProperty(o)) {
              const s = m(this, t, a, this._tracks[o], o, e);
              s && (this._clipList.push(s), i++, this.animation && this.animation.addClip(s), (r = s));
            }
          }
          if (r) {
            const l = r.onframe;
            r.onframe = function(t, e) {
              l(t, e);
              for (let r = 0; r < n._onframeList.length; r++) n._onframeList[r](t, e);
            };
          }
          return i || this._doneCallback(), this;
        },
        stop(t) {
          for (var e = this._clipList, r = this.animation, n = 0; n < e.length; n++) {
            const i = e[n];
            t && i.onframe(this._target, 1), r && r.removeClip(i);
          }
          e.length = 0;
        },
        delay(t) {
          return (this._delay = t), this;
        },
        done(t) {
          return t && this._doneList.push(t), this;
        },
        getClips() {
          return this._clipList;
        }
      };
      const _ = x;
      t.exports = _;
    },
    function(t, e, r) {
      const n = r(48);
      function i(t) {
        (this._target = t.target),
          (this._life = t.life || 1e3),
          (this._delay = t.delay || 0),
          (this._initialized = !1),
          (this.loop = t.loop != null && t.loop),
          (this.gap = t.gap || 0),
          (this.easing = t.easing || "Linear"),
          (this.onframe = t.onframe),
          (this.ondestroy = t.ondestroy),
          (this.onrestart = t.onrestart),
          (this._pausedTime = 0),
          (this._paused = !1);
      }
      i.prototype = {
        constructor: i,
        step(t, e) {
          if ((this._initialized || ((this._startTime = t + this._delay), (this._initialized = !0)), this._paused)) this._pausedTime += e;
          else {
            let r = (t - this._startTime - this._pausedTime) / this._life;
            if (!(r < 0)) {
              r = Math.min(r, 1);
              const i = this.easing;
              const a = typeof i === "string" ? n[i] : i;
              const o = typeof a === "function" ? a(r) : r;
              return this.fire("frame", o), r === 1 ? (this.loop ? (this.restart(t), "restart") : ((this._needsRemove = !0), "destroy")) : null;
            }
          }
        },
        restart(t) {
          const e = (t - this._startTime - this._pausedTime) % this._life;
          (this._startTime = t - e + this.gap), (this._pausedTime = 0), (this._needsRemove = !1);
        },
        fire(t, e) {
          this[(t = `on${t}`)] && this[t](this._target, e);
        },
        pause() {
          this._paused = !0;
        },
        resume() {
          this._paused = !1;
        }
      };
      const a = i;
      t.exports = a;
    },
    function(t, e) {
      var r = {
        linear(t) {
          return t;
        },
        quadraticIn(t) {
          return t * t;
        },
        quadraticOut(t) {
          return t * (2 - t);
        },
        quadraticInOut(t) {
          return (t *= 2) < 1 ? 0.5 * t * t : -0.5 * (--t * (t - 2) - 1);
        },
        cubicIn(t) {
          return t * t * t;
        },
        cubicOut(t) {
          return --t * t * t + 1;
        },
        cubicInOut(t) {
          return (t *= 2) < 1 ? 0.5 * t * t * t : 0.5 * ((t -= 2) * t * t + 2);
        },
        quarticIn(t) {
          return t * t * t * t;
        },
        quarticOut(t) {
          return 1 - --t * t * t * t;
        },
        quarticInOut(t) {
          return (t *= 2) < 1 ? 0.5 * t * t * t * t : -0.5 * ((t -= 2) * t * t * t - 2);
        },
        quinticIn(t) {
          return t * t * t * t * t;
        },
        quinticOut(t) {
          return --t * t * t * t * t + 1;
        },
        quinticInOut(t) {
          return (t *= 2) < 1 ? 0.5 * t * t * t * t * t : 0.5 * ((t -= 2) * t * t * t * t + 2);
        },
        sinusoidalIn(t) {
          return 1 - Math.cos((t * Math.PI) / 2);
        },
        sinusoidalOut(t) {
          return Math.sin((t * Math.PI) / 2);
        },
        sinusoidalInOut(t) {
          return 0.5 * (1 - Math.cos(Math.PI * t));
        },
        exponentialIn(t) {
          return t === 0 ? 0 : Math.pow(1024, t - 1);
        },
        exponentialOut(t) {
          return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
        },
        exponentialInOut(t) {
          return t === 0 ? 0 : t === 1 ? 1 : (t *= 2) < 1 ? 0.5 * Math.pow(1024, t - 1) : 0.5 * (2 - Math.pow(2, -10 * (t - 1)));
        },
        circularIn(t) {
          return 1 - Math.sqrt(1 - t * t);
        },
        circularOut(t) {
          return Math.sqrt(1 - --t * t);
        },
        circularInOut(t) {
          return (t *= 2) < 1 ? -0.5 * (Math.sqrt(1 - t * t) - 1) : 0.5 * (Math.sqrt(1 - (t -= 2) * t) + 1);
        },
        elasticIn(t) {
          let e;
          let r = 0.1;
          return t === 0
            ? 0
            : t === 1
            ? 1
            : (!r || r < 1 ? ((r = 1), (e = 0.1)) : (e = (0.4 * Math.asin(1 / r)) / (2 * Math.PI)), -r * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t - e) * (2 * Math.PI)) / 0.4));
        },
        elasticOut(t) {
          let e;
          let r = 0.1;
          return t === 0
            ? 0
            : t === 1
            ? 1
            : (!r || r < 1 ? ((r = 1), (e = 0.1)) : (e = (0.4 * Math.asin(1 / r)) / (2 * Math.PI)), r * Math.pow(2, -10 * t) * Math.sin(((t - e) * (2 * Math.PI)) / 0.4) + 1);
        },
        elasticInOut(t) {
          let e;
          let r = 0.1;
          const n = 0.4;
          return t === 0
            ? 0
            : t === 1
            ? 1
            : (!r || r < 1 ? ((r = 1), (e = 0.1)) : (e = (n * Math.asin(1 / r)) / (2 * Math.PI)),
              (t *= 2) < 1 ? r * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t - e) * (2 * Math.PI)) / n) * -0.5 : r * Math.pow(2, -10 * (t -= 1)) * Math.sin(((t - e) * (2 * Math.PI)) / n) * 0.5 + 1);
        },
        backIn(t) {
          const e = 1.70158;
          return t * t * ((e + 1) * t - e);
        },
        backOut(t) {
          const e = 1.70158;
          return --t * t * ((e + 1) * t + e) + 1;
        },
        backInOut(t) {
          const e = 2.5949095;
          return (t *= 2) < 1 ? t * t * ((e + 1) * t - e) * 0.5 : 0.5 * ((t -= 2) * t * ((e + 1) * t + e) + 2);
        },
        bounceIn(t) {
          return 1 - r.bounceOut(1 - t);
        },
        bounceOut(t) {
          return t < 1 / 2.75
            ? 7.5625 * t * t
            : t < 2 / 2.75
            ? 7.5625 * (t -= 1.5 / 2.75) * t + 0.75
            : t < 2.5 / 2.75
            ? 7.5625 * (t -= 2.25 / 2.75) * t + 0.9375
            : 7.5625 * (t -= 2.625 / 2.75) * t + 0.984375;
        },
        bounceInOut(t) {
          return t < 0.5 ? 0.5 * r.bounceIn(2 * t) : 0.5 * r.bounceOut(2 * t - 1) + 0.5;
        }
      };
      const n = r;
      t.exports = n;
    },
    function(t, e, r) {
      let n = function() {};
      r(23).debugMode === 1 && (n = console.error);
      const i = n;
      t.exports = i;
    },
    function(t, e, r) {
      const n = r(24);
      const i = r(3);
      const a = r(7).WILL_BE_RESTORED;
      const o = new i();
      const s = function() {};
      s.prototype = {
        constructor: s,
        drawRectText(t, e) {
          const r = this.style;
          (e = r.textRect || e), this.__dirty && n.normalizeTextStyle(r, !0);
          let i = r.text;
          if ((i != null && (i += ""), n.needDrawText(i, r))) {
            t.save();
            const s = this.transform;
            r.transformText ? this.setTransform(t) : s && (o.copy(e), o.applyTransform(s), (e = o)), n.renderText(this, t, i, r, e, a), t.restore();
          }
        }
      };
      const l = s;
      t.exports = l;
    },
    function(t, e, r) {
      const n = r(2);
      const i = r(4);
      const a = Math.min;
      const o = Math.max;
      const s = Math.sin;
      const l = Math.cos;
      const h = 2 * Math.PI;
      const u = n.create();
      const c = n.create();
      const f = n.create();
      const d = [];
      const p = [];
      (e.fromPoints = function(t, e, r) {
        if (t.length !== 0) {
          let n;
          let i = t[0];
          let s = i[0];
          let l = i[0];
          let h = i[1];
          let u = i[1];
          for (n = 1; n < t.length; n++) (i = t[n]), (s = a(s, i[0])), (l = o(l, i[0])), (h = a(h, i[1])), (u = o(u, i[1]));
          (e[0] = s), (e[1] = h), (r[0] = l), (r[1] = u);
        }
      }),
        (e.fromLine = function(t, e, r, n, i, s) {
          (i[0] = a(t, r)), (i[1] = a(e, n)), (s[0] = o(t, r)), (s[1] = o(e, n));
        }),
        (e.fromCubic = function(t, e, r, n, s, l, h, u, c, f) {
          let v;
          const g = i.cubicExtrema;
          const y = i.cubicAt;
          let m = g(t, r, s, h, d);
          for (c[0] = 1 / 0, c[1] = 1 / 0, f[0] = -1 / 0, f[1] = -1 / 0, v = 0; v < m; v++) {
            const x = y(t, r, s, h, d[v]);
            (c[0] = a(x, c[0])), (f[0] = o(x, f[0]));
          }
          for (m = g(e, n, l, u, p), v = 0; v < m; v++) {
            const _ = y(e, n, l, u, p[v]);
            (c[1] = a(_, c[1])), (f[1] = o(_, f[1]));
          }
          (c[0] = a(t, c[0])), (f[0] = o(t, f[0])), (c[0] = a(h, c[0])), (f[0] = o(h, f[0])), (c[1] = a(e, c[1])), (f[1] = o(e, f[1])), (c[1] = a(u, c[1])), (f[1] = o(u, f[1]));
        }),
        (e.fromQuadratic = function(t, e, r, n, s, l, h, u) {
          const c = i.quadraticExtremum;
          const f = i.quadraticAt;
          const d = o(a(c(t, r, s), 1), 0);
          const p = o(a(c(e, n, l), 1), 0);
          const v = f(t, r, s, d);
          const g = f(e, n, l, p);
          (h[0] = a(t, s, v)), (h[1] = a(e, l, g)), (u[0] = o(t, s, v)), (u[1] = o(e, l, g));
        }),
        (e.fromArc = function(t, e, r, i, a, o, d, p, v) {
          const g = n.min;
          const y = n.max;
          const m = Math.abs(a - o);
          if (m % h < 1e-4 && m > 1e-4) return (p[0] = t - r), (p[1] = e - i), (v[0] = t + r), void (v[1] = e + i);
          if (
            ((u[0] = l(a) * r + t),
            (u[1] = s(a) * i + e),
            (c[0] = l(o) * r + t),
            (c[1] = s(o) * i + e),
            g(p, u, c),
            y(v, u, c),
            (a %= h) < 0 && (a += h),
            (o %= h) < 0 && (o += h),
            a > o && !d ? (o += h) : a < o && d && (a += h),
            d)
          ) {
            const x = o;
            (o = a), (a = x);
          }
          for (let _ = 0; _ < o; _ += Math.PI / 2) _ > a && ((f[0] = l(_) * r + t), (f[1] = s(_) * i + e), g(p, f, p), y(v, f, v));
        });
    },
    function(t, e, r) {
      const n = r(8);
      const i = r(53);
      const a = r(54);
      const o = r(55);
      const s = r(56);
      const l = r(26).normalizeRadian;
      const h = r(4);
      const u = r(57);
      const c = n.CMD;
      const f = 2 * Math.PI;
      const d = [-1, -1, -1];
      const p = [-1, -1];
      function v(t, e, r, n, i, a, o, s, l, u) {
        if ((u > e && u > n && u > a && u > s) || (u < e && u < n && u < a && u < s)) return 0;
        let c;
        const f = h.cubicRootAt(e, n, a, s, u, d);
        if (f === 0) return 0;
        for (var v, g, y = 0, m = -1, x = 0; x < f; x++) {
          const _ = d[x];
          const b = _ === 0 || _ === 1 ? 0.5 : 1;
          h.cubicAt(t, r, i, o, _) < l
            || (m < 0
              && ((m = h.cubicExtrema(e, n, a, s, p)),
              p[1] < p[0] && m > 1 && ((c = void 0), (c = p[0]), (p[0] = p[1]), (p[1] = c)),
              (v = h.cubicAt(e, n, a, s, p[0])),
              m > 1 && (g = h.cubicAt(e, n, a, s, p[1]))),
            m === 2 ? (_ < p[0] ? (y += v < e ? b : -b) : _ < p[1] ? (y += g < v ? b : -b) : (y += s < g ? b : -b)) : _ < p[0] ? (y += v < e ? b : -b) : (y += s < v ? b : -b));
        }
        return y;
      }
      function g(t, e, r, n, i, a, o, s) {
        if ((s > e && s > n && s > a) || (s < e && s < n && s < a)) return 0;
        const l = h.quadraticRootAt(e, n, a, s, d);
        if (l === 0) return 0;
        const u = h.quadraticExtremum(e, n, a);
        if (u >= 0 && u <= 1) {
          for (var c = 0, f = h.quadraticAt(e, n, a, u), p = 0; p < l; p++) {
            var v = d[p] === 0 || d[p] === 1 ? 0.5 : 1;
            h.quadraticAt(t, r, i, d[p]) < o || (d[p] < u ? (c += f < e ? v : -v) : (c += a < f ? v : -v));
          }
          return c;
        }
        v = d[0] === 0 || d[0] === 1 ? 0.5 : 1;
        return h.quadraticAt(t, r, i, d[0]) < o ? 0 : a < e ? v : -v;
      }
      function y(t, e, r, n, i, a, o, s) {
        if ((s -= e) > r || s < -r) return 0;
        let h = Math.sqrt(r * r - s * s);
        (d[0] = -h), (d[1] = h);
        const u = Math.abs(n - i);
        if (u < 1e-4) return 0;
        if (u % f < 1e-4) {
          (n = 0), (i = f);
          var c = a ? 1 : -1;
          return o >= d[0] + t && o <= d[1] + t ? c : 0;
        }
        if (a) {
          h = n;
          (n = l(i)), (i = l(h));
        } else (n = l(n)), (i = l(i));
        n > i && (i += f);
        for (var p = 0, v = 0; v < 2; v++) {
          const g = d[v];
          if (g + t > o) {
            let y = Math.atan2(s, g);
            c = a ? 1 : -1;
            y < 0 && (y = f + y), ((y >= n && y <= i) || (y + f >= n && y + f <= i)) && (y > Math.PI / 2 && y < 1.5 * Math.PI && (c = -c), (p += c));
          }
        }
        return p;
      }
      function m(t, e, r, n, l) {
        for (var h, f, d = 0, p = 0, m = 0, x = 0, _ = 0, b = 0; b < t.length;) {
          const w = t[b++];
          switch ((w === c.M && b > 1 && (r || (d += u(p, m, x, _, n, l))), b === 1 && ((x = p = t[b]), (_ = m = t[b + 1])), w)) {
            case c.M:
              (p = x = t[b++]), (m = _ = t[b++]);
              break;
            case c.L:
              if (r) {
                if (i.containStroke(p, m, t[b], t[b + 1], e, n, l)) return !0;
              } else d += u(p, m, t[b], t[b + 1], n, l) || 0;
              (p = t[b++]), (m = t[b++]);
              break;
            case c.C:
              if (r) {
                if (a.containStroke(p, m, t[b++], t[b++], t[b++], t[b++], t[b], t[b + 1], e, n, l)) return !0;
              } else d += v(p, m, t[b++], t[b++], t[b++], t[b++], t[b], t[b + 1], n, l) || 0;
              (p = t[b++]), (m = t[b++]);
              break;
            case c.Q:
              if (r) {
                if (o.containStroke(p, m, t[b++], t[b++], t[b], t[b + 1], e, n, l)) return !0;
              } else d += g(p, m, t[b++], t[b++], t[b], t[b + 1], n, l) || 0;
              (p = t[b++]), (m = t[b++]);
              break;
            case c.A:
              var S = t[b++];
              var T = t[b++];
              var P = t[b++];
              var M = t[b++];
              var O = t[b++];
              var k = t[b++];
              b += 1;
              var C = 1 - t[b++];
              var A = Math.cos(O) * P + S;
              var D = Math.sin(O) * M + T;
              b > 1 ? (d += u(p, m, A, D, n, l)) : ((x = A), (_ = D));
              var I = ((n - S) * M) / P + S;
              if (r) {
                if (s.containStroke(S, T, M, O, O + k, C, e, I, l)) return !0;
              } else d += y(S, T, M, O, O + k, C, I, l);
              (p = Math.cos(O + k) * P + S), (m = Math.sin(O + k) * M + T);
              break;
            case c.R:
              (x = p = t[b++]), (_ = m = t[b++]);
              (A = x + t[b++]), (D = _ + t[b++]);
              if (r) {
                if (i.containStroke(x, _, A, _, e, n, l) || i.containStroke(A, _, A, D, e, n, l) || i.containStroke(A, D, x, D, e, n, l) || i.containStroke(x, D, x, _, e, n, l)) return !0;
              } else (d += u(A, _, A, D, n, l)), (d += u(x, D, x, _, n, l));
              break;
            case c.Z:
              if (r) {
                if (i.containStroke(p, m, x, _, e, n, l)) return !0;
              } else d += u(p, m, x, _, n, l);
              (p = x), (m = _);
          }
        }
        return r || ((h = m), (f = _), Math.abs(h - f) < 1e-4) || (d += u(p, m, x, _, n, l) || 0), d !== 0;
      }
      (e.contain = function(t, e, r) {
        return m(t, 0, !1, e, r);
      }),
        (e.containStroke = function(t, e, r, n) {
          return m(t, e, !0, r, n);
        });
    },
    function(t, e) {
      e.containStroke = function(t, e, r, n, i, a, o) {
        if (i === 0) return !1;
        const s = i;
        let l = 0;
        if ((o > e + s && o > n + s) || (o < e - s && o < n - s) || (a > t + s && a > r + s) || (a < t - s && a < r - s)) return !1;
        if (t === r) return Math.abs(a - t) <= s / 2;
        const h = (l = (e - n) / (t - r)) * a - o + (t * n - r * e) / (t - r);
        return (h * h) / (l * l + 1) <= ((s / 2) * s) / 2;
      };
    },
    function(t, e, r) {
      const n = r(4);
      e.containStroke = function(t, e, r, i, a, o, s, l, h, u, c) {
        if (h === 0) return !1;
        const f = h;
        return (
          !(
            (c > e + f && c > i + f && c > o + f && c > l + f)
            || (c < e - f && c < i - f && c < o - f && c < l - f)
            || (u > t + f && u > r + f && u > a + f && u > s + f)
            || (u < t - f && u < r - f && u < a - f && u < s - f)
          ) && n.cubicProjectPoint(t, e, r, i, a, o, s, l, u, c, null) <= f / 2
        );
      };
    },
    function(t, e, r) {
      const n = r(4).quadraticProjectPoint;
      e.containStroke = function(t, e, r, i, a, o, s, l, h) {
        if (s === 0) return !1;
        const u = s;
        return (
          !((h > e + u && h > i + u && h > o + u) || (h < e - u && h < i - u && h < o - u) || (l > t + u && l > r + u && l > a + u) || (l < t - u && l < r - u && l < a - u))
          && n(t, e, r, i, a, o, l, h, null) <= u / 2
        );
      };
    },
    function(t, e, r) {
      const n = r(26).normalizeRadian;
      const i = 2 * Math.PI;
      e.containStroke = function(t, e, r, a, o, s, l, h, u) {
        if (l === 0) return !1;
        const c = l;
        (h -= t), (u -= e);
        const f = Math.sqrt(h * h + u * u);
        if (f - c > r || f + c < r) return !1;
        if (Math.abs(a - o) % i < 1e-4) return !0;
        if (s) {
          const d = a;
          (a = n(o)), (o = n(d));
        } else (a = n(a)), (o = n(o));
        a > o && (o += i);
        let p = Math.atan2(u, h);
        return p < 0 && (p += i), (p >= a && p <= o) || (p + i >= a && p + i <= o);
      };
    },
    function(t, e) {
      t.exports = function(t, e, r, n, i, a) {
        if ((a > e && a > n) || (a < e && a < n)) return 0;
        if (n === e) return 0;
        let o = n < e ? 1 : -1;
        const s = (a - e) / (n - e);
        (s !== 1 && s !== 0) || (o = n < e ? 0.5 : -0.5);
        const l = s * (r - t) + t;
        return l === i ? 1 / 0 : l > i ? o : 0;
      };
    },
    function(t, e) {
      const r = function(t, e) {
        (this.image = t), (this.repeat = e), (this.type = "pattern");
      };
      r.prototype.getCanvasPattern = function(t) {
        return t.createPattern(this.image, this.repeat || "repeat");
      };
      const n = r;
      t.exports = n;
    },
    function(t, e, r) {
      const n = r(8);
      const i = r(2).applyTransform;
      const a = n.CMD;
      const o = [[], [], []];
      const s = Math.sqrt;
      const l = Math.atan2;
      t.exports = function(t, e) {
        let r;
        let n;
        let h;
        let u;
        let c;
        const f = t.data;
        const d = a.M;
        const p = a.C;
        const v = a.L;
        const g = a.R;
        const y = a.A;
        const m = a.Q;
        for (h = 0, u = 0; h < f.length;) {
          switch (((r = f[h++]), (u = h), (n = 0), r)) {
            case d:
            case v:
              n = 1;
              break;
            case p:
              n = 3;
              break;
            case m:
              n = 2;
              break;
            case y:
              var x = e[4];
              var _ = e[5];
              var b = s(e[0] * e[0] + e[1] * e[1]);
              var w = s(e[2] * e[2] + e[3] * e[3]);
              var S = l(-e[1] / w, e[0] / b);
              (f[h] *= b), (f[h++] += x), (f[h] *= w), (f[h++] += _), (f[h++] *= b), (f[h++] *= w), (f[h++] += S), (f[h++] += S), (u = h += 2);
              break;
            case g:
              (T[0] = f[h++]), (T[1] = f[h++]), i(T, T, e), (f[u++] = T[0]), (f[u++] = T[1]), (T[0] += f[h++]), (T[1] += f[h++]), i(T, T, e), (f[u++] = T[0]), (f[u++] = T[1]);
          }
          for (c = 0; c < n; c++) {
            var T;
            ((T = o[c])[0] = f[h++]), (T[1] = f[h++]), i(T, T, e), (f[u++] = T[0]), (f[u++] = T[1]);
          }
        }
      };
    },
    function(t, e, r) {
      const n = r(6);
      const i = r(3);
      const a = r(0);
      const o = r(12);
      function s(t) {
        n.call(this, t);
      }
      (s.prototype = {
        constructor: s,
        type: "image",
        brush(t, e) {
          const r = this.style;
          const n = r.image;
          r.bind(t, this, e);
          const i = (this._image = o.createOrUpdateImage(n, this._image, this, this.onload));
          if (i && o.isImageReady(i)) {
            const a = r.x || 0;
            const s = r.y || 0;
            let l = r.width;
            let h = r.height;
            const u = i.width / i.height;
            if (
              (l == null && h != null ? (l = h * u) : h == null && l != null ? (h = l / u) : l == null && h == null && ((l = i.width), (h = i.height)), this.setTransform(t), r.sWidth && r.sHeight)
            ) {
              var c = r.sx || 0;
              var f = r.sy || 0;
              t.drawImage(i, c, f, r.sWidth, r.sHeight, a, s, l, h);
            } else if (r.sx && r.sy) {
              const d = l - (c = r.sx);
              const p = h - (f = r.sy);
              t.drawImage(i, c, f, d, p, a, s, l, h);
            } else t.drawImage(i, a, s, l, h);
            r.text != null && (this.restoreTransform(t), this.drawRectText(t, this.getBoundingRect()));
          }
        },
        getBoundingRect() {
          const t = this.style;
          return this._rect || (this._rect = new i(t.x || 0, t.y || 0, t.width || 0, t.height || 0)), this._rect;
        }
      }),
        a.inherits(s, n);
      const l = s;
      t.exports = l;
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(19);
      const a = r(3);
      const o = function(t) {
        for (const e in ((t = t || {}), i.call(this, t), t)) t.hasOwnProperty(e) && (this[e] = t[e]);
        (this._children = []), (this.__storage = null), (this.__dirty = !0);
      };
      (o.prototype = {
        constructor: o,
        isGroup: !0,
        type: "group",
        silent: !1,
        children() {
          return this._children.slice();
        },
        childAt(t) {
          return this._children[t];
        },
        childOfName(t) {
          for (let e = this._children, r = 0; r < e.length; r++) if (e[r].name === t) return e[r];
        },
        childCount() {
          return this._children.length;
        },
        add(t) {
          return t && t !== this && t.parent !== this && (this._children.push(t), this._doAdd(t)), this;
        },
        addBefore(t, e) {
          if (t && t !== this && t.parent !== this && e && e.parent === this) {
            const r = this._children;
            const n = r.indexOf(e);
            n >= 0 && (r.splice(n, 0, t), this._doAdd(t));
          }
          return this;
        },
        _doAdd(t) {
          t.parent && t.parent.remove(t), (t.parent = this);
          const e = this.__storage;
          const r = this.__zr;
          e && e !== t.__storage && (e.addToStorage(t), t instanceof o && t.addChildrenToStorage(e)), r && r.refresh();
        },
        remove(t) {
          const e = this.__zr;
          const r = this.__storage;
          const i = this._children;
          const a = n.indexOf(i, t);
          return a < 0 || (i.splice(a, 1), (t.parent = null), r && (r.delFromStorage(t), t instanceof o && t.delChildrenFromStorage(r)), e && e.refresh()), this;
        },
        removeAll() {
          let t;
          let e;
          const r = this._children;
          const n = this.__storage;
          for (e = 0; e < r.length; e++) (t = r[e]), n && (n.delFromStorage(t), t instanceof o && t.delChildrenFromStorage(n)), (t.parent = null);
          return (r.length = 0), this;
        },
        eachChild(t, e) {
          for (let r = this._children, n = 0; n < r.length; n++) {
            const i = r[n];
            t.call(e, i, n);
          }
          return this;
        },
        traverse(t, e) {
          for (let r = 0; r < this._children.length; r++) {
            const n = this._children[r];
            t.call(e, n), n.type === "group" && n.traverse(t, e);
          }
          return this;
        },
        addChildrenToStorage(t) {
          for (let e = 0; e < this._children.length; e++) {
            const r = this._children[e];
            t.addToStorage(r), r instanceof o && r.addChildrenToStorage(t);
          }
        },
        delChildrenFromStorage(t) {
          for (let e = 0; e < this._children.length; e++) {
            const r = this._children[e];
            t.delFromStorage(r), r instanceof o && r.delChildrenFromStorage(t);
          }
        },
        dirty() {
          return (this.__dirty = !0), this.__zr && this.__zr.refresh(), this;
        },
        getBoundingRect(t) {
          for (var e = null, r = new a(0, 0, 0, 0), n = t || this._children, i = [], o = 0; o < n.length; o++) {
            const s = n[o];
            if (!s.ignore && !s.invisible) {
              const l = s.getBoundingRect();
              const h = s.getLocalTransform(i);
              h ? (r.copy(l), r.applyTransform(h), (e = e || r.clone()).union(r)) : (e = e || l.clone()).union(l);
            }
          }
          return e || r;
        }
      }),
        n.inherits(o, i);
      const s = o;
      t.exports = s;
    },
    function(t, e, r) {
      const n = r(6);
      const i = r(0);
      const a = r(11);
      const o = r(24);
      const s = r(7).ContextCachedBy;
      const l = function(t) {
        n.call(this, t);
      };
      (l.prototype = {
        constructor: l,
        type: "text",
        brush(t, e) {
          const r = this.style;
          this.__dirty && o.normalizeTextStyle(r, !0), (r.fill = r.stroke = r.shadowBlur = r.shadowColor = r.shadowOffsetX = r.shadowOffsetY = null);
          let n = r.text;
          n != null && (n += ""), o.needDrawText(n, r) ? (this.setTransform(t), o.renderText(this, t, n, r, null, e), this.restoreTransform(t)) : (t.__attrCachedBy = s.NONE);
        },
        getBoundingRect() {
          const t = this.style;
          if ((this.__dirty && o.normalizeTextStyle(t, !0), !this._rect)) {
            let e = t.text;
            e != null ? (e += "") : (e = "");
            const r = a.getBoundingRect(`${t.text}`, t.font, t.textAlign, t.textVerticalAlign, t.textPadding, t.textLineHeight, t.rich);
            if (((r.x += t.x || 0), (r.y += t.y || 0), o.getStroke(t.textStroke, t.textStrokeWidth))) {
              const n = t.textStrokeWidth;
              (r.x -= n / 2), (r.y -= n / 2), (r.width += n), (r.height += n);
            }
            this._rect = r;
          }
          return this._rect;
        }
      }),
        i.inherits(l, n);
      const h = l;
      t.exports = h;
    },
    function(t, e, r) {
      const n = r(1).extend({
        type: "circle",
        shape: { cx: 0, cy: 0, r: 0 },
        buildPath(t, e, r) {
          r && t.moveTo(e.cx + e.r, e.cy), t.arc(e.cx, e.cy, e.r, 0, 2 * Math.PI, !0);
        }
      });
      t.exports = n;
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(65);
      const a = n.extend({
        type: "sector",
        shape: {
          cx: 0,
          cy: 0,
          r0: 0,
          r: 0,
          startAngle: 0,
          endAngle: 2 * Math.PI,
          clockwise: !0
        },
        brush: i(n.prototype.brush),
        buildPath(t, e) {
          const r = e.cx;
          const n = e.cy;
          const i = Math.max(e.r0 || 0, 0);
          const a = Math.max(e.r, 0);
          const o = e.startAngle;
          const s = e.endAngle;
          const l = e.clockwise;
          const h = Math.cos(o);
          const u = Math.sin(o);
          t.moveTo(h * i + r, u * i + n),
            t.lineTo(h * a + r, u * a + n),
            t.arc(r, n, a, o, s, !l),
            t.lineTo(Math.cos(s) * i + r, Math.sin(s) * i + n),
            i !== 0 && t.arc(r, n, i, s, o, l),
            t.closePath();
        }
      });
      t.exports = a;
    },
    function(t, e, r) {
      const n = r(15);
      const i = [
        ["shadowBlur", 0],
        ["shadowColor", "#000"],
        ["shadowOffsetX", 0],
        ["shadowOffsetY", 0]
      ];
      t.exports = function(t) {
        return n.browser.ie && n.browser.version >= 11
          ? function() {
              let e;
              const r = this.__clipPaths;
              const n = this.style;
              if (r) {
                for (let a = 0; a < r.length; a++) {
                  const o = r[a];
                  const s = o && o.shape;
                  const l = o && o.type;
                  if (s && ((l === "sector" && s.startAngle === s.endAngle) || (l === "rect" && (!s.width || !s.height)))) {
                    for (var h = 0; h < i.length; h++) (i[h][2] = n[i[h][0]]), (n[i[h][0]] = i[h][1]);
                    e = !0;
                    break;
                  }
                }
              }
              if ((t.apply(this, arguments), e)) for (h = 0; h < i.length; h++) n[i[h][0]] = i[h][2];
            }
          : t;
      };
    },
    function(t, e, r) {
      const n = r(1).extend({
        type: "ring",
        shape: {
          cx: 0,
          cy: 0,
          r: 0,
          r0: 0
        },
        buildPath(t, e) {
          const r = e.cx;
          const n = e.cy;
          const i = 2 * Math.PI;
          t.moveTo(r + e.r, n), t.arc(r, n, e.r, 0, i, !1), t.moveTo(r + e.r0, n), t.arc(r, n, e.r0, 0, i, !0);
        }
      });
      t.exports = n;
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(27);
      const a = n.extend({
        type: "polygon",
        shape: { points: null, smooth: !1, smoothConstraint: null },
        buildPath(t, e) {
          i.buildPath(t, e, !0);
        }
      });
      t.exports = a;
    },
    function(t, e, r) {
      const n = r(2).distance;
      function i(t, e, r, n, i, a, o) {
        const s = 0.5 * (r - t);
        const l = 0.5 * (n - e);
        return (2 * (e - r) + s + l) * o + (-3 * (e - r) - 2 * s - l) * a + s * i + e;
      }
      t.exports = function(t, e) {
        for (var r = t.length, a = [], o = 0, s = 1; s < r; s++) o += n(t[s - 1], t[s]);
        let l = o / 2;
        for (l = l < r ? r : l, s = 0; s < l; s++) {
          var h;
          var u;
          var c;
          const f = (s / (l - 1)) * (e ? r : r - 1);
          const d = Math.floor(f);
          const p = f - d;
          const v = t[d % r];
          e ? ((h = t[(d - 1 + r) % r]), (u = t[(d + 1) % r]), (c = t[(d + 2) % r])) : ((h = t[d === 0 ? d : d - 1]), (u = t[d > r - 2 ? r - 1 : d + 1]), (c = t[d > r - 3 ? r - 1 : d + 2]));
          const g = p * p;
          const y = p * g;
          a.push([i(h[0], v[0], u[0], c[0], p, g, y), i(h[1], v[1], u[1], c[1], p, g, y)]);
        }
        return a;
      };
    },
    function(t, e, r) {
      const n = r(2);
      const i = n.min;
      const a = n.max;
      const o = n.scale;
      const s = n.distance;
      const l = n.add;
      const h = n.clone;
      const u = n.sub;
      t.exports = function(t, e, r, n) {
        let c;
        let f;
        let d;
        let p;
        const v = [];
        const g = [];
        const y = [];
        const m = [];
        if (n) {
          (d = [1 / 0, 1 / 0]), (p = [-1 / 0, -1 / 0]);
          for (var x = 0, _ = t.length; x < _; x++) i(d, d, t[x]), a(p, p, t[x]);
          i(d, d, n[0]), a(p, p, n[1]);
        }
        for (x = 0, _ = t.length; x < _; x++) {
          const b = t[x];
          if (r) (c = t[x ? x - 1 : _ - 1]), (f = t[(x + 1) % _]);
          else {
            if (x === 0 || x === _ - 1) {
              v.push(h(t[x]));
              continue;
            }
            (c = t[x - 1]), (f = t[x + 1]);
          }
          u(g, f, c), o(g, g, e);
          let w = s(b, c);
          let S = s(b, f);
          const T = w + S;
          T !== 0 && ((w /= T), (S /= T)), o(y, g, -w), o(m, g, S);
          const P = l([], b, y);
          const M = l([], b, m);
          n && (a(P, P, d), i(P, P, p), a(M, M, d), i(M, M, p)), v.push(P), v.push(M);
        }
        return r && v.push(v.shift()), v;
      };
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(27);
      const a = n.extend({
        type: "polyline",
        shape: { points: null, smooth: !1, smoothConstraint: null },
        style: { stroke: "#000", fill: null },
        buildPath(t, e) {
          i.buildPath(t, e, !1);
        }
      });
      t.exports = a;
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(25);
      const a = r(13).subPixelOptimizeRect;
      const o = {};
      const s = n.extend({
        type: "rect",
        shape: {
          r: 0,
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        buildPath(t, e) {
          let r;
          let n;
          let s;
          let l;
          this.subPixelOptimize ? (a(o, e, this.style), (r = o.x), (n = o.y), (s = o.width), (l = o.height), (o.r = e.r), (e = o)) : ((r = e.x), (n = e.y), (s = e.width), (l = e.height)),
            e.r ? i.buildPath(t, e) : t.rect(r, n, s, l),
            t.closePath();
        }
      });
      t.exports = s;
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(13).subPixelOptimizeLine;
      const a = {};
      const o = n.extend({
        type: "line",
        shape: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          percent: 1
        },
        style: { stroke: "#000", fill: null },
        buildPath(t, e) {
          let r;
          let n;
          let o;
          let s;
          this.subPixelOptimize ? (i(a, e, this.style), (r = a.x1), (n = a.y1), (o = a.x2), (s = a.y2)) : ((r = e.x1), (n = e.y1), (o = e.x2), (s = e.y2));
          const l = e.percent;
          l !== 0 && (t.moveTo(r, n), l < 1 && ((o = r * (1 - l) + o * l), (s = n * (1 - l) + s * l)), t.lineTo(o, s));
        },
        pointAt(t) {
          const e = this.shape;
          return [e.x1 * (1 - t) + e.x2 * t, e.y1 * (1 - t) + e.y2 * t];
        }
      });
      t.exports = o;
    },
    function(t, e, r) {
      const n = r(1);
      const i = r(2);
      const a = r(4);
      const o = a.quadraticSubdivide;
      const s = a.cubicSubdivide;
      const l = a.quadraticAt;
      const h = a.cubicAt;
      const u = a.quadraticDerivativeAt;
      const c = a.cubicDerivativeAt;
      const f = [];
      function d(t, e, r) {
        const n = t.cpx2;
        const i = t.cpy2;
        return n === null || i === null
          ? [(r ? c : h)(t.x1, t.cpx1, t.cpx2, t.x2, e), (r ? c : h)(t.y1, t.cpy1, t.cpy2, t.y2, e)]
          : [(r ? u : l)(t.x1, t.cpx1, t.x2, e), (r ? u : l)(t.y1, t.cpy1, t.y2, e)];
      }
      const p = n.extend({
        type: "bezier-curve",
        shape: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          cpx1: 0,
          cpy1: 0,
          percent: 1
        },
        style: { stroke: "#000", fill: null },
        buildPath(t, e) {
          const r = e.x1;
          const n = e.y1;
          let i = e.x2;
          let a = e.y2;
          let l = e.cpx1;
          let h = e.cpy1;
          let u = e.cpx2;
          let c = e.cpy2;
          const d = e.percent;
          d !== 0
            && (t.moveTo(r, n),
            u == null || c == null
              ? (d < 1 && (o(r, l, i, d, f), (l = f[1]), (i = f[2]), o(n, h, a, d, f), (h = f[1]), (a = f[2])), t.quadraticCurveTo(l, h, i, a))
              : (d < 1 && (s(r, l, u, i, d, f), (l = f[1]), (u = f[2]), (i = f[3]), s(n, h, c, a, d, f), (h = f[1]), (c = f[2]), (a = f[3])), t.bezierCurveTo(l, h, u, c, i, a)));
        },
        pointAt(t) {
          return d(this.shape, t, !1);
        },
        tangentAt(t) {
          const e = d(this.shape, t, !0);
          return i.normalize(e, e);
        }
      });
      t.exports = p;
    },
    function(t, e, r) {
      const n = r(1).extend({
        type: "arc",
        shape: {
          cx: 0,
          cy: 0,
          r: 0,
          startAngle: 0,
          endAngle: 2 * Math.PI,
          clockwise: !0
        },
        style: { stroke: "#000", fill: null },
        buildPath(t, e) {
          const r = e.cx;
          const n = e.cy;
          const i = Math.max(e.r, 0);
          const a = e.startAngle;
          const o = e.endAngle;
          const s = e.clockwise;
          const l = Math.cos(a);
          const h = Math.sin(a);
          t.moveTo(l * i + r, h * i + n), t.arc(r, n, i, a, o, !s);
        }
      });
      t.exports = n;
    },
    function(t, e, r) {
      const n = r(1);
      const i = n.extend({
        type: "compound",
        shape: { paths: null },
        _updatePathDirty() {
          for (var t = this.__dirtyPath, e = this.shape.paths, r = 0; r < e.length; r++) t = t || e[r].__dirtyPath;
          (this.__dirtyPath = t), (this.__dirty = this.__dirty || t);
        },
        beforeBrush() {
          this._updatePathDirty();
          for (let t = this.shape.paths || [], e = this.getGlobalScale(), r = 0; r < t.length; r++) t[r].path || t[r].createPathProxy(), t[r].path.setScale(e[0], e[1], t[r].segmentIgnoreThreshold);
        },
        buildPath(t, e) {
          for (let r = e.paths || [], n = 0; n < r.length; n++) r[n].buildPath(t, r[n].shape, !0);
        },
        afterBrush() {
          for (let t = this.shape.paths || [], e = 0; e < t.length; e++) t[e].__dirtyPath = !1;
        },
        getBoundingRect() {
          return this._updatePathDirty(), n.prototype.getBoundingRect.call(this);
        }
      });
      t.exports = i;
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(28);
      const a = function(t, e, r, n, a, o) {
        (this.x = t == null ? 0 : t), (this.y = e == null ? 0 : e), (this.x2 = r == null ? 1 : r), (this.y2 = n == null ? 0 : n), (this.type = "linear"), (this.global = o || !1), i.call(this, a);
      };
      (a.prototype = { constructor: a }), n.inherits(a, i);
      const o = a;
      t.exports = o;
    },
    function(t, e, r) {
      const n = r(0);
      const i = r(28);
      const a = function(t, e, r, n, a) {
        (this.x = t == null ? 0.5 : t), (this.y = e == null ? 0.5 : e), (this.r = r == null ? 0.5 : r), (this.type = "radial"), (this.global = a || !1), i.call(this, n);
      };
      (a.prototype = { constructor: a }), n.inherits(a, i);
      const o = a;
      t.exports = o;
    },
    function(t, e, r) {
      const n = r(0).inherits;
      const i = r(6);
      const a = r(3);
      function o(t) {
        i.call(this, t), (this._displayables = []), (this._temporaryDisplayables = []), (this._cursor = 0), (this.notClear = !0);
      }
      (o.prototype.incremental = !0),
        (o.prototype.clearDisplaybles = function() {
          (this._displayables = []), (this._temporaryDisplayables = []), (this._cursor = 0), this.dirty(), (this.notClear = !1);
        }),
        (o.prototype.addDisplayable = function(t, e) {
          e ? this._temporaryDisplayables.push(t) : this._displayables.push(t), this.dirty();
        }),
        (o.prototype.addDisplayables = function(t, e) {
          e = e || !1;
          for (let r = 0; r < t.length; r++) this.addDisplayable(t[r], e);
        }),
        (o.prototype.eachPendingDisplayable = function(t) {
          for (var e = this._cursor; e < this._displayables.length; e++) t && t(this._displayables[e]);
          for (e = 0; e < this._temporaryDisplayables.length; e++) t && t(this._temporaryDisplayables[e]);
        }),
        (o.prototype.update = function() {
          this.updateTransform();
          for (var t = this._cursor; t < this._displayables.length; t++) {
            ((e = this._displayables[t]).parent = this), e.update(), (e.parent = null);
          }
          for (t = 0; t < this._temporaryDisplayables.length; t++) {
            var e;
            ((e = this._temporaryDisplayables[t]).parent = this), e.update(), (e.parent = null);
          }
        }),
        (o.prototype.brush = function(t, e) {
          for (var r = this._cursor; r < this._displayables.length; r++) {
            (n = this._displayables[r]).beforeBrush && n.beforeBrush(t), n.brush(t, r === this._cursor ? null : this._displayables[r - 1]), n.afterBrush && n.afterBrush(t);
          }
          this._cursor = r;
          for (r = 0; r < this._temporaryDisplayables.length; r++) {
            var n;
            (n = this._temporaryDisplayables[r]).beforeBrush && n.beforeBrush(t), n.brush(t, r === 0 ? null : this._temporaryDisplayables[r - 1]), n.afterBrush && n.afterBrush(t);
          }
          (this._temporaryDisplayables = []), (this.notClear = !0);
        });
      const s = [];
      (o.prototype.getBoundingRect = function() {
        if (!this._rect) {
          for (var t = new a(1 / 0, 1 / 0, -1 / 0, -1 / 0), e = 0; e < this._displayables.length; e++) {
            const r = this._displayables[e];
            const n = r.getBoundingRect().clone();
            r.needLocalTransform() && n.applyTransform(r.getLocalTransform(s)), t.union(n);
          }
          this._rect = t;
        }
        return this._rect;
      }),
        (o.prototype.contain = function(t, e) {
          const r = this.transformCoordToLocal(t, e);
          if (this.getBoundingRect().contain(r[0], r[1])) {
            for (let n = 0; n < this._displayables.length; n++) {
              if (this._displayables[n].contain(t, e)) return !0;
            }
          }
          return !1;
        }),
        n(o, i);
      const l = o;
      t.exports = l;
    },
    function(t, e, r) {
      const n = r(5);
      function i(t, e, r, n) {
        return e === 0
          ? [
              [t + (0.5 * r) / Math.PI / 2, n / 2],
              [t + (0.5 * r) / Math.PI, n],
              [t + r / 4, n]
            ]
          : e === 1
          ? [
              [t + ((0.5 * r) / Math.PI / 2) * (Math.PI - 2), n],
              [t + ((0.5 * r) / Math.PI / 2) * (Math.PI - 1), n / 2],
              [t + r / 4, 0]
            ]
          : e === 2
          ? [
              [t + (0.5 * r) / Math.PI / 2, -n / 2],
              [t + (0.5 * r) / Math.PI, -n],
              [t + r / 4, -n]
            ]
          : [
              [t + ((0.5 * r) / Math.PI / 2) * (Math.PI - 2), -n],
              [t + ((0.5 * r) / Math.PI / 2) * (Math.PI - 1), -n / 2],
              [t + r / 4, 0]
            ];
      }
      t.exports = n.graphic.extendShape({
        type: "ec-liquid-fill",
        shape: {
          waveLength: 0,
          radius: 0,
          radiusY: 0,
          cx: 0,
          cy: 0,
          waterLevel: 0,
          amplitude: 0,
          phase: 0,
          inverse: !1
        },
        buildPath(t, e) {
          e.radiusY == null && (e.radiusY = e.radius);
          for (var r = Math.max(2 * Math.ceil(((2 * e.radius) / e.waveLength) * 4), 8); e.phase < 2 * -Math.PI;) e.phase += 2 * Math.PI;
          for (; e.phase > 0;) e.phase -= 2 * Math.PI;
          const n = (e.phase / Math.PI / 2) * e.waveLength;
          const a = e.cx - e.radius + n - 2 * e.radius;
          t.moveTo(a, e.waterLevel);
          for (var o = 0, s = 0; s < r; ++s) {
            const l = s % 4;
            const h = i((s * e.waveLength) / 4, l, e.waveLength, e.amplitude);
            t.bezierCurveTo(h[0][0] + a, -h[0][1] + e.waterLevel, h[1][0] + a, -h[1][1] + e.waterLevel, h[2][0] + a, -h[2][1] + e.waterLevel), s === r - 1 && (o = h[2][0]);
          }
          e.inverse
            ? (t.lineTo(o + a, e.cy - e.radiusY), t.lineTo(a, e.cy - e.radiusY), t.lineTo(a, e.waterLevel))
            : (t.lineTo(o + a, e.cy + e.radiusY), t.lineTo(a, e.cy + e.radiusY), t.lineTo(a, e.waterLevel)),
            t.closePath();
        }
      });
    },
    function(t, e, r) {
      const n = r(0).createHashMap;
      t.exports = function(t) {
        return {
          getTargetSeries(e) {
            const r = {};
            const i = n();
            return (
              e.eachSeriesByType(t, (t) => {
                (t.__paletteScope = r), i.set(t.uid, t);
              }),
              i
            );
          },
          reset(t, e) {
            const r = t.getRawData();
            const n = {};
            const i = t.getData();
            i.each((t) => {
              const e = i.getRawIndex(t);
              n[e] = t;
            }),
              r.each((e) => {
                let a;
                const o = n[e];
                const s = o != null && i.getItemVisual(o, "color", !0);
                const l = o != null && i.getItemVisual(o, "borderColor", !0);
                if (((s && l) || (a = r.getItemModel(e)), !s)) {
                  const h = a.get("itemStyle.color") || t.getColorFromPalette(r.getName(e) || `${e}`, t.__paletteScope, r.count());
                  o != null && i.setItemVisual(o, "color", h);
                }
                if (!l) {
                  const u = a.get("itemStyle.borderColor");
                  o != null && i.setItemVisual(o, "borderColor", u);
                }
              });
          }
        };
      };
    }
  ]))));
// # sourceMappingURL=echarts-liquidfill.min.js.map
