var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-default" }, [
    _vm.styleType == "2"
      ? _c("div", { staticClass: "styleType2-conter" }, [
          _c(
            "div",
            {
              staticClass: "styleType2-title",
              style: {
                color: _vm.item.titleColor,
                "font-size": _vm.item.titleFont + "px",
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.item.title))]),
              _c("en-icon", {
                style: "color:" + _vm.item.contentColor,
                attrs: {
                  name: "bus-board-icon" + _vm.item.iconSet.icon + "_1",
                  size: _vm.item.contentFont,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "styleType2-text",
              style: {
                color: _vm.item.contentColor,
                "font-size": _vm.item.contentFont + "px",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.through.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.value))]
          ),
        ])
      : _vm.styleType == "3"
      ? _c(
          "div",
          {
            staticClass: "styleType2-conter",
            staticStyle: { color: "#FFF" },
            style: _vm.gradient,
          },
          [
            _c(
              "div",
              {
                staticClass: "styleType2-title",
                style: { "font-size": _vm.item.titleFont + "px" },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.item.title))]),
                _c("en-icon", {
                  staticStyle: { color: "#FFF" },
                  attrs: {
                    name: "bus-board-icon" + _vm.item.iconSet.icon + "_1",
                    size: _vm.item.contentFont,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "styleType2-text",
                style: { "font-size": _vm.item.contentFont + "px" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.through.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.value))]
            ),
          ]
        )
      : _c("div", { staticClass: "default-conter" }, [
          _c(
            "p",
            {
              staticClass: "default-conter-title",
              style: {
                color: _vm.item.titleColor,
                "font-size": _vm.item.titleFont + "px",
              },
            },
            [_vm._v(_vm._s(_vm.item.title))]
          ),
          _c(
            "p",
            {
              staticClass: "default-conter-text",
              style: {
                color: _vm.item.contentColor,
                "font-size": _vm.item.contentFont + "px",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.through.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.value))]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }