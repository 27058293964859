<!--
 * @Author: zoujp
 * @Date: 2020-07-13 15:32:06
 * @LastEditTime: 2020-07-15 11:53:44
 * @LastEditors: Please set LastEditors
 * @Description: 指标卡文件锚点指标
 * @FilePath: \user\src\views\businessChart\components\card.vue
-->
<template>
  <div ref="anchorWrap" class="anchor-wrap">
      <div class="circle" v-for="(item,index) in circleData" :key="index" :style="item.circleStyle" >
        <div class="anchor-list">
            <!-- 九宫格布局 -->
          <div class="anchor" v-for="(itm,idx) in item.anchors" :key="idx">
            <div
              class="value-icon"
              :style="getAnchorIconStyle(itm)"
            >
              <en-icon v-if="itm.uuid"
                :name="'bus-board-icon'+itm.anchorStyle.iconSet.icon"
                :size="itm.anchorStyle.iconSet.size"
                color="#fff"
                @mouseover.native.stop="mouserEvent(itm,true)"
                @mouseout.native.stop="mouserEvent(itm,false)"
              ></en-icon>
            </div>
            <div class="value-mod" v-show="itm.show" :style="getAnchorValueStyle(itm)"><span>{{itm.value}}</span></div>
          </div>
        </div>
        <div class="circle-txt" :title="item.controlName">
          <span :style="getTextStyle(item)">{{item.controlName}}</span>
        </div>
      </div>
      <div class="anchorbtn-lis">
        <div class="btn-wrap">
          <div class="lend" v-for="(item,index) in lendData" :key="index">
            <div class="lend-icon">
              <en-icon
                :name="'bus-board-icon'+item.iconSet.icon"
                size="16"
              ></en-icon>
            </div>
            <div class="lend-tit">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <img class="anchor-img" :src="imgSrc" alt="" :style="imgStyle">
  </div>
</template>

<script>
import { thousand } from "en-js";

export default {
  name: "anchorPoint",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 完整的图表数据
    widthPar: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      width: 0,
      height: 0,
      imgSrc: "",
      imgStyle: {
        width: "0px",
        height: "0px"
      },
      lendData: [],
      circleData: [],
      isFull: this.isFullScreen
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.anchorWrap) {
        setTimeout(() => {
          this.width = this.$refs.anchorWrap.offsetWidth;
          this.height = this.$refs.anchorWrap.offsetHeight;
          this.init();
        }, 500);
      }
      this.initLendData();
    });
  },
  watch: {
    //
    resized() {
      this.width = this.$refs.anchorWrap.offsetWidth;
      this.height = this.$refs.anchorWrap.offsetHeight;
      this.init();
    },
    isFullScreen(val) {
      const data = JSON.parse(JSON.stringify(this.circleData));
      this.circleData.forEach((b) => {
        b.anchors?.forEach((d) => {
          if (d.uuid) {
            d.show = val;
          }
        });
      });
      this.circleData = data;
    }
  },
  methods: {
    /**
     * @description: 获取九宫格数据
     * @item 当前格子数据
     */
    getValue(item) {
      if (!item.uuid) return "";
      // 范围这里暂时不处理，需要开启eval函数
      const showStyle = item.anchorStyle.showStyle;
      let value = item.value;
      if (showStyle.showType.toString() === "2") {
        // 百分百
        value = `${(Number(value || 0) * 100).toFixed(Number(showStyle.decimal || 0))}%`;
      } else if (showStyle.thousandMark.toString() === "1") {
        // 数值
        value = Number(value || 0).toFixed(Number(showStyle.decimal || 0));
      } else {
        // 千分符
        value = thousand(Number(value || 0), Number(showStyle.decimal || 0));
      }
      if (item.anchorStyle.isShow.toString() === "0") {
        value = `${item.anchorStyle.showName}：${value || ""}`;
      } else {
        value = value || "";
      }
      return value;
    },
    /**
     * @description: 获取九宫格每个格子的金额样式
     * @item 当前格子数据
     * @show 显示隐藏
     */
    mouserEvent(item, show) {
      if (this.isFull) {
        item.show = true;
      } else {
        item.show = show;
      }
    },
    /**
     * @description: 获取九宫格每个格子的金额样式
     * @item 当前格子数据
     */
    getAnchorValueStyle(item) {
      if (!item.uuid) return "";
      const top = Number(item.anchorStyle.iconSet.size) + 2 + 10;// 加padding-top 10
      const left = 0;
      return `left:${left}px;top:${top}px;width:${item.anchorStyle.textWidth}px`;
    },
    /**
     * @description: 获取九宫格每个格子的图标样式
     * @item 当前格子数据
     */
    getAnchorIconStyle(item) {
      if (!item.uuid) return "";
      const width = Number(item.anchorStyle.iconSet.size) + 2;
      return `width:${width}px;height:${width}px;background:${item.anchorStyle.iconSet.color};`;
    },
    /**
     * @description: 获取circel 文字样式
     * @item 当前格子数据
     */
    getTextStyle(item) {
      return `font-size:${item.contentFont}px;color:${item.contentColor};`;
    },
    /**
     * @description: 计算图片宽高
     */
    loadSuccess() {
      // 计算图片宽高
      const imgWidth = Number(this.anchorData.imgWidth);
      const imgHeight = Number(this.anchorData.imgHeight);
      let iw = 0; let ih = 0;
      const wrate = imgWidth / this.width;
      const hrate = imgHeight / this.height;
      if (wrate <= 1 && hrate <= 1) {
        // 如果两个数都小于等于1，直接赋值给img
        iw = imgWidth;
        ih = imgHeight;
      } else {
        // 取比例最大的，然后都除以这个比例
        const maxrate = Math.max.apply(null, [wrate, hrate]);
        iw = imgWidth / maxrate;
        ih = imgHeight / maxrate;
      }
      this.imgStyle.width = `${iw}px`;
      this.imgStyle.height = `${ih}px`;
    },
    /**
     * @description: 初始化显示数据
     */
    init() {
      this.drawImg();
      this.drawCircle();
    },
    /**
     * @description: 画出图片
     */
    drawImg() {
      // 背景图片
      this.imgSrc = this.anchorData.backgroundImg || "";
      this.loadSuccess();
    },
    /**
     * @description: 画锚点（圆形，方形）
     */
    drawCircle() {
      const res = [];
      const dataList = [];
      // 取出dataList数据
      this.anchorData.targetData?.forEach((b) => {
        b.dataList?.forEach((d) => {
          dataList.push(d);
        });
      });

      this.anchorData.mData.forEach((b) => {
        const cle = JSON.parse(JSON.stringify(b));
        // 圆形属性
        const borderWidth = cle.style.borderWidth;
        const borderType = cle.style.borderType.toString() === "1" ? "solid" : "dashed";
        const borderColor = cle.style.borderColor;
        let borderRadius = "0";
        const width = Number(this.width) * Number(cle.dataRate);
        const left = Number(this.width) * Number(cle.dataRateX);
        const top = Number(this.height) * Number(cle.dataRateY);

        if (cle.style.type.toString() === "1") { // 圆形
          borderRadius = "50";
        }
        cle.circleStyle = `border: ${borderWidth}px ${borderType} ${borderColor}; border-radius: ${borderRadius}%; width: ${width}px; height: ${width}px; left: ${left}px; top: ${top}px;`;

        // 圆形对应的数值
        const values = (() => {
          let vs = [];
            this.picItemData.zbData?.forEach((n) => {
              if (n.id === cle.controlNameVariable) {
                vs = n.value || [];
              }
            });
            return vs;
        })();

        // 圆形内部九宫格属性
        const mods = [];
        b.targetPos?.forEach((d) => {
          const obj = {};
          obj.show = false; // 默认隐藏
          obj.uuid = d;
          obj.anchorStyle = (() => {
            const style = {};
            dataList.forEach((m) => {
              if (m.uuid === d) {
                style.iconSet = m.iconSet;
                style.showRange = m.showRange;
                style.showRangeStr = m.showRangeStr;
                style.showStyle = m.showStyle;
                style.isShow = m.isShow;
                style.showName = m.showName || "";
                style.textWidth = 60;
              }
            });
            return style;
          })();
          obj.value = (() => {
            let va = "";
            values.forEach((v) => {
              if (v.uuid === d) {
                va = v.value || 0;
              }
            });
            return va;
          })();
          mods.push(obj);
        });
        cle.anchors = mods;
        res.push(cle);
      });
      // 取值范围
      res.forEach((b) => {
        b.anchors.forEach((d) => {
          const v = Number(d.value) || 0;
          const range = d.anchorStyle.showRangeStr || [];
          const result = [];
          range.forEach((n) => {
            const row = [];
            n.items.forEach((k) => {
              row.push((() => {
                const op = k.perator.toString();
                let va = true;
                if (op === "1") {
                  va = v === Number(k.value);
                } if (op === "2") {
                  va = v !== Number(k.value);
                } if (op === "5") {
                  va = v > Number(k.value);
                } if (op === "6") {
                  va = v >= Number(k.value);
                } if (op === "7") {
                  va = v <= Number(k.value);
                } if (op === "8") {
                  va = v < Number(k.value);
                }
                return va;
              })());
            });
            result.push(row);
          });
          let re = false;
          if (range.length) {
            re = true;
          }
          for (let i = 0; i < result.length; i++) {
            let levelResult = true;
            for (let j = 0; j < result[i].length; j++) {
              levelResult = levelResult && result[i][j];
            }
            re = re || levelResult;
          }
          if (re) {
            d.uuid = "";
          }
        });
      });
      // 格式化取值
      res.forEach((b) => {
        b.anchors.forEach((d) => {
          d.value = this.getValue(d);
          d.anchorStyle.textWidth = d.value.length * 12 + 10;
        });
      });
      this.circleData = res;
      console.log(this.circleData);
    },
    /**
     * @description: 初始化Lend数据（图例）
     */
    initLendData() {
      const res = [];
      this.anchorData.targetData?.forEach((b) => {
        b.dataList.forEach((d) => {
          res.push(d);
        });
      });
      this.lendData = res;
    }
  }
};
</script>

<style lang="scss" scoped>
  .anchor-wrap{
    width:100%;
    height: 100%;
    position: relative;
    .circle{
      position: absolute;
      .anchor-list{
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        width: 100%;
        height: 100%;
        z-index: 2;
        align-items: center;
        justify-content: center;
        .anchor{
          min-width: 30%;
          max-width: 30%;
          text-align: center;
          position: relative;
          cursor: pointer;
          padding: 10px;
          z-index: 3;
          .value-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            .en-icon{
              cursor: pointer;
            }
          }
          .value-mod{
            position: absolute;
            background: #555865;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            border-radius: 5px;
            color: #fff;
            line-height: 22px;
            z-index: 99;
            text-overflow: clip;
            font-size: 12px;
            padding: 0 5px;
          }
        }
      }
      .circle-txt{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .anchorbtn-lis{
      position:absolute;
      left:17px;
      bottom: 10px;
      padding: 10px;
      background:rgba(36,39,62,1);
      opacity:0.3;
      border-radius:6px;
      width:202px;
      max-height:320px;
      overflow-y: auto;
      .lend{
        height: 30px;
        line-height: 30px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: left;
        .lend-icon{
          width: 30px;
          padding-right: 10px;
          display: flex;
          align-items: center;
        }
        .lend-tit{
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .anchor-img{
      display: block;
      margin: 0 auto;
    }
  }
</style>
