<!--
 * @Author: zoujp
 * @Date: 2020-07-08 18:55:20
 * @LastEditTime: 2021-07-01 15:04:07
 * @LastEditors: Please set LastEditors
 * @Description: 业务建模看板 - 过滤弹窗
 * 月 区间或单月
 * 日 时 分
 * @FilePath: \user\src\views\intelligentAccounting\financialReport\boardFi
 <boardFilter
  ref="popBoardFilter"
  :conditions="fdata"
  :variables="gvars"
>
</boardFilter>
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="筛选"
    class="bus-board-filter"
    width="900px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="form-wrap">
      <div class="rows" v-for="(item) in cons" :key="item.id">
        <div class="row-label"><span>{{ item.fieldName }}</span></div>
        <!-- 单行文本 -->
        <div class="row-input-wrap" v-if="['1','2','29'].includes(item.fieldType)">
            <div class="row-input"><el-input v-model="item.valueModel" maxlength="20"  placeholder="请输入" @change="selectChange(item)"></el-input></div>
        </div>
        <!-- 数值金额 -->
        <div class="row-input-wrap" v-else-if="['3','4'].includes(item.fieldType)">
          <div class="row-input"><el-input v-model="item.valueModelStart" maxlength="8" placeholder="请输入" @change="selectChange(item)"></el-input></div>
          <div class="split"> — </div>
          <div class="row-input"><el-input v-model="item.valueModelEnd" maxlength="8"  placeholder="请输入" @change="selectChange(item)"></el-input></div>
        </div>
        <!-- 单选多选 -->
        <div class="row-input-wrap" v-else-if="['5','15'].includes(item.fieldType)">
          <div class="row-input">
            <en-select-data
              type="tree"
              v-model="item.valueModel"
              :isMulti="true"
              page ="true"
              pageSize ="20"
              selectMode="tree"
              lazy="true"
              :config="{hideRight:true, dependData:{areaObjType:item.baseDataTableType,areaObjTypeId:item.baseDataType,field:item.fieldNameVariable}}"
            >
            </en-select-data>
          </div>
        </div>
        <!-- 时间 -->
        <div class="row-input-wrap" v-else-if="['7'].includes(item.fieldType)">
          <en-select
            v-model="item.varModel"
            :data="vars"
            filterable
            :props="{ value: 'id', label: 'name'}"
            style="width:100%;"
            @change="selectChange(item)"
          >
          </en-select>
          <div class="row-input date-time" v-show="item.valueType && item.valueType.toString()==='30'">
            <el-date-picker
              v-model="item.valueModel"
              :type="getDateType(item)"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="selectChange(item)"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import dayjs from "dayjs";
import enSelectData from "@/components/en-select-data";
import datePickerOptions from "@/mixins/datePickerOptions";

// 过滤
export default {
  name: "BoardFilter",
  components: { enSelectData },
  mixins: [datePickerOptions],
  props: {
    conditions: {
      type: Array,
      default() {
        return [];
      }
    },
    variables: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // 弹窗是否显示
      visiable: false,
      // 看板条件
      cons: this.formatData(),
      vars: this.getVarByType(3)
    };
  },
  watch: {
    conditions() {
      this.cons = this.formatData();
    },
    variables() {
      this.vars = this.getVarByType(3);
    },
    visiable() {
      if (this.visiable) {
        this.cons = this.formatData();
      }
    }
  },
  mounted() {
    this.queryGlobalVarible();
  },
  methods: {
    /**
     * @description: 根据类型1人员2组织机构3时间获取全局变量
     * @item :选中的数据集
     */
    getVarByType(type) {
      const res = this.variables.filter((b) => b.type === type);
      return res;
    },
    /**
     * @description: 获取时间控件的格式化类型
     * @item :选中的数据集
     */
    getDateType(item) {
      let format = "date";
      const dateAccuracy = item.dateAccuracy?.toString();
      if (dateAccuracy === "1") {
        const dateMonthType = item.dateMonthType?.toString();
        if (dateMonthType === "1") {
          format = "monthrange";
        } else {
          format = "month";
        }
      } else if (dateAccuracy === "2") {
        format = "daterange";
      } else if (dateAccuracy === "3") {
        format = "datetimerange";
      } else if (dateAccuracy === "4") {
        format = "datetimerange";
      }
      return format;
    },
    /**
     * @description: 格式化每个条件的v-mode
     * 文本：v-mode：valueMode
     * 数值金额：v-mode：valueModeStart,valueModelEnd
     * 单选：多选 v-mode：valueMode ["id1","id2"]
     * 日期：v-mode：varMode 全局变量下来,valueModel 选择日期:
     */
    formatData() {
      const con = JSON.parse(JSON.stringify(this.conditions));
      con.forEach((b) => {
        if (["1", "2", "29"].includes(b.fieldType)) {
          b.valueModel = b.initValue || "";
        } else if (["3", "4"].includes(b.fieldType)) {
          if (b.initValue) {
            if (b.initValue.indexOf(",") > -1) {
              b.valueModelStart = b.initValue.split(",")[0];
              b.valueModelEnd = b.initValue.split(",")[1];
            } else {
              b.valueModelStart = b.initValue.split("-")[0];
              b.valueModelEnd = b.initValue.split("-")[1];
            }
          } else {
            b.valueModelStart = "";
            b.valueModelEnd = "";
          }
        } else if (["15", "5"].includes(b.fieldType)) {
          if (b.initValue) {
            b.valueModel = { id: b.initValue, name: b.initValueText };
          } else {
            b.valueModel = [];
          }
        } else if (["7"].includes(b.fieldType)) {
          if (b.valueType?.toString() === "3") {
            b.varModel = b.initValue || "";
            b.valueModel = [];
            if (b.dateAccuracy?.toString() === "1" && b.dateMonthType?.toString() === "2") { // 月单月
              b.valueModel = "";
            } else {
              b.valueModel = [];
            }
          } else if (b.valueType?.toString() === "30") {
            b.varModel = "0";
            if (b.dateValue && b.dateAccuracy?.toString() === "1" && b.dateMonthType?.toString() === "2") {
              b.valueModel = "";
            } else if ((b.dateValue && b.dateAccuracy?.toString() === "1" && b.dateMonthType?.toString() === "1") || (["2", "3", "4"].includes(b.dateAccuracy?.toString()))) {
              const dateArr = b.dateValue.split(",");
              const startDate = dayjs(dateArr[0]).format("YYYY-MM-DD HH:mm:ss");
              const endDate = dayjs(dateArr[1]).format("YYYY-MM-DD HH:mm:ss");
              b.valueModel = [new Date(startDate), new Date(endDate)];
            }
          } else {
            b.valueModel = "";
          }
        }
      });
      console.log(con);
      return con;
    },
    /**
     * @description: 树，单选，全局变量 多选回调
     * @item :选中的数据集
     */
    selectChange(item) {
      const fieldType = item.fieldType.toString();
      const valueModel = item.valueModel;
      if (["1", "2", "29"].includes(fieldType)) { // 文本
        item.initValue = valueModel;
        item.initValueText = valueModel;
      } else if (fieldType.toString() === "7") { // 日期字段
        const model = item.varModel;
        if (model.toString() === "0") {
          item.valueType = "30";
          item.dateValue = (() => {
            if (item.valueModel === "" || !item.valueModel.length) {
              return "";
            } if (typeof item.valueModel === "string") {
              return item.valueModel;
            } if (typeof item.valueModel === "object") {
              return [dayjs(item.valueModel[0]).format("YYYY-MM-DD"), dayjs(item.valueModel[1]).format("YYYY-MM-DD")].join(",");
            }
            return "";
          })();
          item.initValue = item.dateValue;
        } else {
          item.valueType = "3";
          item.initValue = item.varModel;
        }
      } else if (["3", "4"].includes(fieldType)) { // 数值金额
        const startValue = item.valueModelStart;
        const endValue = item.valueModelEnd;
        if (isNaN(startValue)) {
          this.$message.error("请输入正确的数值或金额区间");
          item.valueModelStart = "";
          return false;
        }
        if (isNaN(endValue)) {
          this.$message.error("请输入正确的数值或金额区间");
          item.valueModelEnd = "";
          return false;
        }
        item.initValue = `${startValue}-${endValue}`;
      }
      return true;
    },
    /**
     * @description: 窗口关闭
     */
    closeFn() {
      this.visiable = false;
    },
    /**
     * @description: 保存
     */
    save() {
      const isBlank = this.cons.some((item) => ["3", "4"].includes(item.fieldType.toString()) && ((!item.valueModelStart && item.valueModelEnd) || (item.valueModelStart && !item.valueModelEnd)));
      if (isBlank) {
        this.$message.error("请输入正确的数值或金额区间");
      } else {
        this.visiable = false;
        this.cons.forEach((element) => {
          if (String(element.fieldType) === "5" || String(element.fieldType) === "15") {
            if (element.valueModel) {
              if (element.valueModel.id === element.initValue) {
              // 证明条件没有改变 不需要操作
              } else {
              // 条件改变 因为用户端不能选到全局变量 所有 这里的 valueType = 30
                element.initValue = element.valueModel.id;
                element.initValueText = element.valueModel.name;
                element.valueType = 30;
              }
            } else {
              // 没有选择内容
              element.initValue = "";
              element.initValueText = "";
              element.valueType = 30;
            }
          } else if (String(element.fieldType) === "3" || String(element.fieldType) === "4") {
            if (element.valueModelStart === "" && element.valueModelEnd === "") {
              element.initValue = "";
              element.initValueText = "";
            } else {
              element.initValue = `${element.valueModelStart},${element.valueModelEnd}`;
              element.initValueText = `${element.valueModelStart},${element.valueModelEnd}`;
            }
          }
        });
        this.$emit("saveFilter", this.cons);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bus-board-filter {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  .form-wrap{
    overflow-y: auto;
    .rows{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .row-label{
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        text-align: right;
        flex: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
        font-size: 12px;
        color: #636C78;
      }
      .row-input-wrap{
        flex: 1;
        display: flex;
        align-items: center;
        .row-input{
          flex: 1;
        }
        .date-time{
          margin-left: 10px;
        }
        .split{
          width: 40px;
          color: #DCDFE6;
          text-align: center;
        }
      }
    }
  }
}
</style>
