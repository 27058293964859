<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 16:35:40
 * @LastEditTime: 2022-06-02 14:06:10
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 地图
 * @FilePath: \user\src\views\businessChart\components\chartMap.vue
-->
<template>
  <div class="map">
    <section class="jsmap-container" ref="maps">
      <svg v-if="flag" version="1.1" xmlns="http://www.w3.org/2000/svg" style="position:relative;overflow:hidden;" viewBox="0 0 575 470" :width="w" :height="h">
        <path
          v-for="(b, a) in mapList"
          :key="a.name"
          :d="b.svg"
          :class="'jsmap-' + a"
          class="ditu"
          style="cursor:pointer;"
          :fill="(b.flag ? (!isFull ? '#FFFFFF' : 'rgba(255, 255, 255, 0.2)') : b.fill)"
          :stroke="!isFull ? '#AFB8BF' : 'rgba(255, 255, 255, 0.8)'"
          stroke-width="1"
          @mouseenter="enter($event, b)"
          @mousemove="move($event, b)"
          @mouseleave="leave()"
          @click="through(b)"
        >
          <text x="0" y="0">eeeee</text>
        </path>
        <text
          v-for="(b, a) in mapList"
          :key="a.name"
          :x="b.textPosition[0]"
          :y="b.textPosition[1]"
          class="ditu"
          :style="{ 'font-size': '12px', 'user-select': 'none', cursor: 'pointer', fill: (b.flag ? (!isFull ? '#606060' : 'rgba(255, 255, 255, 0.6)') : b.textfill) }"
          @mouseenter="enter($event, b)"
          @mousemove="move($event, b)"
          @mouseleave="leave()"
          @click="through(b)"
        >
          {{ b.name }}
        </text>
      </svg>
      <section v-show="enterflg" class="jsmap-hovertip" :style="'transform: translate(' + (pageX + 12 + 'px') + ', ' + (pageY + 12 + 'px') + ')'" v-html="htmlstr"></section>
    </section>
    <div v-if="flag" style="position:absolute;bottom:20px;left:40px;">
      <div class="tuli" :class="(v.flag ? 'cursection' : '')" v-for="v in section" :key="v.value" @click="clickItem(v)">
        <span class="tuli1" :style="'height: 10px;background: ' + v.color + ';'"></span>
        <span class="tulitxt" :style="'color: ' + (!isFull ? '#606060' : '#FFFFFF') + ';'">{{ v.desc || (Number(v.value) == 0 ? ">0" : "≥" + v.value) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";
import map from "./map.json";

export default {
  name: "ChatrMap",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      picItemData: {},
      isFull: this.isFullScreen,
      ratio: 0,
      // 地图 数据体
      mapList: [],
      h: 0,
      w: 0,
      shengmapName: {
        重庆: "重庆市",
        浙江: "浙江省",
        云南: "云南省",
        新疆: "新疆维吾尔自治区",
        香港: "香港特别行政区",
        西藏: "西藏自治区",
        天津: "天津市",
        四川: "四川省",
        上海: "上海市",
        陕西: "陕西省",
        山西: "山西省",
        山东: "山东省",
        青海: "青海省",
        宁夏: "宁夏回族自治区",
        内蒙古: "内蒙古自治区",
        辽宁: "辽宁省",
        江西: "江西省",
        江苏: "江苏省",
        吉林: "吉林省",
        湖南: "湖南省",
        湖北: "湖北省",
        黑龙江: "黑龙江省",
        河南: "河南省",
        河北: "河北省",
        海南: "海南省",
        贵州: "贵州省",
        广西: "广西壮族自治区",
        广东: "广东省",
        甘肃: "甘肃省",
        福建: "福建省",
        北京: "北京市",
        澳门: "澳门特别行政区",
        安徽: "安徽省",
        台湾: "台湾省"
      },
      sectionSetting: {},
      section: [],
      flag: false,
      pageX: 0,
      pageY: 0,
      enterflg: false,
      htmlstr: "",
      map: {}
    };
  },
  mounted() {
    this.gethw();
    this.makefun();
  },
  methods: {
    // 获取 元素的长宽
    gethw() {
      this.h = this.$refs.maps.clientHeight;
      this.w = this.$refs.maps.clientWidth;
    },
    // 处理数据
    makefun() {
      this.picItemData = this.picData;
      this.map = JSON.parse(JSON.stringify(map));
      console.log(this.picData);
      const This = this;
      const li = Object.keys(this.map.china);
      li.forEach((element) => {
        This.mapList.push(this.map.china[element]);
      });
      // debugger;
      let zong = 0;
      console.log(This.mapList);
      // 把 地图信息和 svg信息绑在一起
      This.mapList.forEach((v) => {
        v.setData = This.picItemData.picData.picDataList[0].yField.data[0];
        v.setDataX = This.picItemData.picData.picDataList[0].xField.data[0];
        v.showName = This.shengmapName[v.name];
        v.value = 0;
        v.trueValue = 0;
        v.bili = "";
        this.$set(v, "flag", false);
        This.picItemData.groupData.forEach((v1) => {
          if (v.showName === v1.showName) {
            const a = Number(v1.data[0].value);
            zong += a;
            v.value = a;
            v.trueValue = a;
            v.groupId = v1.groupId || v1.id;
            v.id = v1.id;
            v.groupName = v1.groupName;
            v.fieldType = v1.type;
            v.type = v1.type;
          }
        });
      });
      // 计算比例
      if (zong === 0) {
        // 如果总数等于0 都是0%
        This.mapList.forEach((v) => {
          v.bili = 0;
        });
      } else {
        This.mapList.forEach((v) => {
          v.bili = ((Number(v.trueValue) / zong) * 100).toFixed(2) || 0;
        });
      }
      // console.log(This.mapList);
      // 区间数据
      this.picItemData.picData.sectionSetting = this.picItemData.picData.sectionSetting.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      this.sectionSetting = JSON.parse(this.picItemData.picData.sectionSetting);
      this.section = this.sectionSetting.section.reverse();
      // 新增一个标识 用来实现点击效果
      this.section.forEach((v) => {
        this.$set(v, "flag", false);
      });
      This.mapList.forEach((v) => {
        this.sectionSettingfun(v);
      });
      This.flag = true;
    },
    // 给每个数据打上 区间数据的标识 颜色
    sectionSettingfun(atd) {
      if ((String(this.sectionSetting.paramet) === "1" ? atd.trueValue : Number(atd.bili)) < 0) {
        atd.fill = "#e6e8ea";
        atd.textfill = "#606060";
      } else if ((String(this.sectionSetting.paramet) === "1" ? atd.trueValue : Number(atd.bili)) === 0) {
        atd.fill = !this.isFull ? "#FFFFFF" : "rgba(255, 255, 255, 0.2)";
        atd.textfill = !this.isFull ? "#606060" : "rgba(255, 255, 255, 0.6)";
      } else {
        // 个区间起始值0、100、1000、10000，则对应的区间为：（0,100）、[100,1000)、[1000,10000)、[10000,+∞】
        for (let k = 0; k < this.section.length; k++) {
          const v = this.section[k];
          if (String(this.sectionSetting.paramet) === "1") {
            if (k === this.section.length - 1) {
              // 第一个
              atd.fill = v.color;
              atd.textfill = "#ffffff";
              atd.sectionValue = v.value;
              atd.flag = false;
              break;
            }
            if (k === 0) {
              if (atd.trueValue >= Number(v.value)) {
                atd.fill = v.color;
                atd.textfill = "#ffffff";
                atd.sectionValue = v.value;
                break;
              }
            } else if (Number(v.value) <= atd.trueValue) {
              atd.fill = v.color;
              atd.textfill = "#ffffff";
              atd.sectionValue = v.value;
              atd.flag = false;
              break;
            }
          } else {
            // 百分比
            if (k === this.section.length - 1) {
              // 第一个
              atd.fill = v.color;
              atd.textfill = "#ffffff";
              atd.sectionValue = v.value;
              atd.flag = false;
              break;
            }
            if (k === 0) {
              if (Number(atd.bili) >= Number(v.value)) {
                atd.fill = v.color;
                atd.textfill = "#ffffff";
                atd.sectionValue = v.value;
                atd.flag = false;
                break;
              }
            } else if (Number(v.value) <= Number(atd.bili)) {
              atd.fill = v.color;
              atd.textfill = "#ffffff";
              atd.sectionValue = v.value;
              atd.flag = false;
              break;
            }
          }
        }
      }
    },
    // 地图 移入 移出事件
    enter(e, item) {
      this.enterflg = true;
      this.htmlstr = this.hoverTip(item);
      this.pageX = e.offsetX;
      this.pageY = e.offsetY;
    },
    move(e, item) {
      this.enterflg = true;
      this.htmlstr = this.hoverTip(item);
      this.pageX = e.offsetX;
      this.pageY = e.offsetY;
    },
    leave() {
      this.enterflg = false;
    },
    // 地图 文字提示
    hoverTip(attrData) {
      if (attrData) {
        const res = [];
        res.push(`地区：${attrData.name}`);
        const setData = attrData.setData;
        if (String(setData.showType) === "2") {
          // 百分比
          res.push(`${setData.alias || setData.name || ""} : ${Number(attrData.trueValue || 0).toFixed(Number(setData.decimal || 0))}%（${attrData.bili}%）`);
        } else if (String(setData.thousandMark) === "1") {
          // 数值
          res.push(`${setData.alias || setData.name || ""} : ${Number(attrData.trueValue || 0).toFixed(Number(setData.decimal || 0))}（${attrData.bili}%）`);
        } else {
          res.push(`${setData.alias || setData.name || ""} : ${thousandFormat(Number(attrData.trueValue || 0).toFixed(Number(setData.decimal || 0)))}（${attrData.bili}%）`);
        }
        return `<div style="margin:8px;color:#fff;font-size:12px;">${res.join("<br/>")}</div>`;
      }
      return "";
    },
    // 图例点击
    clickItem(item) {
      item.flag = !item.flag;
      this.mapList.forEach((v) => {
        if (v.sectionValue === item.value) {
          v.flag = item.flag;
        }
      });
    },
    // 穿透
    through(item) {
      if (this.isFullScreen) {
        return;
      }
      this.$emit("through", item);
    }
  },
  watch: {
    // 外面变化时 重新获取长宽
    resized() {
      this.gethw();
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
.jsmap-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.jsmap-hovertip {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: auto;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(00, 00, 00, 0.8);
  // display: none;
  z-index: 99999999;
}
.ditu {
  &:hover {
    opacity: 0.8;
  }
}
.cursection {
  opacity: 0.5;
}
.tuli {
  height: 10px;
  line-height: 10px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  align-items: center;
  .tuli1 {
    width: 10px;
    display: inline-block;
    border-radius: 2px;
    vertical-align: middle;
    margin-right: 10px;
  }
  .tulitxt {
    font-size: 12px;
    opacity: 0.7;
  }
}
</style>
