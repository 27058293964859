<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-18 16:35:40
 * @LastEditTime: 2022-08-25 18:03:36
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 指标卡直线
 * @FilePath: \user\src\views\businessChart\components\cardLine.vue
-->
<template>
  <div class="card-line">
    <!-- 样式2 -->
    <div class="line-conter2" v-if="styleType == '2'">
      <div style="margin-bottom:25px;">
        <!-- 标题 -->
        <div class="col-tits" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">
          <span class="col-txts">{{ item.title }} </span>
        </div>
      </div>
      <div>
        <div class="line-conter1-title">
          <div class="col-num" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">
            {{ valueStr }}
          </div>
          <div>
            <span style="padding:8px;border-radius: 50%;display: inline-block;" :style="{ background: item.contentColor }">
              <en-icon :name="'bus-board-icon' + item.iconSet.icon" :size="item.contentFont || '32'" style="color:#FFF"> </en-icon>
            </span>
          </div>
        </div>
        <div :style="{ background: progressbg }" class="col-progress progress">
          <div :style="{ width: ratio + '%', background: item.contentColor }" class="progress-bar"></div>
        </div>
      </div>
    </div>
    <!-- 样式3 -->
    <div class="line-conter3-box" v-else-if="styleType == '3'" :style="gradient">
      <div class="line-conter2">
        <div style="margin-bottom:25px;">
          <div class="col-tits" :style="{ color: white, 'font-size': item.titleFont + 'px' }">
            <span class="col-txts">{{ item.title }} </span>
          </div>
        </div>
        <div>
          <div class="line-conter1-title" ref="progress3">
            <div>
              <en-icon :name="'bus-board-icon' + item.iconSet.icon + '_1'" :size="item.contentFont || '32'" style="color:#FFF"> </en-icon>
            </div>
            <div class="col-num" :style="{ color: white, 'font-size': item.contentFont + 'px' }" @click.stop="through">
              {{ valueStr }}
            </div>
          </div>
          <div class="col-progress3-box">
            <div v-if="progress3flg" class="col-progress3">
              <!--  -->
              <div v-for="(item, index) in num" :key="index" :style="ratio3 > index ? 'opacity: 1;' : ''" class="col-progress3-span"></div>
            </div>
            <!-- <div :style="{ width: ratio + '%' }" class="col-progress3-bar"></div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 样式1 -->
    <div class="line-conter1" v-else>
      <div style="margin-bottom:20px;">
        <span style="padding:8px;border-radius: 50%;display: inline-block;" :style="{ background: item.contentColor }">
          <en-icon :name="'bus-board-icon' + item.iconSet.icon" :size="item.contentFont || '32'" style="color:#FFF"> </en-icon>
        </span>
      </div>
      <div>
        <div class="line-conter1-title">
          <!-- 标题 -->
          <div class="col-tits" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">
            <span class="col-txts">{{ item.title }} </span>
          </div>
          <div class="col-num" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">
            {{ valueStr }}
          </div>
        </div>
        <div :style="{ background: progressbg }" class="col-progress progress">
          <div :style="{ width: ratio + '%', background: item.contentColor }" class="progress-bar"></div>
        </div>
      </div>
    </div>

    <div v-if="false" class="line-conter">
      <div class="cardblend-linear">
        <div class="col-bgs">
          <div class="col-mainnum">
            <div class="col-tits" :style="{ color: item.titleColor, 'font-size': item.titleFont + 'px' }">
              <span v-if="item.iconSet.align === '2' || item.iconSet.align === '4'" class="col-txts">{{ item.title }} </span>
              <span style="padding:5px;border-radius: 50%;" :style="{ background: item.iconSet.color }" :class="item.iconSet.align === '3' || item.iconSet.align === '4' ? 'inline' : 'block'">
                <en-icon :name="'bus-board-icon' + item.iconSet.icon" :size="item.iconSet.size || '32'" style="color:#FFF"> </en-icon>
              </span>
              <span v-if="item.iconSet.align === '1' || item.iconSet.align === '3'" class="col-txts">{{ item.title }}</span>
            </div>
            <div class="col-num" :style="{ color: item.contentColor, 'font-size': item.contentFont + 'px' }" @click.stop="through">
              {{ valueStr }}
            </div>
          </div>
          <div :style="{ background: progressbg }" class="col-progress progress">
            <div :style="{ width: ratio + '%', background: item.yData[0].color }" class="progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";

export default {
  name: "CardLine",
  props: {
    // 完整的图表数据
    picData: {
      type: Object,
      line() {
        return {};
      }
    },
    // 当前指标 图形数据 picData.picData.charts中
    item: {
      type: Object,
      line() {
        return {};
      }
    },
    // 是否全屏
    isFullScreen: {
      type: Boolean,
      line() {
        return false;
      }
    },
    resized: {
      type: Boolean,
      line() {
        return false;
      }
    },
    menuData: {
      type: Object,
      circular() {
        return {};
      }
    }
  },
  data() {
    return {
      picItemData: this.picData,
      anchorData: this.item,
      isFull: this.isFullScreen,
      value: 0, // 取出的真实值
      valueStr: "", // 显示值
      contrast: 0, // 对比值
      zbData: [],
      ratio: 0,
      white: "#FFFFFF",
      gradient: "",
      num: [],
      progress3flg: false, // 重置样式3的进度条
      ratio3: 0, // 样式3的显示格数
      styleType: "1"
    };
  },
  watch: {
    resized() {
      this.calculation3();
    },
    menuData: {
      handler() {
        const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
        if (customAttr && customAttr.styleType) {
          this.styleType = customAttr.styleType || "1";
        } else {
          this.styleType = this.picItemData.picData.styleType || "1";
        }
        if (this.styleType === "3") {
          this.$nextTick(() => {
            this.calculation3();
          });
        }
      },
      deep: true
    }
  },
  created() {
    const customAttr = typeof this.menuData.customAttr === "string" ? JSON.parse(this.menuData.customAttr || "{}") : this.menuData.customAttr;
    if (customAttr && customAttr.styleType) {
      this.styleType = customAttr.styleType;
    } else {
      this.styleType = this.picItemData.picData.styleType;
    }
  },
  mounted() {
    console.log(1111111, this.item);
    console.log(this.picData);
    this.fun();
    this.zbData = this.picItemData.zbData;
    // 获取唯一id在zbData的中查找
    const controlNameVariable = this.item.yData[0].controlNameVariable;
    const controlName = this.item.yData[0].controlName;
    (this.zbData || []).forEach((element) => {
      if (element.id === controlNameVariable && element.name === controlName) {
        this.value = element.value;
      }
    });
    const decimal = Number(this.item.yData[0].decimal || 0);
    const showType = String(this.item.yData[0].showType);
    const thousandMark = String(this.item.yData[0].thousandMark);
    if (String(this.item.yData[0].controlType) === "5") {
      // 文本不需要处理
      this.valueStr = this.value;
    } else if (showType === "2") {
      // 百分百
      this.valueStr = `${(Number(this.value) * 100).toFixed(decimal)}%`;
    } else if (thousandMark === "1") {
      // 数值
      this.valueStr = Number(this.value).toFixed(decimal);
    } else {
      // 千分符
      this.valueStr = thousandFormat(Number(this.value).toFixed(decimal));
    }
    this.calculation();
    this.calculation3();
  },
  methods: {
    // 计算样式3中 进度条需要多少个
    calculation3() {
      this.progress3flg = false;
      this.ratio3 = 0;
      const w = this.$refs.progress3.clientWidth;
      const n = parseInt(w / 10, 10);
      this.num.length = n;
      // 计算占比
      // 根据 this.ratio 占比 计算 格子的显示的数量
      const ratio3 = (this.ratio / 100) * n;
      this.progress3flg = true;
      const timer = setInterval(() => {
        if (this.ratio3 < ratio3) {
          this.ratio3++;
        } else {
          this.ratio3 = ratio3;
          clearInterval(timer);
        }
      }, 10);
    },
    // 计算百分比
    calculation() {
      // 取出对比值
      if (this.item.yData2 && this.item.yData2.length) {
        const controlNameVariable2 = this.item.yData2[0].controlNameVariable;
        const controlName2 = this.item.yData2[0].controlName;
        (this.zbData || []).forEach((element) => {
          if (element.id === controlNameVariable2 && element.name === controlName2) {
            this.contrast = element.value;
          }
        });
      }
      // 得到需要展示的值
      if (String(this.item.yData[0].controlType) === "5") {
        this.ratio = 100;
      } else if (this.contrast === 0) {
        this.ratio = 100;
      } else {
        // 对比值 存在, 按比例处理
        let bili = this.value / this.contrast;
        if (bili < 0) {
          bili = 0;
          this.ratio = 0;
        } else if (bili > 1) {
          bili = 1;
          this.ratio = 100;
        } else {
          this.ratio = bili * 100;
        }
      }
    },
    progressbg() {
      let color = "";
      if (this.item.yData2 && this.item.yData2.length) {
        color = this.item.yData2[0].color;
      }
      return color;
    },
    // 穿透事件
    through() {
      if (this.isFullScreen) {
        return;
      }
      if (String(this.item.yData[0].controlType) === "5" || String(this.item.yData[0].controlType) === "100") {
        // 文本 公式 不穿透
        return;
      }
      this.$emit("through", this.item.yData[0]);
    },
    fun() {
      const c1 = `${this.item.contentColor}FF`;
      const c2 = `${this.item.contentColor}E5`;
      const c3 = `${this.item.contentColor}CC`;
      this.gradient = `background-image: linear-gradient(${c1},${c2},${c3})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-line {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Microsoft YaHei;

  box-sizing: border-box;
  .line-conter3-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line-conter1 {
    padding: 0 30px;
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    .line-conter1-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .col-tits {
        font-family: Microsoft YaHei;
        font-weight: bold;
      }
    }
  }
  .line-conter2 {
    padding: 20px;
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    .line-conter1-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .col-tits {
        font-family: Microsoft YaHei;
        font-weight: bold;
      }
    }
  }
  .line-conter {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .col-bgs {
      width: 100%;
      margin: 0 auto;
      padding: 40px 0;
      .col-mainnum {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        align-items: flex-end;
      }
      // 进度条
    }
  }
}
.col-progress {
  height: 8px;
  background: #e0e7f3;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  .progress-bar {
    border-radius: 4px;
    background: #72ade9;
    box-shadow: none;
    float: left;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    width: 0;
    transition: all 1s;
  }
}
// 样式3的进度条
.col-progress3-box {
  width: 100%;
  height: 16px;
  position: relative;
  z-index: 1;
}
.col-progress3-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  z-index: 2;
  background: #fff;
}
.col-progress3 {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .col-progress3-span {
    width: 6px;
    height: 16px;
    border-radius: 5px;
    opacity: 0.25;
    background: #fff;
    // transition: all 1s;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.inline {
  display: inline-block;
}
.block {
  display: block;
}
</style>
